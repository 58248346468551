import React from 'react';

import { 
  Box as MuiBox
} from '@mui/material';

export default function Box(props){
  const { flex, flexColumn, center, fullWidth, fullHeight, sx, ...muiProps } = props;

  let finalSx = { ...sx };

  if(flex){
    finalSx = {
      ...finalSx,
      display: 'flex',
      flexDirection: 'row'
    };
  }else if(flexColumn){
    finalSx = {
      ...finalSx,
      display: 'flex',
      flexDirection: 'column'
    };
  }

  if(fullWidth){
    finalSx = {
      ...finalSx,
      width: '100%'
    };
  }

  if(fullHeight){
    finalSx = {
      ...finalSx,
      height: '100%'
    };
  }

  if(center  == 'horizontal' || center == 'h'){
    finalSx = {
      ...finalSx,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    };
  }else if(center  == 'vertical' || center == 'v'){
    finalSx = {
      ...finalSx,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    };
  }

  return (
    <MuiBox 
      {...muiProps}
      sx={{ ...finalSx }}
    />
  );
}