import React, { useContext } from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import { LoadableText, Avatar } from '@components/atoms/';

import config from '@config';
import { ClientContext } from '../client-context';

export default function Header() {
  const { profile, isLoading } = useContext(ClientContext);

  return(
    <Box sx={{ display: 'flex', width: '100%' }}> 
      <Avatar 
        isLoading={isLoading}
        size={40}
        name={profile?.name} 
        src={profile?.imageLocation ? `${config.contentBaseUrl}images/${profile?.imageLocation}` : null}/>
      <Typography component='div' sx={{ fontWeight: 'medium', py: 1, fontSize: 'h5.fontSize', flexGrow: 1, paddingLeft: 1 }}>
        <LoadableText text={profile?.name} isLoading={isLoading} dataTestId='client-name-header' />
      </Typography>
    </Box>
  );
}
