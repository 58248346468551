import React, { useContext } from 'react';

import {
  Box,
  Button
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { NewPostInputContext } from './new-post-input-context';

import { ImageUploader } from '@components/molecules';

export default function Image() {
  const { setImage } = useContext(NewPostInputContext);

  return (
    <Box sx={{ marginBottom: 'env(safe-area-inset-bottom)', p: 1, width: '50%' }}>
      <ImageUploader imageId='NewPostImage'
        isEnabled={true}
        saveImage={setImage}
        button={
          <Button size='large' color='braceGreenDark' startIcon={<AddPhotoAlternateIcon />}>
          Photo
          </Button>
        } />
    </Box>
  );
}