import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { 
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Browser } from '@capacitor/browser';

import { setCookie, COOKIE_NAMES } from './../../../helpers/cookies';

import { useContext } from 'react';
import { UserContext } from '@context';

import { LoadingPage } from '@components/molecules';
import { useTheme } from '@emotion/react';
import { forceLightTheme } from '@helpers/theme';
import { EventsContext } from '@context/events';
import { isNative } from '@helpers/device';
import { useHistory } from 'react-router-dom';

export default function Index() {
  var theme = useTheme();
  let { inviteToken } = useParams();
  const [hasShownLogin, setHasShownLogin] = useState(false);
  const { loginWithRedirect } = useAuth0();
  const { user, isLoading, isAuthenticated, handleLogout } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const history = useHistory();

  useEffect(() => {
    trackEvent('Index:Open');
  }, []);

  const login = async () => {
    if(inviteToken){
      setCookie({ name: COOKIE_NAMES.INVITE_TOKEN , value: inviteToken, minutes: 1 });
    }
    isNative() ?
      loginWithRedirect({
        async openUrl(url){
          await Browser.open({
            url,
            windowName: '_self'
          });
        }

      }) : 
      loginWithRedirect();
  };

  if(!isLoading && !isAuthenticated || !user?.id){
    if(!hasShownLogin){
      setHasShownLogin(true);
      trackEvent('Index:Authentication:Open');
      login();
    }
  }

  if(user?.status === 'PENDING'){
    history.push('/onboarding');
  }

  return(
    <ThemeProvider theme={forceLightTheme(theme)}>
      <LoadingPage onClick={handleLogout}/>
    </ThemeProvider>
  );
}