import React from 'react';

import { 
  Box,
  Select as MuiSelect,
  MenuItem,
  Typography,
  Chip,
  FormHelperText
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

import { useTheme } from '@emotion/react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function Select(props){
  const { label, value, options, dataTestId, required, placeholder, size='small', onChange = () => {}, onBlur, error, helperText, ...muiProps } = props;
  const theme = useTheme();
  
  function getStyles(name, theme) {
    return {
      fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
    };
  }

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      {label &&
      <Typography variant='body2' paddingBottom={0.5} fontWeight='medium'>
        {`${label}${required ? '*' : ''}`}
      </Typography>
      }
      <MuiSelect
        size={size}
        displayEmpty
        data-testid={dataTestId}
        multiple
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onClose={onBlur}
        sx={{
          '.MuiSelect-select.MuiInputBase-inputSizeSmall':{
            padding: '12px 14px'
          }
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span style={{ color: theme.palette.text.disabled }}>{placeholder}</span>;
          }

          return (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((v) => (
              <Chip key={v} label={v} />
            ))}
          </Box>
          );
        }}
        input={
          <OutlinedInput 
            fullWidth
            {...muiProps}
          />
        }
        MenuProps={MenuProps}
      >
        {options.map((v) => (
          <MenuItem
            key={v}
            value={v}
            style={getStyles(v, theme)}
          >
            {v}
          </MenuItem>
        ))}
      </MuiSelect>
      {error &&
        <FormHelperText>{helperText}</FormHelperText>
      }
    </Box>
  );
}