import moment from 'moment';
let width, height, gradient;

function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  width = chartWidth;
  height = chartHeight;
  gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
  return gradient;
}

const getDisplayFormat = (activeRange) => {
  if(activeRange == '1w'){
    return 'dd';
    
  }
  return 'D MMM';
};

export function buildDataset({ label, data, activeDatasetName, adjustGradient }){
  return {
    label,
    data,
    tension: 0.33,
    borderWidth: 4,
    borderColor: function(context) {
      const chart = context.chart;
      const { ctx, chartArea } = chart;
      if (!chartArea) {
        // This case happens on initial chart load
        return;
      }
      var gradient = getGradient(ctx, chartArea);
      adjustGradient(gradient);
      return gradient; 
    },
    hidden: activeDatasetName != label.toLowerCase()
  };
}

export function buildOptions({ minDate, activeRange }){
  return {
    responsive: true,
    elements:{
      point:{
        radius: 1
      }
    },
    layout:{
      padding: {
        left: 20,
        right: 20
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        min: 0.5,
        max: 5.5,
        display: false 
      },
      x: {
        type: 'time',
        time: {
          tooltipFormat:'MM/DD/YYYY',
          unit: 'day',
          displayFormats: {
            day: getDisplayFormat(activeRange)
          }
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7
        },
        min: minDate,
        grid:{
          display: true
        }
      }
    }
  };
}

export const filterData = (data, activeRange) => {
  var limit = moment().subtract(7, 'day');
  switch(activeRange){
  case '1w':
    limit = moment().subtract(7, 'day');
    break;
  case '1m':
    limit = moment().subtract(1, 'month');
    break;
  case '6m':
    limit = moment().subtract(6, 'month');
    break;
  case '1y':
    limit = moment().subtract(12, 'month');
    break;
  default:
    limit = moment().subtract(7, 'day');
  }
  return data.filter(d => moment(d.createdAt).isAfter(limit));
};

export const getMinDate = (data) => {
  var result = moment().subtract(7, 'day');
  
  data.forEach(d => {
    if(moment(d.createdAt).isBefore(result)){
      result = moment(d.createdAt);
    }
  });

  return result;
};

