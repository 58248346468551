import React from 'react';

import { 
  Box,
  Typography,
  Stack
} from '@mui/material';

import UserAvatar from './user-avatar';
import { Icon, LoadableText } from '../atoms';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function UserDetails(props){
  const { user, isLoading } = props;
  const history = useHistory();
  const ProfileDetail = (props) => {
    const { text, icon } = props;
    const theme = useTheme();

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon 
          fill={theme.palette.text.disabled}
          stroke={theme.palette.text.disabled}
          size={14}
          type={icon}
          isLoading={isLoading} />
        <Typography component='div'
          color='text.disabled'
          fontSize={10}
          marginLeft={0.5}>
          <LoadableText isLoading={isLoading} text={text} minWidth={50}/>
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <UserAvatar size={32}
          user={user}
          isLoading={isLoading}
          useNameBasedColor={true} 
          onClick={() => history.push(`?profileId=${user?.id}`)}/>
      </Box>
      <Box sx={{ paddingLeft: 1.5 }} onClick={() => history.push(`?profileId=${user?.id}`)}>
        
        <Typography component='div'
          color='#000'
          fontWeight='medium'
        >
          <LoadableText isLoading={isLoading} text={user?.name} minWidth={50}/>
        </Typography>
        {(isLoading || user?.injuryType || user?.activityType) &&
          <Stack direction='row' spacing={1}>
            {(isLoading || user?.injuryType) &&
              <ProfileDetail icon='heart-beat' text={user?.injuryType}/>
            }
            {(isLoading || user?.activityType) &&
              <ProfileDetail icon='running-person' text={user?.activityType}/>
            }
          
          </Stack>
        }
      </Box>
    </Box>
  );
}