import React, { useContext, useEffect, useRef } from 'react';

import {
  Dialog,
  Box,
  Grid,
  IconButton,
  Divider
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PostFeedContext } from '../post-feed-context';
import { UserDetails } from '@components/molecules';
import { Comments, LikeAndCommentCounts } from '@components/organisms';
import { LoadableText, Image, DialogTransition } from '@components/atoms';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ThumbUpOffAltRoundedIcon from '@mui/icons-material/ThumbUpOffAltRounded';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import { NewPostContext } from '@components/organisms/new-post/new-post-context';

import { useTheme } from '@emotion/react';
import PostPrivacy from '../post-privacy';
import { EventsContext } from '@context/events';
import { usePrevious } from '@helpers/hooks';

export default function ViewPost(props) {
  var history = useHistory();
  var { backLocation } = props;
  var theme = useTheme();
  const { postBeingViewed, setPostBeingViewed, likePost, saveComment } = useContext(PostFeedContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  const { trackEvent } = useContext(EventsContext);
  const prevPostBeingViewed = usePrevious({ postBeingViewed }, useRef, useEffect);
  
  const close = () => {

    if(backLocation){
      history.goBack();
    }else{
      setPostBeingViewed(null);
    }
  };

  const openCreateComment = (comment) => {
    trackEvent('Community:Posts:Comment:New:Open');
    openNewPostDialog({ 
      buttonText: 'COMMENT',
      title: 'New Check-In', 
      placeholder: 'Your comment...', 
      buttonAction: ({ message }) => {
        trackEvent('Community:Posts:Comment:Save');
        saveComment({ postId: postBeingViewed.id, message, parentCommentId:comment?.id  });
      }, 
      successToastMessage : 'Comment Posted!',
      isImageEnabled: false,
      parentCommentId: comment?.id
    });
  };
  
  useEffect(() => {
    if(postBeingViewed != null && prevPostBeingViewed?.postBeingViewed == null){
      trackEvent('Community:Post:Open', { postId:postBeingViewed.id });
    }
  }, [postBeingViewed]);

  return (
    <Dialog open={postBeingViewed != null}
      fullScreen
      TransitionComponent={DialogTransition}
      sx={{ ...props.sx, marginTop: 'env(safe-area-inset-top)' }} >
      <Box sx={{
        p: 1 
        
      }} >
        <Grid container direction="row" alignItems="center">
          <Grid item xs={4}>
            <IconButton onClick={close} size='small'>
              <ArrowBackIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={8}>
          </Grid>
        </Grid>
        
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}> 
        <UserDetails user={postBeingViewed?.user} date={postBeingViewed?.createdAt}/>
        <Box sx={{ typography: 'body1', paddingBottom: 0.5 }}>
          <LoadableText text={postBeingViewed?.message} isLoading={false} minHeight={50}/>
        </Box>
        {postBeingViewed?.imageUrl &&
        <Box sx={{ paddingBottom: 1 }}>
          <Image src={postBeingViewed?.imageUrl}
            sx={{ 
              [theme.breakpoints.down('md')]: {
                maxWidth: '80%'
              },
              [theme.breakpoints.only('md')]: {
                maxWidth: '50%'
              },
              [theme.breakpoints.up('lg')]: {
                maxWidth: '33%'
              }   
                  
            }} />
        </Box>
        }
        <PostPrivacy post={postBeingViewed}/>
        <LikeAndCommentCounts 
          likeCount={postBeingViewed?.likeCount}
          commentCount={postBeingViewed?.commentCount}
          parentId={postBeingViewed?.id} />
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
          <Box>
            <IconButton aria-label="delete"
              sx={{ color: postBeingViewed?.likedByCurrentUser ? '#E57373' : 'text.disabled' }}
              onClick={() => likePost(postBeingViewed.id)}>
              <ThumbUpOffAltRoundedIcon />
            </IconButton>
          </Box>
          <Box>
            <IconButton aria-label="delete"
              sx={{ color: 'text.disabled' }}
              onClick={openCreateComment}>
              <ChatBubbleRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Comments 
        parentUser={postBeingViewed?.user}
        commentCount={postBeingViewed?.commentCount}
        loadCommentsUrl={postBeingViewed?.id ?
          `community/posts/${postBeingViewed.id}/comments` :
          null} 
        openCreateComment={openCreateComment}
      />
    </Dialog>
  );
}