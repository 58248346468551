import React, { useContext, useEffect } from 'react';

import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Stack
}from '@mui/material';

import { NewPlanContext } from '../../context';
import { EventsContext } from '@context';

import { RichTextField } from '@components/molecules';
import 'react-quill/dist/quill.snow.css';

import Buttons from '../buttons';
import Header from '../header';

export default function Index(props) {
  const { handleBack } = props;
  const { trackEvent } = useContext(EventsContext);
  const { name, setName, notes, setNotes, savePlan, selectedExercises, shouldAlsoUpdateSessions, setShouldAlsoUpdateSessions, isSavingPlan, isEdit, isSaveDraftEnabled, isDraft } = useContext(NewPlanContext);

  const handleChange = (event) => {
    setShouldAlsoUpdateSessions(event.target.checked);
  };

  useEffect(() =>{
    trackEvent('Recovery:PlanCreator:Step:3');
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Header title={`${isEdit ? 'Edit' : 'New'} Plan (${selectedExercises.length} Exercises Selected)`} />
      <Stack spacing={1} sx={{ px: 1, py: 2 }}>
        <TextField 
          fullWidth
          inputProps={{ 'data-testid':'new-plan-dialog-plan-name-input' }}
          label='Plan Name'
          placeholder='Plan Name'
          size='small'
          value={name}
          onChange={(e) => setName(e.target.value)}/>
        <RichTextField 
          variant="outlined"
          fullWidth
          multiline={true}
          size="small"
          minRows={4}
          label='Notes'
          value={notes ?? ''}
          onChange={setNotes} />
        {isEdit &&
       <FormControlLabel control={  
         <Checkbox
           checked={shouldAlsoUpdateSessions}
           onChange={handleChange}
           inputProps={{ 'aria-label': 'controlled' }}
         />}
       label="Update Plan On All Linked Programs" />
      
        }
      </Stack>

      <Buttons 
        allowSaveDraft={isSaveDraftEnabled}
        next={() => savePlan()} 
        nextText={(isEdit && !isDraft) ? 'UPDATE' : 'CREATE'} 
        isNextDisabled={name === ''}
        isNextLoading={isSavingPlan}
        back={handleBack}/>
    </Box>
  );
}

