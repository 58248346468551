
import React from 'react';

export default function CheckCircle(props){
  return (
    <svg  xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={'#000000'}
      fill={'none'}>
      <circle cx="12"
        cy="12"
        r="6"
        stroke={props.stroke}
        fill={props.stroke}
        strokeWidth="3" />
      <path 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 22.5C5.81294 22.5 1 17.6871 1 11.75C1 5.81294 5.81294 1 11.75 1C17.6871 1 22.5 5.81294 22.5 11.75C22.5 17.6871 17.6871 22.5 11.75 22.5ZM16.5182 9.39018C16.8718 8.9659 16.8145 8.33534 16.3902 7.98177C15.9659 7.62821 15.3353 7.68553 14.9818 8.10981L10.6828 13.2686L8.45711 11.0429C8.06658 10.6524 7.43342 10.6524 7.04289 11.0429C6.65237 11.4334 6.65237 12.0666 7.04289 12.4571L10.0429 15.4571C10.2416 15.6558 10.5146 15.7617 10.7953 15.749C11.076 15.7362 11.3384 15.606 11.5182 15.3902L16.5182 9.39018Z"
        fill={props.fill}
        stroke={'none'} />

    </svg>
  );
}