import React from 'react';

export default function GroupIcon (props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50001 3.75C8.01879 3.75 9.25001 4.98122 9.25001 6.5C9.25001 8.01878 8.01879 9.25 6.50001 9.25C4.98122 9.25 3.75001 8.01878 3.75001 6.5C3.75001 4.98122 4.98122 3.75 6.50001 3.75ZM9.17982 14.5068C8.65307 14.7034 8.15249 14.9533 7.68488 15.25H1.75V12.7368L1.9145 12.5313C3.02215 11.1476 4.65653 10.25 6.5 10.25C6.85759 10.25 7.20731 10.2838 7.54684 10.3483C7.51598 10.5611 7.5 10.7787 7.5 11C7.5 12.4176 8.15545 13.682 9.17982 14.5068ZM14.75 6.5C14.75 4.98122 15.9812 3.75 17.5 3.75C19.0188 3.75 20.25 4.98122 20.25 6.5C20.25 8.01878 19.0188 9.25 17.5 9.25C15.9812 9.25 14.75 8.01878 14.75 6.5ZM12 15.25C9.99144 15.25 8.18495 16.1187 6.9427 17.4981L6.75001 17.7121V20.25H17.25V17.7121L17.0573 17.4981C15.8151 16.1187 14.0086 15.25 12 15.25ZM8.75001 11C8.75001 9.20507 10.2051 7.75 12 7.75C13.7949 7.75 15.25 9.20507 15.25 11C15.25 12.7949 13.7949 14.25 12 14.25C10.2051 14.25 8.75001 12.7949 8.75001 11ZM14.8202 14.5068C15.3469 14.7034 15.8475 14.9533 16.3151 15.25H22.25V12.7368L22.0855 12.5313C20.9779 11.1476 19.3435 10.25 17.5 10.25C17.1424 10.25 16.7927 10.2838 16.4532 10.3483C16.484 10.5611 16.5 10.7787 16.5 11C16.5 12.4176 15.8446 13.682 14.8202 14.5068Z"
        fill={props.fill}
        stroke={props.stroke} />
    </svg>
  );
}