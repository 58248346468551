import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';

import { 
  Box
} from '@mui/material';

import { Page } from '../../components/organisms';

import Header from './components/header';
import Cards from './components/cards';
import Actions from './components/actions';
import { ClientProvider } from './client-context';
import { EventsContext } from '@context';

import { ClientStats } from '@components/organisms';

export default function Client() {
  let { id } = useParams();
  const { trackEvent } = useContext(EventsContext);

  useEffect(() => {
    if(id){
      trackEvent('Client:Open', { clientId: id });
    }
  }, [id]);
  
  return(
    <Page enableBackButton={true}>
      <ClientProvider id={id}>
        <Box sx={{ p: 1 }}>
          <Header/>
          <ClientStats userId={id}/>
          <Cards/>
        </Box>
        <Actions/>
      </ClientProvider>
    </Page>
  );
}