
import React from 'react';

export default function Circle(props){
  return (
    <svg  xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={'none'}
      {...props}>
      <circle cx="12"
        cy="12"
        r="10"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinejoin="round" 
      />
    </svg>
  );
}