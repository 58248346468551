import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Dialog,
  DialogTitle,
  Slide,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Stack,
  IconButton,
  Typography
}from '@mui/material';
import { useTheme } from '@emotion/react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from '@mui/icons-material/Close';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

import { NewPlanContext } from './../../context';
import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';
import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AutoCompleteWithOptions = (props) => {
  var { value, onChange, options, label } = props;
  return (
    <Autocomplete
      multiple
      fullWidth
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      size='small'
      options={options}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '') {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
        />
      )}
    />
  );
};

export default function NewExerciseDialog(props) {
  const { isOpen, close } = props;
  const theme = useTheme();
  const [name, setName] = useState('');
  const [instruction, setInstruction] = useState('');
  const [instructions, setInstructions] = useState([]);
  const [tags, setTags] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const { exercises, addNewExercise, updateExercise, exerciseBeingEdited } = useContext(NewPlanContext);
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { enqueueSnackbar } = useSnackbar();

  const saveExercise = async () => {
    setIsSaving(true);
    if(!exerciseBeingEdited?.id){
      var saveResult = await api.post({ url: 'recovery/exercises', user, data: {
        name,
        tags,
        equipment,
        instructions
      } });

      enqueueSnackbar(`Exercise "${name}" created`, { 
        variant: 'success'
      });
      addNewExercise(saveResult);

    }else{
      var updateResult = await api.put({ url: `recovery/exercises/${exerciseBeingEdited.id}`, user, data: {
        name,
        tags,
        equipment,
        instructions
      } });
      enqueueSnackbar(`Exercise "${name}" updated`, { 
        variant: 'success'
      });
      updateExercise(updateResult);
    }
    setIsSaving(false);
    close();

  };

  const handleInstructionsSubmit = (event) => {
    event.preventDefault();
    if(instruction != ''){
      setInstructions([...instructions, instruction]);
      setInstruction('');
    }
  };

  const removeInstruction = (indexToDelete) => {
    var copy = [];
    instructions.forEach((i, index) => {
      if(index != indexToDelete) {
        copy.push(i);
      }
    });

    setInstructions(copy);
  };

  var tagOptions = [];

  exercises.forEach((e) => {
    e.tags.forEach((t) => {
      if(!tagOptions.includes(t) && t.toLowerCase() != 'custom'){
        tagOptions.push(t);
      }
    });
  });

  var equipmentOptions = [];

  exercises.forEach((e) => {
    e.equipment.forEach((e) => {
      if(!equipmentOptions.includes(e)){
        equipmentOptions.push(e);
      }
    });
  });

  useEffect(() => {
    if(!isOpen){
      setIsSaving(false);
      setName('');
      setTags([]);
      setInstruction('');
      setInstructions([]);
      setEquipment([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if(exerciseBeingEdited){
      setName(exerciseBeingEdited.name);
      setTags(exerciseBeingEdited.tags);
      setInstructions(exerciseBeingEdited.instructions);
      setEquipment(exerciseBeingEdited.equipment);
    }
  }, [exerciseBeingEdited]);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      TransitionComponent={Transition}
      sx={{    
           
        [theme.breakpoints.down('md')]: {
          '.MuiDialog-paper': {
            width: '100%'
          }
        },
        [theme.breakpoints.up('md')]: {
          '.MuiDialog-paper': {
            width: '66%'
          }
        } }}
    >
      <DialogTitle>
          Add New Exercise
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} p={1}>
          <TextField 
            label='Name'
            fullWidth
            value={name}
            size='small'
            onChange={(e) => setName(e.target.value)} />
          <AutoCompleteWithOptions
            value={tags}
            onChange={(newValue) => {
              setTags(newValue);
            }}
            label="Add A Tag"
            options={tagOptions}
          />
          <AutoCompleteWithOptions
            value={equipment}
            onChange={(newValue) => {
              setEquipment(newValue);
            }}
            label="Add Equipment"
            options={equipmentOptions}
          />
          <form onSubmit={handleInstructionsSubmit}>
            <Stack direction='row' spacing={1}>
              <TextField
                label='Add Instructions'
                fullWidth
                value={instruction}
                size='small'
                onChange={(e) => setInstruction(e.target.value)}
              />
              <IconButton 
                color='primary'
                disabled={instruction == ''}
                onClick={handleInstructionsSubmit}>
                <AddBoxIcon />
              </IconButton>          
            </Stack>
            {instructions?.length > 0 &&
            <>
              <Typography>Instructions</Typography>
              <Box >
                {
                  instructions.map((i, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ flexGrow: 1 }}>

                        <li>{i}</li>
                      </Box>
                      <IconButton size='small' onClick={() => removeInstruction(index)}>
                        <CloseIcon fontSize='6px'/>
                      </IconButton>
                    </Box>
                  )) 
                }
               
              </Box>
            </>
            }
            {instructions?.length === 0 &&
              <Box textAlign='center' p={2}>
                <Typography variant='h5'> No Instructions Yet</Typography>
                <Typography color='text.secondary'> Add Above</Typography>
              </Box>
            }
          </form>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={close}>
          Cancel
        </Button>
        <LoadingButton disabled={name == '' || tags.length == 0}
          loading={isSaving}
          variant='contained'
          onClick={saveExercise}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}