
import React, { createContext, useContext, useState } from 'react';

export const CommunityFilterContext = createContext();
import { EventsContext } from '@context/events';

export function CommunityFilterProvider(props){
  const { userId, isSearchEnabled: isSearchEnabledProp } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [isFiltersDialogOpen, setIsFiltersDialogOpen] = useState(false);
  const { trackEvent } = useContext(EventsContext);

  const initialize = ({ isSearchEnabled: isSearchEnabledParam  = false, defaultSortBy }) => {
    setSearchTerm('');
    setIsFiltersDialogOpen(false);
    setIsSearchEnabled(isSearchEnabledParam);
    setSortBy(defaultSortBy ?? 'POPULAR');
  };

  const handleSortByChange = (newValue) => {
    trackEvent(`Community:SortBy:Change:${newValue}`);
    setSortBy(newValue);
  };

  const handleSearchTermChange = (newValue) => {
    setSearchTerm(newValue);
  };

  return (  
    <CommunityFilterContext.Provider value={{ 
      initialize,
      isSearchEnabled : isSearchEnabled && isSearchEnabledProp,
      searchTerm,
      handleSearchTermChange,
      sortBy, 
      handleSortByChange,
      isFiltersDialogOpen, 
      setIsFiltersDialogOpen,
      userId

    }}>
      {props.children}
    </CommunityFilterContext.Provider>
  );
}