import React from 'react';

import {
  useHistory
} from 'react-router';

import { 
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Stack
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { Avatar, LoadableList, LoadableText, LoadableChip } from '@components/atoms';
import { EmptyState } from '@components/molecules';

import config from '@config';
import { toShortDateString } from '@helpers/dates';

export default function ClientsTable(props) {
  const history = useHistory();
  const { clients, isLoading, openAddNewClient } = props;
  
  return(
    <Box sx={{ overflow: 'auto', p: 1 }}>
      {(!isLoading && clients.length == 0 ) &&  
      <Box sx={{ p: 1 }}>
        <EmptyState 
          title='Add Clients'
          message='Once you add some clients they will appear here.'
          iconType='table'
          buttonText='Add New Client'
          buttonAction={openAddNewClient}
          buttonDataTestId='clients-add-client-button'
        /> 
      </Box>
      }
      {(isLoading || clients.length > 0) && 
      <Paper sx={{ width: '100%' }}>
        <TableContainer >
          <Table aria-label="simple table" size="small" >
            <TableHead>
              <TableRow >
                <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Name</TableCell>
                <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Groups</TableCell>
                <TableCell sx={{ color: 'text.secondary', py: 0.5 }}>Created At</TableCell>
                <TableCell sx={{ py: 0.5 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <LoadableList 
                isLoading={isLoading}
                rows={clients}
                getRow={(client) => {
                  return (
                    <TableRow
                      data-testid={`client-row-${client?.account?.id}`}
                      key={client.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                      onClick={() => history.push(`/client/${client.account.id}`)}
                    >
                      <TableCell component="th" scope="row">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar 
                            isLoading={isLoading}
                            size={30}
                            name={client?.account?.name} 
                            src={client?.account?.imageLocation ? 
                              `${config.contentBaseUrl}images/${client?.account?.imageLocation}` : null}/>
                          <Typography sx={{ px: 1 }} >
                            {client?.account?.name}
                          </Typography>
                          {client.account?.status != 'ACTIVE' &&   
                            <LoadableChip 
                              size='small'
                              isLoading={isLoading}
                              label={'Invited'}
                              color='info'
                              variant="outlined" />
                          }
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Stack direction='row' spacing={0.5}>
                          {client?.teams?.map(t => 
                            <LoadableChip 
                              key={t.id}
                              size='small'
                              isLoading={isLoading}
                              label={t.name}
                              color='default'
                              variant="outlined" />
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <LoadableText isLoading={isLoading} text={toShortDateString(client?.createdAt)}/>
                      </TableCell>
                      <TableCell component="th" scope="row" align='right'>
                        <IconButton>
                          <KeyboardArrowRightIcon size='small'/>
                        </IconButton>
                      </TableCell>
                
                    </TableRow>
                  );
                }}
              />
            </TableBody>
       
          </Table>
        </TableContainer>
      </Paper>
      }
    </Box>
  );
}
