import React, { useContext, useState } from 'react';

import {
  Box,
  Divider,
  Typography,
  IconButton
}from '@mui/material';
import { useTheme } from '@emotion/react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExerciseInfoDialog from '../../../../molecules/exercise-info-dialog';
import { NewPlanContext } from '../../context';

export default function Exercise(props) {
  const { exercise, onClick, isAdd } = props;
  const theme  = useTheme();
  const { setExerciseBeingEdited } = useContext(NewPlanContext);

  return (
    <>
      <Box sx={{ p: 1, display: 'flex', width: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant='body1' data-testid={`new-plan-dialog-${isAdd ? 'library' : 'selected'}-exercises-${exercise.id}`}>
            {exercise.name}
          </Typography>
          <Box sx={{ display: 'flex', maxWidth: '100%', flexWrap: 'wrap' }}>
            {exercise.tags.map(t => (
              <Box key={t} sx={{ px: 0.25, m: 0.25, borderWidth: '1px', borderColor: theme.palette.divider, borderStyle: 'solid' }}>
                <Typography fontWeight='light' fontSize='0.75rem'>
                  {t}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          {exercise.tags?.includes('Custom') &&
            <Box>
              <IconButton size='small'
                onClick={() => {
                  setExerciseBeingEdited(exercise);
                }}>
                <EditIcon fontSize='small'/>
              </IconButton>
            </Box>
          }
          {!exercise.tags?.includes('Custom') &&
            <ExerciseInfoDialog exercise={exercise}/>
          }
          <IconButton onClick={onClick} size="small" data-testid={`new-plan-dialog-${isAdd ? 'add' : 'remove'}-exercises-button-${exercise.id}`}>
            {isAdd ? <AddBoxIcon size='small' color='primary'/> : <RemoveCircleIcon size='small' color='disabled'/>}
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
}

