import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  Button
} from '@mui/material';

import Trial from './trial';
import Subscription from './subscription';
import Image from 'mui-image';
import { useTheme } from '@emotion/react';
import Stepper from './stepper';
import { BillingContext, AppContext } from '@context';
import { DeleteAccountDialog, FeedbackDialog } from '@components/v2/organisms';
import AccessCode from './access-code';

export default function Paywall(props) {
  const { handleDelete, isTrial } = props;
  const history = useHistory();
  const { subscription } = useContext(BillingContext);
  const { accessCode, clearAccessCode } = useContext(AppContext);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const [shouldShowDropOffScreen, setShouldShowDropOffScreen] = useState(false);
  const [ isFeedbackDialogOpen, setIsFeedbackDialogOpen ] = useState(false);

  const theme = useTheme();

  return (
    <Box sx={{ height: '100%', backgroundColor: '#FAFAFA' }}>
      {accessCode != false && 
        <AccessCode code={accessCode} clearCode={clearAccessCode}/>
      }
      {accessCode == false &&
      <>
        {(!subscription && !accessCode) && <Trial openDeleteAccount={() => setIsDeleteAccountOpen(true)} />}
        {(subscription && !accessCode && !shouldShowDropOffScreen && !isTrial) && <Subscription openDropOffScreen={() => setShouldShowDropOffScreen(true)} openDeleteAccount={() => setIsDeleteAccountOpen(true)}  />}
        {(subscription && !accessCode && shouldShowDropOffScreen) &&
        <Box sx={{ py: 4, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 2, paddingTop: 3 }}>
            <Image 
              duration={500} 
              width='200px' 
              src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_Dark and grad.png'/>
          </Box>
          <Box sx={{ px: 2, paddingBottom: 1, fontWeight: 'medium', textAlign: 'center', color: theme.palette.braceGreenLight.main }}>
            <Typography variant='h5'>Recovery Is A Tough Journey.</Typography>
          </Box>
          <Box sx={{ px: 1, fontWeight: 'medium', textAlign: 'center', color: theme.palette.braceGreenDark.main  }}>
            <Typography variant='h6'>Brace is empowering our community with the physical and mental health supports to come back stronger, together.</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Stepper />
          </Box>
          <Box sx={{ px: 2, paddingBottom: 3, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button 
              sx={{ marginX: 2, marginTop: 2, marginBottom: 1, py: 1, px: 4, fontSize: 'h6.fontSize', width: '66%', minWidth: '250px' }}
              size='large'
              variant='contained'
              color='braceGreenLight'
              onClick={() => setShouldShowDropOffScreen(false)}
            >
              View Plans
            </Button>
            <Button 
              sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
              size='small'
              color='braceGreenDark'
              onClick={() => {
                if(subscription?.isTrial){
                  setIsFeedbackDialogOpen(true);
                }else{
                  setIsDeleteAccountOpen(true);
                }
                
              }}
            >
                Delete Account
            </Button>
          </Box>
        </Box>
        } 
      </>
      }

      <FeedbackDialog
        isDeleteAccount={true}
        isOpen={isFeedbackDialogOpen} 
        close={(wasSurveyOpen, wasSurveyComplete) => {
          setIsFeedbackDialogOpen(false);
          if(!wasSurveyOpen){
            setIsDeleteAccountOpen(true);
          }
          if(wasSurveyComplete){
            history.push('/');
          }
        }}
      />

      <DeleteAccountDialog
        deleteAccount={handleDelete}
        isOpen={isDeleteAccountOpen} 
        close={() => setIsDeleteAccountOpen(false)}
      />
    </Box>
  );
}