import moment from 'moment';
import { titleCase } from './text';

const ORDERED_DAYS_OF_WEEK = ['MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY','SUNDAY'];

export function toShortDateString(date, includeYear = true){
  return includeYear ? moment(date).format('MMM D,YYYY') :
    moment(date).format('MMM Do');
}

export function toShortDateTimeString(date){
  return moment(date).format('MMM D,YYYY h:mmA');
}

export const getFromNow = (dateString) => {
  var now = moment();
  var date = moment(dateString);
  if(date.year() < now.year()){
    return date.format('DD MMM YYYY');
  }
  else if(date.month() < now.month()){
    return date.format('DD MMM');
  }

  return date.fromNow(true) + ' ago';
};

export const getDaysFromNow = (dateString) => {
  var now = moment();
  var date = moment(dateString);
  return Math.ceil(date.diff(now, 'hours') / 24);
};

export const sortDaysOfWeek = (dayOfWeek) => {
  return  dayOfWeek.sort((d1, d2) => ORDERED_DAYS_OF_WEEK.indexOf(d1) - ORDERED_DAYS_OF_WEEK.indexOf(d2) );
};

export const getShortDateOfWeek = (dayOfWeek) => {
  return titleCase(dayOfWeek?.substring(0,3).toLowerCase());
};

export const areDatesTheSameIgnoringMinutes = (d1, d2) => {
  if(!d1 && !d2){
    return true;
  }
  if(!d1 || !d2){
    return false;
  }
  
  return Math.abs(moment(d1).unix() - moment(d2).unix()) / 60 < 2;
	
};

export const getDateNumber = (date) => {
  return date.date();
};

export const getMonth3Letter = (date) => {
  return date.format('MMM');
};