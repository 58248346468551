import React from 'react';

import { 
  Box,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction
} from '@mui/material';

export default function MenuOptions(props) {
  const { actions = [], disabled } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return(
    <Box>
      <SpeedDial
        hidden={disabled}
        ariaLabel="SpeedDial tooltip example"
        sx={{ 
          position: 'fixed',  
          bottom: 'calc(16px + env(safe-area-inset-bottom))',
          right: 16 }}
        icon={<SpeedDialIcon />}
        FabProps={{ color: 'secondary' }}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => {
              setOpen(false);
              action.onClick();
            }}
            sx={{
              '& .MuiSpeedDialAction-staticTooltipLabel': {
                maxWidth: 'none', 
                whiteSpace: 'nowrap'
              }
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
