import React, { useRef, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { SearchInput } from '@components/v2/atoms';

export default function NewExerciseDialog(props){
  var { selectedCategories, setSelectedCategories, categories } = props;
  const [inputValue, setInputValue] = useState('');
  const autoCompleteRef = useRef(null);

  var options = [];

  categories.forEach((c) => {
    if(selectedCategories.filter(sc => sc.id == c.id).length == 0){
      options.push({ label: c.name, id: c.id });
    }
  });

  return (
    <Autocomplete
      ref={autoCompleteRef}
      fullWidth
      value={null}
      onChange={(event, newValue) => {
        if(newValue){
          var category = categories.filter(c => c.id == newValue.id)[0];
          setSelectedCategories([...selectedCategories, category]);
          setInputValue('');
        }
      }}
      size='small'
      id="categories-search-autocomplete"
      options={options}
      inputValue={inputValue}
      onInputChange={(e, value) => setInputValue(value)}
      openOnFocus={false}
      blurOnSelect={true}
      open={inputValue.length > 0}
      renderInput={(params) => (
        <SearchInput
          inputRef={params.InputProps.ref}
          inputProps={params.inputProps}
          placeholder='Search'
        />
      )}
    />
  );
}
