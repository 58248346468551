import React, { createContext, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { EventsContext } from '@context';

export const NewPostContext = createContext();

const defaultSettings = {
  title:'',
  buttonText: 'POST',
  buttonAction: () => {},
  placeholder: null,
  requiresTitle: false,
  titlePlaceholder: null,
  isImageEnabled: true,
  isAccessControlEnabled: false,
  successToastMessage : '',
  initialImage: null,
  isPrivateEnabled: false
};

export function NewPostProvider(props){
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [settings, setSettings] = useState({});
  const [initialImage, setInitialImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const [team, setTeam] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const { trackEvent } = useContext(EventsContext);

  const openNewPostDialog = (input) => {
    setIsOpen(true);
    setSettings({ ...defaultSettings, ...input });

    if(input.initialImage){
      setInitialImage(input.initialImage);
    }
  };

  const closeNewPostDialog = () => {
    trackEvent('Community:CreateDialog:Close');
    setIsOpen(false);
    setIsSaving(false);
    setInitialImage(null);
    setSettings({});
    setOrganisation(null);
    setTeam(null);
    setIsPrivate(false);
  };

  const save = async ({ message, title, image }) => {
    setIsSaving(true);
    try{
      await settings.buttonAction({ message, title, image, organisation, team, isPrivate });
      if(settings.successToastMessage != ''){
        enqueueSnackbar(settings.successToastMessage, { 
          variant: 'success'
        });
      }
      closeNewPostDialog();

    }catch(e){
      if(e?.data?.message){
        enqueueSnackbar(e?.data?.message, { 
          variant: 'error'
        });
      }else{
        enqueueSnackbar('Oh oh... something went wrong!', { 
          variant: 'error'
        });
      }
    }
    finally{
      setIsSaving(false);
    }

  };

  return (
    <NewPostContext.Provider value={{ 
      isOpen,
      settings,
      openNewPostDialog,
      closeNewPostDialog,
      initialImage,
      save,
      organisation, 
      setOrganisation,
      setIsPrivate,
      team, 
      setTeam,
      isSaving
    }}>
      {props.children}
    </NewPostContext.Provider>
  );
}