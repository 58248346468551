import { createTheme } from '@mui/material/styles';

export const forceLightTheme = (theme) => {
  const lightTheme = createTheme({
    ...theme,
    palette: {
      primary: theme.palette.primary,
      secondary: theme.palette.secondary,
      mode: 'light',
      braceGreenDark: {
        contrastText: '#fff',
        light: '#336569',
        main: '#003F43',
        dark: '#003236'
      },
      braceGreenLight: {
        main: '#00E39A',
        light: '1ae6a4',
        dark: '00cc8b',
        contrastText: '#fff'
      }
    }
  });

  return lightTheme;
};  

export const hexToRGB = (hex, opacity = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  
  // return {r, g, b} 
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};