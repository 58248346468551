import React, { useContext, useEffect } from 'react';

import {
  Box
}from '@mui/material';

import { NewPlanContext } from '../../context';
import { EventsContext } from '@context';
import Buttons from '../buttons';
import ExerciseDetails from './exercise-details';
import Header from '../header';

export default function Index(props) {
  const { handleNext, handleBack } = props;
  const { trackEvent } = useContext(EventsContext);
  const { selectedExercises, isEdit } = useContext(NewPlanContext);

  useEffect(() =>{
    trackEvent('Recovery:PlanCreator:Step:2');
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'scroll' }}>
      <Header title={`${isEdit ? 'Edit' : 'New'} Plan (${selectedExercises.length} Exercises Selected)`} />
      
      <Box sx={{ display: 'flex', p: 1, flexGrow: 1, paddingBottom: '50px' }}>
        <Box sx={{ width: '100%' }}> 
          {selectedExercises.map((e) => (
            <ExerciseDetails key={e.id} exercise={e}/>
          ))}
        </Box>
      </Box>
      <Buttons next={handleNext}
        back={handleBack}
        backText='BACK'
        isNextDisabled={selectedExercises.length === 0}
        allowSaveDraft={false} />
    </Box>
  );
}

