import React from 'react';

import { 
  Box,
  Typography
} from '@material-ui/core';

import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import EventIcon from '@material-ui/icons/Event';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import BadgeIcon from '@mui/icons-material/Badge';
import FlagIcon from '@mui/icons-material/Flag';

import { getFromNow } from '@helpers/dates';

export default function ProfileDetails(props){
  var { profile } = props;
  return (
    <Box display='flex'
      flexDirection='column'
      sx={{
        color: 'text.secondary'
      }}
      height="100%"
      justifyContent="center">
      {profile?.injury?.type && 
        <Box display='flex'>
          <Box >
            <LocalHospitalIcon fontSize="small"/>
          </Box>
          <Box marginLeft={1}>
            <Typography component="div">
              <Box fontSize="body2.fontSize" data-testid='profile-dialog-injury-type-text'>
                {profile?.injury?.type}
              </Box>
            </Typography>
          </Box>
        </Box>
      }
      {(profile?.role != 'PRACTITIONER' && profile?.injury?.activityType) && 
        <Box display='flex'>
          <Box>
            <DirectionsRunIcon fontSize="small"/>
          </Box>
          <Box marginLeft={1}>
            <Typography component="div">
              <Box fontSize="body2.fontSize">
                {profile?.injury?.activityType}
              </Box>
            </Typography>
          </Box>
        </Box>
      }
      {(profile?.role != 'PRACTITIONER' && profile?.injury?.date) && 
        <Box display='flex'>
          <Box>
            <EventIcon fontSize="small"/>
          </Box>
          <Box marginLeft={1}>
            <Typography component="div">
              <Box fontSize="body2.fontSize">
                {getFromNow(profile.injury.date)}
              </Box>
            </Typography>
          </Box>
        </Box>
      }
      {profile?.properties?.occupation && 
        <Box display='flex'>
          <Box>
            <LocalHospitalIcon fontSize="small"/>
          </Box>
          <Box marginLeft={1}>
            <Typography component="div">
              <Box fontSize="body2.fontSize">
                {profile.properties?.occupation}
              </Box>
            </Typography>
          </Box>
        </Box>
      }
      {profile?.properties?.practiceName && 
        <Box display='flex'>
          <Box>
            <BadgeIcon fontSize="small"/>
          </Box>
          <Box marginLeft={1}>
            <Typography component="div">
              <Box fontSize="body2.fontSize">
                {profile.properties?.practiceName}
              </Box>
            </Typography>
          </Box>
        </Box>
      }
      {profile?.properties?.practiceLocation && 
        <Box display='flex'>
          <Box>
            <LocationOnIcon fontSize="small"/>
          </Box>
          <Box marginLeft={1}>
            <Typography component="div">
              <Box fontSize="body2.fontSize">
                {profile.properties?.practiceLocation}
              </Box>
            </Typography>
          </Box>
        </Box>
      }
      {profile?.country && 
        <Box display='flex'>
          <Box>
            <FlagIcon fontSize="small"/>
          </Box>
          <Box marginLeft={1}>
            <Typography component="div">
              <Box fontSize="body2.fontSize">
                {profile?.country}
              </Box>
            </Typography>
          </Box>
        </Box>
      }

    </Box>
  );
}
