import React, { useContext } from 'react';

import { 
  Box, Typography
} from '@mui/material';
import { UserContext } from '@context/user';

import { LoadableText, Icon } from '@components/v2/atoms';
import { useTheme } from '@emotion/react';
import { grey } from '@material-ui/core/colors';

export default function PostPrivacy(props) {
  const { post, isLoading } = props;
  const { activeOrganisation } = useContext(UserContext);
  const theme = useTheme();

  var text = '';
  var icon = 'world';

  if(post?.private){
    text = 'Only You';
    icon = 'lock';
  }
  else if(post?.organisationId && post?.organisationId == activeOrganisation?.id ){
    text = `${activeOrganisation.name} members`;
    icon = 'group';
  }
  else if(post?.teamIds.length > 0 ){
    var teamNames = [];

    post?.teamIds.forEach(teamId => {
      var team = activeOrganisation.myTeams.filter(t => t.id == teamId)[0];

      if(team.name){
        teamNames.push(team.name);
      }
    });

    text = `${teamNames.join(',')} members`;
    icon = 'group';
  }else{
    text = 'Public';
    icon = 'world';
  }

  return (
    <Box sx={{ display : 'flex', alignItems: 'center' }}>
      { text != '' &&
      <>
        <Box sx={{ marginRight: 0.5, lineHeight: 1, display : 'flex', alignItems: 'center' }}>
          <Icon type={icon}
            fill={'none'}
            stroke={grey[500]}
            size={14}/>
        </Box>
        
        <Typography component='div'
          variant='body2'
          color={grey[500]}
          lineHeight={1}>
          <LoadableText minWidth={100} isLoading={isLoading} text={text}/>
        </Typography>
      </>
      }
    </Box>
  );
}