import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { 
  Dialog,
  DialogTitle,
  Slide,
  DialogContent,
  DialogActions,
  Button,
  Box, 
  Stack
} from '@mui/material';

import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';
import { useTheme } from '@mui/material/styles';

import { TextField, MultipleSelect } from '@components/atoms';
import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateGroup(props) {
  const { isOpen, close } = props;
  const { user, activeOrganisation } = useContext(UserContext);

  const { enqueueSnackbar } = useSnackbar();
  const theme  = useTheme();
  const { api } = useContext(ServicesContext);
  const [name, setName] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  
  function handleSubmit(event) {
    event.preventDefault();
    setIsSaving(true);
    api.post({ url: `organisations/${activeOrganisation.id}/teams`, 
      data: { name, accountIds: selectedOptions.map(so => so.id) }, 
      user })
      .then(() => {
        enqueueSnackbar('Successfully create group', { 
          variant: 'success'
        });
        close(true);
        setIsSaving(false);
      })
      .catch((err) => {
        if(err.data.message){
          enqueueSnackbar(err.data.message, { 
            variant: 'error'
          });
        }else{
          enqueueSnackbar('Create Group failed', { 
            variant: 'error'
          });
        }
       
        setIsSaving(false);
      });

  }

  const loadUsers = async () => {
    setIsLoadingOptions(true);
    var result = await api.post({ url: `organisations/${activeOrganisation?.id}/accounts/search`, data:{}, user });
    setOptions(result.results.filter(u => u.role != 'CLIENT' && u.account.status == 'ACTIVE' )
      .map(a => {return { id: a.account.id, label: a.account.name };}));
    setIsLoadingOptions(false); 
  };

  useEffect(() => {
    setName('');
    if(isOpen){
      loadUsers();
    }
  }, [isOpen]);

  return(
    <Dialog 
      sx={{
        '.MuiPaper-root' : {
          [theme.breakpoints.down('md')]: {
            width: '90%'
          },
          [theme.breakpoints.up('md')]: {
            width: '50%'
          }
        }
      }}
      TransitionComponent={Transition}
      open={isOpen}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <DialogTitle  data-testid='groups-add-dialog-title'>{'Create New Group'}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={1}>
              <TextField
                inputProps={{ 'data-testid': 'group-add-text-name' }}
                required
                autoFocus
                size='small'
                margin="dense"
                id="group-name"
                label="Group Name"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                fullWidth
              />
              {(isLoadingOptions || options?.length > 0 ) &&
                <MultipleSelect
                  dataTestId={'group-add-owners-select'}
                  size='small'
                  fullWidth
                  disabled={isLoadingOptions}
                  options={options}
                  label="Group Owners"
                  handleChange={(value) => setSelectedOptions(value)}
                />
              }
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close(false)}>Cancel</Button>
            <LoadingButton 
              data-testid= 'groups-add-submit-button'
              loading={isSaving}
              disabled={name == '' || isLoadingOptions || selectedOptions.length == 0}
              variant='contained'
              color='secondary'
              type="submit">
                Create
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>

  );
}