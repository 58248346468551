import React from 'react';

import { 
  Chip
} from '@mui/material';

const getColors = (type) => {
  switch (type) {
  case 'Injury Stage':
    return {
      text: '#F72585',
      background: 'rgba(247, 37, 133, 0.15)'
    };
  case 'Mental Supports':
    return {
      text: '#FF6700',
      background: 'rgba(255, 103, 0, 0.15)'
    };
  case 'Physical Symptoms':
    return {
      text: '#675BE6',
      background: '#DAD7F9'
    };
  case 'Injury Type':
  default:
    return {
      text: '#00A8E8',
      background: 'rgba(0, 168, 232, 0.12)'
    };
  }
  
};

export default function CategoryChip(props){
  var { 
    category,
    sx
  } = props;

  var colors = getColors(category.type);
  
  return (
    <Chip 
      {...props}   
      sx={{ color: colors.text, background: colors.background, ...sx }}                      
      label={category.name}
    />
    
  );
}