import React, { createContext  } from 'react';
import mixpanel from 'mixpanel-browser';
export const ServicesContext = createContext({});

import Storage from '@services/storage';
import Api from '@services/api';
import Payments from '@services/payments';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Logger } from '@services/logger';

export function ServicesProvider(props){
  
  const config = props.config;

  const appInsights = new ApplicationInsights({ config: {
    connectionString: config.appInsightsKey,
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: ['api-eu1.hubspot.com', 'api.mixpanel.com', 'cdn.contentful.com', 'bracesocial.eu.auth0.com', 'bracesocial-dev.eu.auth0.com', 'auth.bracesocial.com', 'events.statsigapi.net']
  } });
  appInsights.loadAppInsights();
  appInsights.trackPageView();

  mixpanel.init(config.mixPanelKey, { api_host: 'https://api.mixpanel.com' }); 

  return (
    <ServicesContext.Provider value={{
      logger: new Logger(appInsights),
      config,
      storage: new Storage(),
      api: new Api(config),
      payments : new Payments(config, new Logger(appInsights))
    }}>
      {props.children}
    </ServicesContext.Provider>
  );
}