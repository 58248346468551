import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Chip,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  Box
}from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(id, values, theme) {
  return {
    fontWeight:
    values.filter(v => v.id === id).length == 0
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium
  };
}

export default function MultipleSelect(props) {
  const theme = useTheme();
  const { label, fullWidth, options, handleChange, isDisabled, size, dataTestId } = props;
  const [selectedValues, setSelectedValues] = useState([]);

  const onChange = (event) => {
    var ev = event.target.value;
    setSelectedValues(ev);
    handleChange([...ev]);
  };

  return (
    <FormControl fullWidth={fullWidth} size={size} required>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        disabled={isDisabled}
        multiple
        value={selectedValues}
        onChange={onChange}
        size={size}
        MenuProps={MenuProps}
        input={<OutlinedInput id="select-multiple-chip" data-testid={dataTestId} label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value.id} label={value.label} />
            ))}
          </Box>
        )}
      >
        {options.map((option) => (
          <MenuItem
            data-testid={`multi-select-option-${option.id}`}
            key={option.id}
            value={option}
            style={getStyles(option.id, selectedValues, theme)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}