import React, { useContext, useEffect, useState } from 'react';

import { 
  Box,
  Typography,
  Card,
  Grid
} from '@mui/material';
import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';

import { Icon, LoadableText } from '@components/atoms';

export default function DashboardStats(props) {
  const { api } = useContext(ServicesContext);
  const { getUserToken, activeOrganisation } = useContext(UserContext);
  const { userId, groupId } = props;
  const [ isLoading, setIsLoading ] = useState(true);
  const [ stats, setStats ] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    var params ={
      numberOfDays: 30,
      userId,
      teamId: groupId
    };
    
    api.get({ url: `organisations/${activeOrganisation.id}/stats`, getUserToken, params })
      .then((response) =>{
        setStats(response.stats);
        setIsLoading(false);
      });
  }, []);

  var mappings ={
    'SESSIONS_COMPLETE':{
      title: 'Sessions Completed',
      icon: 'stretching'
    },
    'ADHERENCE':{
      title: 'Plan Adherence',
      icon: 'percentage',
      numberFormatAppend: '%'
    },
    'GOALS_COMPLETE':{
      title: 'Goals Achieved',
      icon: 'target'
    },
    'DIARIES':{
      title: 'Diary Entries',
      icon: 'inkwell'
    }
  };

  const BasicStatCard = (props) => {
    var stat = stats.filter(s => s.type == props.type)[0];
    var mapping = mappings[stat?.type];
    return (
      <Grid item xs={6} sm={3}>
        <Card sx={{ display: 'flex', alignItems: 'center', padding: 1, height: '100%' }}>
          <Icon type={mapping?.icon} size='50px' isLoading={isLoading}/>
          <Box sx={{ marginLeft: 0.5 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant='h4' fontWeight='bold' component='div'>
                <LoadableText minWidth='80px' isLoading={isLoading} text={`${Math.round(stat?.currentValue)}${mapping?.numberFormatAppend ?? ''}`}/>
              </Typography>
            </Box>
            <Typography variant='body2' color="text.secondary" component='div'>
              <LoadableText isLoading={isLoading} text={mapping?.title}/>
            </Typography>
          </Box>
        </Card>
      </Grid>
    );
  };

  return(
    <Box sx={{ width: '100%', p: 1 }}>
      <Grid container spacing={1} alignItems="stretch">
        <BasicStatCard type={'SESSIONS_COMPLETE'}/>
        <BasicStatCard type={'ADHERENCE'}/>
        <BasicStatCard type={'GOALS_COMPLETE'}/>
        <BasicStatCard type={'DIARIES'}/>
      </Grid>
    </Box>
  );
}
