import React, { useState } from 'react';

import {
  Box,
  Typography,
  Tooltip
}from '@mui/material';

export default function AlphabeticScroller(props) {
  const { handleChange, selectedLetter, isEnabled } = props;
  const [isScrolling, setIsScrolling] = useState(false);
  const [value, setValue] = useState('');

  const alphabet = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ#'];

  const handleLetterSelect = (letter) => {
    setValue(letter);
    if(letter != selectedLetter && isEnabled) {
      handleChange(letter);
    }
  };

  const onTouchMove = (e) => {
    var location = e.changedTouches[0];
    var realTarget = document.elementFromPoint(location.clientX, location.clientY);
    if(realTarget?.id){
      var id = realTarget.id;
      if(id?.startsWith('alphabet-button-')){
        handleLetterSelect(id.split('-')[2]);
      }
    }
  };

  return (
    <Box 
      sx={{ 
        position: 'absolute', 
        right: 0, 
        top: 'calc(env(safe-area-inset-top) + 44px)', 
        height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 44px)', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px'
      }}
      onTouchMove={() => setIsScrolling(true)}
      onTouchEnd={() => setIsScrolling(false)}
    >
      {alphabet.map((letter) => 
        
        <Box key={letter}
          sx={{ width: '100%', textAlign: 'center' }}
          onClick={() => handleLetterSelect(letter)}
          onTouchMove={onTouchMove}>
          <Tooltip title={letter}  placement="left" open={letter == value && isScrolling}>
            <Typography 
              id={`alphabet-button-${letter}`}
              component='div'
              variant='caption'
              color={isEnabled ? 'text.primary' : 'text.disabled'}
              sx={{ lineHeight: 1.25 }}>
              {letter}
            </Typography>
          </Tooltip>
        </Box>
        
      )}
    </Box>
  );
}