import React from 'react';
import { styled } from '@mui/material/styles';
import { 
  Tabs as MuiTabs,
  Box
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  boxShadow: 'none',
  minHeight: '36px',
  '& button':{
    padding:'0px',
    minHeight: '36px'
  },
  '& .MuiTabs-root':{
    minHeight: '36px'
  },
  '& .MuiTabs-indicator':{
    width:'33%!important',
    height: '0px'
  },
  '& .Mui-selected':{
    color: '#fff',
    backgroundColor:theme.palette.primary.main
  }
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ background: theme.palette.mode === 'dark' ? grey[900] : '#fafafa'  }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  };
}