import React from 'react';

import { 
  Box
} from '@mui/material';
import { useTheme } from '@emotion/react';

import { Widget } from '@typeform/embed-react';

export default function FeedbackSurvey(props){
  const { close, userId, userFirstName, userEmail, wasSubmitted } = props;
  const theme = useTheme();

  const widgetContainerStyle = {
    width: '100%',
    height: '100%'
  };

  return (
    <Box 
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: '100%'
      }}>
      <Widget 
        // id='P2lKkQpD' //-- Test
        id="x5TjeCss"  //-- Real
        inlineOnMobile={true}
        fullScreen={false}
        style={widgetContainerStyle}
        hidden={{
          first_name: userFirstName,
          user_id: userId,
          email: userEmail
        }}
        onSubmit={() => {
          wasSubmitted.current = true;
        }}
        onClose={() => {
          close();
        }}
        onEndingButtonClick={() => {
          close();
        }} />
    </Box>
  );
}