import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { 
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  DialogActions,
  Select,
  Button,
  Box,
  Alert
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { ServicesContext } from '@context/services';
import { UserContext } from '@context/user';
import { ClientContext } from '../../client-context';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssignPlan(props) {
  const { isOpen, close } = props;
  const { id, profile, loadPlans } = useContext(ClientContext);
  const [plans, setPlans] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [isSaving, setIsSaving] = useState();
  const { api } = useContext(ServicesContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setIsSaving(false);
    api.get({ url: 'recovery/plans', user })
      .then(result => {
        setPlans(result.results);
      });
  }, [isOpen]);

  const handleChange = (event) => {
    setSelectedPlanId(event.target.value);
  };

  const handleClose = () => {
    close();
  };

  const handleSave = () => {
    setIsSaving(true);
    api.put({ url: `recovery/plans/${selectedPlanId}/assign`, data: { assigneeUserId : id }, user })
      .then(() => {
        enqueueSnackbar(`Successfully assigned exercise plan to ${profile.name}`, { 
          variant: 'success'
        });
        loadPlans();
        close();
      })
      .catch(() => {
        enqueueSnackbar('Failed to assign exercise plan', { 
          variant: 'error'
        });
        setIsSaving(false);
      });
  };

  return(
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth='sm'
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Assign Exercise Plan'}</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Plan Name</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPlanId}
                label="Exercise Plan"
                onChange={handleChange}
              >
                {plans.map(p => 
                  <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Cancel</Button>
          <LoadingButton 
            loading={isSaving}
            variant='contained'
            onClick={handleSave}
            disabled={selectedPlanId == ''}>
              Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
