import React from 'react';

import { 
  Divider as MuiDivider
} from '@mui/material';
import { useTheme } from '@emotion/react';

export default function Divider(props){
  const { light, ...muiProps } = props;
  const theme = useTheme();

  return (
    <MuiDivider 
      sx={{
        borderColor: light ?
          'rgba(0, 149, 158, 0.12)':
          theme.palette.divider
      }}
      {...muiProps}
    />
  );
}