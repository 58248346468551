import { create } from 'zustand';

const isComplete = (trophy) => {
  return (trophy.level > 0 && trophy.progress >= trophy.levelCompleteAt && !trophy.hasNextLevel);
};

const useTrophiesStore = create((set) => ({
  hasLoaded: false,
  isLoading: false,
  completeCount: 0,
  trophies: [],
  load: async (api, getUserToken) => {
    set({ isLoading: true });
    var results = await api.get({ url: 'trophies',  getUserToken });
    set({ 
      isLoading: false, 
      hasLoaded: true, 
      notifications: results,
      completeCount: results.filter(t => isComplete(t)).length 
    });
  }
}));

export default useTrophiesStore;