import React, { useState, useContext, useEffect } from 'react';

import { 
  Box,
  Table,
  TableContainer,
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton
} from '@mui/material';

import FactCheckIcon from '@mui/icons-material/FactCheck';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { LoadableText, LoadableList } from '@components/atoms';
import { ConfirmationModal, EmptyState } from '@components/molecules';
import { toShortDateString } from '@helpers/dates';

import { UserContext, ServicesContext, EventsContext } from '@context';

import { Page } from '../../components/organisms';
import { MenuOptions } from '../../components/molecules';

import { NewPlanDialog } from '@components/templates';
import AssignPlansDialog from './components/assign-plan-dialog';

export default function Plans() {
  const [isNewPlanDialogOpen, setIsNewPlanDialogOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [planBeingEdited, setPlanBeingEdited] = useState(null);
  const [planBeingDeleted, setPlanBeingDeleted] = useState(null);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [isDeletingPlan, setIsDeletingPlan] = useState(false);
  const [planToAssign, setPlanToAssign] = useState(null);
  const [isAssigningPlans, setIsAssigningPlans] = useState(false);

  const { trackEvent } = useContext(EventsContext);

  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);

  const loadPlans = () => {
    setIsLoadingPlans(true);
    api.get({ url: 'recovery/plans', user })
      .then((response) => {
        setIsLoadingPlans(false);
        setPlans(response.results);
      });
  };

  const deletePlan = async () => {
    setIsDeletingPlan(true);
    await api.delete({ url: `recovery/plans/${planBeingDeleted.id}`, user });
    setIsDeletingPlan(false);
    loadPlans();
  };

  useEffect(() => {
    loadPlans();
    trackEvent('Plans:Open');
  }, []);

  const PlanTableRow = (props) => {
    var { plan } = props;
    return (
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell >
          <LoadableText isLoading={isLoadingPlans} text={plan.name}/>
        </TableCell>
        <TableCell >
          <LoadableText isLoading={isLoadingPlans} text={toShortDateString(plan.createdAt)}/>
        </TableCell>
        <TableCell align="right" >
          <IconButton onClick={() => {
            setPlanToAssign(plan);
          }}
          disabled={isLoadingPlans || isDeletingPlan || isAssigningPlans}
          >
            <PersonAddIcon fontSize="small"/>
          </IconButton>
          <IconButton onClick={() => {
            setPlanBeingEdited(plan);
            setIsNewPlanDialogOpen(true);
          }}
          disabled={isLoadingPlans || isDeletingPlan || isAssigningPlans}
          >
            <EditIcon fontSize="small"/>
          </IconButton>
          <IconButton onClick={() => {
            setPlanBeingDeleted(plan);
          }}
          disabled={isLoadingPlans || isDeletingPlan || isAssigningPlans}
          >
            <DeleteIcon fontSize="small"/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return(
    <Page>
      <Box sx={{ width: '100%', p: 1 }}>
        <Typography sx={{ fontWeight: 'medium', py: 1, fontSize: 'h5.fontSize' }}>
          Plans
        </Typography>
        {(isLoadingPlans || plans.length > 0) &&
          <Paper sx={{ width: '100%' }}>
            <TableContainer>
              <Table aria-label="simple table" size="small" >
                <TableHead>
                  <TableRow >
                    <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Name</TableCell>
                    <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Created</TableCell>
                    <TableCell sx={{ color: 'text.secondary', py: 0.5 }} ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <LoadableList 
                    isLoading={isLoadingPlans}
                    rows={plans}
                    getRow={(plan) => {return (<PlanTableRow plan={plan} key={plan.id}/>);} }
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        }
        {(!isLoadingPlans && plans.length === 0) && 
          <EmptyState 
            title='Create A Plan'
            message='You can create plans here and assign them to your clients.'
            buttonAction={() => {setIsNewPlanDialogOpen(true);}}
            buttonText='Create a Plan'
            iconType='to-do-list'
          /> 
        }
      </Box>
      <MenuOptions 
        actions={[
          { icon: <FactCheckIcon />, name: 'Create Plan', onClick: () => {setIsNewPlanDialogOpen(true);} }
        ]}/>
      <NewPlanDialog isOpen={isNewPlanDialogOpen}
        close={(shouldRefresh) => {
          setIsNewPlanDialogOpen(false);
          setPlanBeingEdited(null);
          if(shouldRefresh){
            loadPlans();
          }
        }}
        planBeingEdited={planBeingEdited}/>
      {planBeingDeleted &&
        <ConfirmationModal 
          title={`Delete "${planBeingDeleted.name}"?`}
          message='If you delete this plan you will no longer be able to assign it to your clients. Clients that have already been assigned this plan will continue to see it.'
          isOpen={planBeingDeleted != null}
          isDeleteAction={true}
          handleClose={() => {
            setPlanBeingDeleted(null);
          }}
          handleConfirmation={deletePlan} />
      }
      {planToAssign && 
      <AssignPlansDialog 
        isOpen={planToAssign != null}
        close={() => setPlanToAssign(null)}
        plan={planToAssign}
      />
      }
       
    </Page>
  );
}
