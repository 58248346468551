import React, { useContext } from 'react';
import { UserContext } from '@context/user';

import { Box, ThemeProvider, Typography, Button } from '@mui/material';

import Index from './pages/index';
import Dashboard from './pages/dashboard';
import Plans from './pages/plans';
import Client from './pages/client';
import Groups from './pages/groups';
import Group from './pages/group';
import Settings from './pages/settings';
import Onboarding from './pages/onboarding';
import Community from './pages/community';
import ProfileEdit from './pages/profile';

import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import { EmptyState, LoadingPage } from '@components/molecules';
import { NewPost } from '@components/organisms';
import { Profile } from '@components/templates';
import { useTheme } from '@emotion/react';
import { forceLightTheme } from '@helpers/theme';

export default function App() {
  var { isLoading, user, handleLogout } = useContext(UserContext);
  var theme = useTheme();
  if(isLoading){
    return (    
      <LoadingPage />
    );
  }

  if(user && user?.status != 'PENDING' && user.role == 'CLIENT'){
    return (
      <ThemeProvider theme={forceLightTheme(theme)}>
        <Box sx={{ 
          background: '#fff', 
          height: '100vh', 
          width: '100vw', 
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1 }}>
          <EmptyState 
            shouldHideBorder={true}
            iconType='mobile'
            buttonVariant='outlined'
            title='It looks like you have joined Brace as a Client?' 
            message={<Box sx={{ px: 2 }}>
              <Typography>Please download the Brace mobile app in the app store and login to your account. Thanks!</Typography>
              <Box sx={{ p: 2 }}>
                <Button 
                  variant='contained'
                  fullWidth
                  href='https://swt9s.mjt.lu/lnk/BAAABMAlmJAAAAAAAAAAAPX6nnIAAYCtpOwAAAAAACVMVQBlSA9Jr_4BNvoER6aP70e1vSyV9AAi21c/2/jiWviF8wqUvTYTgN_Zy26g/aHR0cHM6Ly9vbmVsaW5rLnRvL3U4bnZlcA'>
                    Download App
                </Button>
              </Box>
            </Box>}
            buttonText='Logout'
            buttonAction={handleLogout}
          />
        </Box>
      </ThemeProvider>
    );
  }

  return(
    <Router>
      <Box sx={{ 
        width: '100%', 
        padding:'env(safe-area-inset-top,0) env(safe-area-inset-right,0) env(safe-area-inset-bottom,0) env(safe-area-inset-left,0)' }}>
        <Switch>
          <Route path="/plans">
            <Plans />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/clients">
            <Dashboard />
          </Route>
          <Route path="/client/:id">
            <Client />
          </Route>
          <Route path="/community">
            <Community />
          </Route>
          <Route path="/group/:id">
            <Group />
          </Route>
          <Route path="/groups">
            <Groups />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/onboarding">
            <Onboarding />
          </Route>
          <Route path="/profile/edit">
            <ProfileEdit />
          </Route>
          <Route path="/index">
            <Index />
          </Route>
          <Route path="/:inviteToken">
            <Index />
          </Route>
          <Route path="/">
            <Index />
          </Route>
        </Switch>
        <NewPost />
        <Profile />
      </Box>
    </Router>
    
  );
}