import React, { useContext } from 'react';

import {
  Box, 
  IconButton,
  Typography,
  Input,
  Button,
  Menu,
  MenuItem
}from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { NewPlanContext } from '../../context';

function SetInput(props){
  var { value, onChange, onBlur } = props;

  return (
    <Input 
      type='number'     
      value={value}
      onChange={(value) => onChange(value.target.value)} 
      onBlur={onBlur}
      disableUnderline
      sx={{ '& input[type=number]': {
        'MozAppearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        'WebkitAppearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        'WebkitAppearance': 'none',
        margin: 0
      } }}
      inputProps={{ 'aria-label': 'description', style: { textAlign: 'center', fontSize: '1.25rem', padding: '0px' } }} />
  );
}

const measurementTypes = ['Weight', 'Seconds', 'Minutes', 'Metres', 'Kilometres'];

export default function ExerciseSet(props) {
  const { exercise, set } = props;
  const { copySet, removeSet, updateSetRepsValue, updateSetMeasurement, updateExerciseMeasurementType } = useContext(NewPlanContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const translateMeasurementType = (measurementType) => {
    switch(measurementType){
    case 'SECONDS':
      return 'Sec';
    case 'MINUTES':
      return 'Min';
    case 'METRES':
      return 'M';
    case 'KILOMETRES':
      return 'Km';
    case 'WEIGHT':
    default:
      return 'Kg';
    }
  };

  const handleSetsBlur = () => {
    if(!set.quantity || set.quantity < 1){
      updateSetRepsValue(exercise.id, set, 1);
    }
  };

  const handleMeasurementTypeButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMeasurementTypeClose = (measurementType) => {
    if(typeof measurementType === 'string' || measurementType instanceof String){
      updateExerciseMeasurementType(exercise.id, measurementType.toUpperCase());
    }

    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', textAlign: 'center' }}>
      <Typography component="div" sx={{ flexGrow: 1 }}>
        <Box fontSize="body1.fontSize" fontWeight='medium'>
          {`Set ${set.number}`}
        </Box>
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <Typography component="div">
          <Box fontSize="body2.fontSize" color='text.secondary'>
              Reps
          </Box>
        </Typography>
        <SetInput value={set.quantity != 0 ? set.quantity : ''} onChange={(value) => updateSetRepsValue(exercise.id, set, value)} onBlur={handleSetsBlur}/>
      </Box>
      <CloseIcon />
      <Box sx={{ textAlign: 'center' }}>
        <Box fontSize="caption.fontSize" color='text.secondary'>
          <Button aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMeasurementTypeButtonClick}
            sx={{ padding: 0 }}
            endIcon={anchorEl ? <ArrowDropUpIcon size='small' /> : <ArrowDropDownIcon size='small' /> }>
            <Typography component="div">
              <Box fontSize="caption.fontSize" color='primary.main'>
                {translateMeasurementType(exercise.measurementType)}
              </Box>
            </Typography>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMeasurementTypeClose}
          >
            {measurementTypes.map((measurementType) => 
              <MenuItem sx={{
                minHeight: 0,
                padding: 1
              }}
              key={measurementType}
              onClick={() => handleMeasurementTypeClose(measurementType)}>{measurementType}</MenuItem>
            )}
          </Menu>
        </Box>
        <SetInput value={set.measurement >= 0 ? set.measurement : ''} onChange={(value) => updateSetMeasurement(exercise.id, set, value)} onBlur={() => {}}/>
      </Box>
      <IconButton size='small' onClick={() => copySet(exercise.id, set)}>
        <ContentCopyIcon />
      </IconButton>
      <IconButton size='small'
        onClick={() => removeSet(exercise.id, set)}
        disabled={exercise.sets.length === 1}>
        <RemoveCircleIcon />
      </IconButton>
    </Box>
  );
}

