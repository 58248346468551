
import React from 'react';

import { Box } from '@mui/material';

export default function Dot(props){
  const { sx } = props;
  return (
    <Box sx={{
      ...sx,
      height: 3,
      width: 3,
      backgroundColor: '#D4D7D6',
      borderRadius: '50%',
      display: 'inline-block'
    }}></Box>
  );
}