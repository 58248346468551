import React from 'react';

import {
  Box,
  Stack,
  Skeleton,
  Card
} from '@mui/material';

export default function LoadingCard(){
  return(
    <Card>
      <Stack direction="row">
        <Box sx={{ padding: 1 }}>
          <Skeleton variant="circular" height={45} width={45} />
        </Box>
        <Box flexGrow={1} sx={{ padding: 1 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Box>
      </Stack>
    </Card>
  );
}
