import React from 'react';

import moment from 'moment';

import {
  Box
} from '@mui/material';

import { ProgressSpinner }  from '@components/molecules';

const getValue = (dailyStreakStats, dailyDiff) => {
  if(!dailyStreakStats)
    return null;
  
  var today = dailyStreakStats?.dailyCompletionStats?.filter(d => d.dayDifference === (-dailyDiff + 1));
  
  if(today && today.length === 1){
    return today[0].completionPercentage;
  }  
  
  return null;
};

function DailyProgressSpinner(props) {
  return (
    <ProgressSpinner fontVariant={'body1'}
      size={40}
      value={getValue(props.stats, props.dayDiff)} />
  );
}

export default function DailyStreak(props) {
  const { dailyStreakStats } = props;

  function DailyBox(props){
    const { dayDiff } = props;
    var date = moment().add(props.dayDiff, 'days');
  
    return (
      <Box sx={{ width : '14.28%', padding: '1px' }}>
        <Box 
          sx={{ 
            border: '2px solid', 
            borderColor: dayDiff === 0 ? 'primary.main' : '#e0e0e0', 
            borderRadius: 2, 
            display: 'flex', 
            justifyContent:'center' }}>
          <Box>
            <Box sx={{ textAlign: 'center', paddingTop: 1 }}>
              <Box sx={{ fontWeight: 'medium', color: 'text.secondary', lineHeight: '1'  }}>
                {date.clone().format('ddd').toUpperCase()}
              </Box>
              <Box sx={{ fontSize:'h4.fontSize', fontWeight: 'medium', color: 'text.primary', lineHeight: '1' }}>
                {date.clone().format('DD').toUpperCase()}
              </Box>
            </Box>
            <DailyProgressSpinner 
              stats={dailyStreakStats} 
              dayDiff={dayDiff}  />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display='flex'
      width='100%'
      justifyContent="center">
      <DailyBox dayDiff={-6}/>
      <DailyBox dayDiff={-5}/>
      <DailyBox dayDiff={-4}/>
      <DailyBox dayDiff={-3}/>
      <DailyBox dayDiff={-2}/>
      <DailyBox dayDiff={-1}/>
      <DailyBox dayDiff={0}/>
    </Box>
  );
}