import config from '@config';

const LogLevel = Object.freeze({
  Trace: 0,
  Debug: 1,
  Info: 2,
  Warn: 3,
  Error: 4
});

// AI values
// export enum SeverityLevel
//     {
//         Verbose = 0,
//         Information = 1,
//         Warning = 2,
//         Error = 3,
//         Critical = 4,
//     }

class Logger {
  constructor(aiLogger){
    this.aiLogger = aiLogger;
  }
  log(level, message, properties={}){
    if(level < config.logLevel)
      return;

    switch(level){
    case LogLevel.Trace:
      this.aiLogger.log('TRACE : ' + message);
      break;
    case LogLevel.Debug:
      this.aiLogger.trackTrace({
        message: message,
        severityLevel:0,
        properties
      });

      if(config.consoleLoggingEnabled)
        console.log(`DEBUG : ${message}`);

      break;
    case LogLevel.Info:
      this.aiLogger.trackTrace({
        message: message,
        severityLevel:1,
        properties
      });

      if(config.consoleLoggingEnabled)
        console.log(`INFO : ${message}`);

      break;
    case LogLevel.Warn:
      this.aiLogger.trackTrace({
        message: message,
        severityLevel:2,
        properties
      });

      if(config.consoleLoggingEnabled)
        console.warn('WARN : ' + message);

      break;
    case LogLevel.Error:
      this.aiLogger.trackTrace({
        message: message,
        severityLevel:3,
        properties
      });
        
      if(config.consoleLoggingEnabled)
        console.error('ERROR : ' + message);

      break;
    }
  }
  debug(message, properties={}){
    this.log(LogLevel.Debug, message, properties);
  }
  info(message, properties={}){
    this.log(LogLevel.Info, message, properties);
  }
  warning(message, properties={}){
    this.log(LogLevel.Warn, message, properties);
  }
  error(message, error, properties={}){
    if (error?.message) {
      message += error.message;
    }
    if (error?.stack) {
      message += ' | stack: ' + error.stack;
    }

    this.log(LogLevel.Error, message, properties);
    this.aiLogger.trackException({ exception: error });
  }
}

export {
  Logger,
  LogLevel
};