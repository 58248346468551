import React from 'react';

export default function TargetIcon (props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79087 16 12 16C14.2091 16 16 14.2091 16 12C16 11.4477 16.4477 11 17 11C17.5523 11 18 11.4477 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.6863 8.6863 6 12 6C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8Z"
        fill={props.stroke}  />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.92487 5.92487 1 12 1C12.7521 1 13.4876 1.07563 14.1989 1.22003C14.7402 1.3299 15.0899 1.85774 14.98 2.39898C14.8701 2.94023 14.3423 3.28992 13.8011 3.18005C13.22 3.06209 12.6177 3 12 3C7.02943 3 3 7.02943 3 12C3 16.9705 7.02944 21 12 21C16.9705 21 21 16.9705 21 12C21 11.3823 20.9379 10.78 20.82 10.1988C20.7102 9.65757 21.0599 9.12978 21.6012 9.01997C22.1424 8.91016 22.6702 9.25991 22.78 9.80117C22.9243 10.5124 23 11.2479 23 12C23 18.0751 18.0751 23 12 23C5.92486 23 1 18.0751 1 12Z"
        fill={props.stroke}  />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7.58613V5.00034C15 4.73513 15.1054 4.48077 15.2929 4.29324L18.2797 1.30645C18.8501 0.736066 19.826 1.03149 19.9842 1.82255L20.3498 3.65051L22.1778 4.0161C22.9688 4.1743 23.2643 5.15025 22.6939 5.72066L19.71 8.70452C19.5206 8.89393 19.2637 9.00034 18.9959 9.00034H16.4142L12.7071 12.7074C12.3166 13.098 11.6834 13.098 11.2929 12.7074C10.9024 12.3169 10.9024 11.6838 11.2929 11.2932L15 7.58613Z"
        fill={props.stroke}  />
    </svg>
  );
}

