import React, { useEffect, useContext, useState } from 'react';

import {
  Box,
  Typography,
  Card,
  Grid,
  Stack
} from '@mui/material';

import { orange } from '@mui/material/colors';

import { DailyStreak } from '@components/molecules';
import { ServicesContext } from '@context/services';
import { UserContext } from '@context/user';
import ProgramsList from './programs/programs-list';

import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

export default function RecoveryTab(props){
  const { profile } = props;
  const { api } = useContext(ServicesContext);
  const { user } = useContext(UserContext);
  const [dailyStreakStats, setDailyStreakStats] = useState(null);

  useEffect(() => {
    if(profile?.id){
      api.get({ url: 'v1.1/recovery/stats/daily', user, params: { userId: profile?.id } })
        .then(stats => {
          setDailyStreakStats(stats);
        });
    }
  }, [profile?.id]);

  return (
    <Box sx={{ p: 1 }}>
      <Typography component="div">
        <Box sx={{ fontSize: 'h6.fontSize', fontWeight:'medium', color: 'text.primary' }}>
          Recovery
        </Box>
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <DailyStreak dailyStreakStats={dailyStreakStats} />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ width: '100%', display: 'flex', flexDirection: 'row', padding: 1 }}>
            <Box sx={{ lineHeight: 1 }}>
              <LocalFireDepartmentIcon sx={{ color: dailyStreakStats?.currentStreak > 1 ? orange[600] : 'text.disabled', fontSize: 50 }}/>
            </Box>
            <Stack justifyContent="center">
              <Typography component="div">
                <Box sx={{ fontSize: 'body2.fontSize', fontWeight:'medium', color: 'text.primary', lineHeight: 1 }}>
                  {`${dailyStreakStats?.currentStreak ?? 0} ${dailyStreakStats?.longestStreak === 1 ? 'Day' : 'Days'}`}
                </Box>
                <Box sx={{ fontSize: 'body2.fontSize', fontWeight:'normal', color: 'text.secondary' }}>
                  Current Streak
                </Box>
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ width: '100%', display: 'flex', flexDirection: 'row', padding: 1 }}>
            <Box sx={{ lineHeight: 1 }}>
              <LocalFireDepartmentIcon  sx={{ color: dailyStreakStats?.longestStreak > 1 ? orange[600] : 'text.disabled', fontSize: 50 }}/>
            </Box>
            <Stack justifyContent="center">
              <Typography component="div">
                <Box sx={{ fontSize: 'body2.fontSize', fontWeight:'medium', color: 'text.primary', lineHeight: 1 }}>
                  {`${dailyStreakStats?.longestStreak ?? 0} ${dailyStreakStats?.longestStreak === 1 ? 'Day' : 'Days'}`}
                </Box>
                <Box sx={{ fontSize: 'body2.fontSize', fontWeight:'normal', color: 'text.secondary' }}>
                  Longest Streak
                </Box>
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ProgramsList profile={profile}/>
        </Grid>
      </Grid>
    </Box>
  );
}
