import React from 'react';

import { Box, Radio } from '@components/v2/atoms';
import { Typography } from '@mui/material';

export default function BulletPoint(props) {
  const { text } = props;
  return (
    <Box flex py={0.5}>
      <Radio checked readOnly sx={{ p: 0, marginRight: 1 }}/>
      <Typography>
        {text}
      </Typography>
      
    </Box>
  );
}