import React, { useContext, useState } from 'react';
import { Share } from '@capacitor/share';
import { Link, useHistory } from 'react-router-dom';
import { 
  Box,
  IconButton,
  Stack,
  Typography
} from '@mui/material';

import { Card, Divider, Icon } from '@components/v2/atoms';
import { UserDetails } from '@components/v2/molecules';

import { LoadableText, Image } from '@components/atoms';
import { useTheme } from '@emotion/react';
import { UserContext, EventsContext } from '@context';
import { LikeAndCommentButtons, ConfirmationModal } from '@components/molecules';
import  { PostFeedContext } from './post-feed-context';
import PostPrivacy from './post-privacy';
import { NewPostContext } from '../new-post/new-post-context';
import { grey } from '@mui/material/colors';
import { isNative } from '@helpers/device';

export default function Post(props) {
  const { user } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { post, isLoading } = props;
  const { openNewPostDialog } = useContext(NewPostContext);
  const { deletePost, likePost, saveComment, setPostBeingViewed } = useContext(PostFeedContext);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  var theme = useTheme();
  var history = useHistory();

  const openCreateComment = () => {
    trackEvent('Community:Posts:Comment:Create:Open', { postId: post.id });
    openNewPostDialog({ 
      title: 'New Check-In', 
      placeholder: 'Your comment...', 
      buttonAction: ({ message }) => {saveComment({ postId: post.id, message });}, 
      successToastMessage : 'Comment Posted!',
      isImageEnabled: false
    });
  };

  const share = async () => {
    trackEvent('Community:Share:Open');
    var options = {
      text: post.message,
      subject: null, // fi. for emailan array of filenames either locally or remotely
      url: null,
      dialogTitle: 'Brace' // Android only, you can override the default share sheet title
    };
  
    await Share.share(options);
  };

  var linkTo = `/community?checkInId=${post?.id}`;

  if(post?.metadata){
    if(post?.metadata.type == 'NEW_TOPIC'){
      linkTo = `/community?topicId=${post?.metadata.topicId}`;
    }
    
  }

  const handleClick = () => {
    if(post?.metadata){
      if(post?.metadata.type == 'NEW_TOPIC'){
        history.push(`/community?topicId=${post?.metadata.topicId}`);
        return;
      }
    }

    setPostBeingViewed(post);
  };

  return (
    <Box sx={{ px: 2, py: '6px' }}>
      <Card sx={{ p: 0 }}>
        <Box>
          <Box sx={{ display: 'flex', p : 1.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <UserDetails user={post?.user} isLoading={isLoading}/>
            </Box>
            {post?.pinned &&
              <Box sx={{ display: 'flex', color: grey[500], justifyContent: 'center' }}>
                <Icon size={12}
                  type='pin'
                  fill={grey[500]}
                  stroke={grey[500]}/>
                <Typography sx={{ marginLeft: 0.5, lineHeight: 1 }} variant='caption'>
                  Pinned
                </Typography>
              </Box>
            }
          </Box>
          <Box sx={{ px:2, paddingBottom: 1.5 }}>
            <Box sx={{ typography: 'body2', color: 'text.primary' }} onClick={handleClick}>
              <LoadableText text={post?.message}
                isLoading={isLoading}
                minWidth={100}
                dataTestId={`post-${post?.id}-message`}/>
            </Box>
          </Box>
          {post?.imageUrl &&
          <Box sx={{ paddingBottom: 0.5, px: 1.5 }}>
            <Box onClick={handleClick}>
              <Image src={post?.imageUrl}
                sx={{ 
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '80%'
                  },
                  [theme.breakpoints.only('md')]: {
                    maxWidth: '50%'
                  },
                  [theme.breakpoints.up('lg')]: {
                    maxWidth: '33%'
                  }   
                  
                }} />
            </Box>
          </Box>
          }
          <Divider />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2, py: 0.5 }}>
            <Stack spacing={1} direction="row">
              {post?.metadata?.type == 'NEW_TOPIC' && 
              <Box sx={{ py: 0.5, textDecorationColor: theme.palette.text.disabled }} component={Link} to={linkTo}>
                <Typography sx={{ marginLeft: 0.5, color: 'text.disabled', textDecorationColor: theme.palette.text.disabled }} variant='body2'>
                  Go To Topic
                </Typography>
              </Box>
              }
              {post?.metadata?.type != 'NEW_TOPIC' && 
               <LikeAndCommentButtons 
                 isLoading={isLoading}
                 data={{ likeCount:post?.likeCount, commentCount:post?.commentCount, likedByCurrentUser: post?.likedByCurrentUser }}
                 likeAction = {() => likePost(post.id)}
                 commentAction={openCreateComment}
               />
              }
              {(user?.id && user?.id == post?.user.id) && 
              <>
                <IconButton sx={{ p: 0 }} onClick={() => setIsDeleteConfirmationModalOpen(true)}>
                  <Icon 
                    type='delete'
                    size={16}
                    fill={'none'}
                    stroke={theme.palette.text.disabled} />
                </IconButton>
                {isNative() &&
              <IconButton sx={{ p: 0 }} onClick={() => share()}>
                <Icon 
                  type='share'
                  size={16}
                  fill={theme.palette.text.disabled}
                  stroke={theme.palette.text.disabled} />
              </IconButton>
                }
              </>
              }
            </Stack>
            <PostPrivacy isLoading={isLoading} post={post}/>
          </Box>
        </Box>
        <ConfirmationModal isOpen={isDeleteConfirmationModalOpen} 
          handleClose={() => setIsDeleteConfirmationModalOpen(false)}
          title='Are You Sure?'
          message='If you delete this post it will no longer appear for other users.'
          confirmationSuccessMessage = 'Post deleted.'
          handleConfirmation={async () => {
            await deletePost(post.id);
          }}
        />
      </Card>
    </Box>
  );
}