import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Card,
  Typography,
  Checkbox,
  TextField,
  IconButton
}from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { NewPlanContext } from '../../context';

import Buttons from '../buttons';
import { useTheme } from '@emotion/react';
import AlphabeticScroller from './alphabetic-scroller';
import { hexToRGB } from '@helpers/theme';
import { titleCase } from '@helpers/text';
import Header from '../header';
import { LoadableText } from '@components/atoms';
import NewExerciseDialog from './new-exercise-dialog';
import { ExerciseInfoDialog } from '@components/molecules';
import { EventsContext } from '@context/events';

export default function PickExercises(props) {
  const theme = useTheme();
  const PAGE_SIZE = 40;
  const { handleNext } = props;
  const { trackEvent } = useContext(EventsContext);
  const { isLoadingExercises, exercises, selectedExercises, addSelectedExercise, clearSelectedExercises, isEdit, removeSelectedExercise, exerciseBeingEdited, setExerciseBeingEdited, close } = useContext(NewPlanContext);
  const [ selectedLetter, setSelectedLetter ] = useState(null);
  const [ searchText, setSearchText ] = useState('');
  const [ isNewExerciseDialogOpen, setIsNewExerciseDialogOpen] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [currentRangeStart, setCurrentRangeStart]= useState(0);
  const [currentRangeEnd, setCurrentRangeEnd]= useState(PAGE_SIZE);

  const alphabet = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ#'];

  useEffect(() =>{
    trackEvent('Recovery:PlanCreator:Step:1');
  }, []);

  useEffect(() => {
    if(selectedLetter != null){
      let index = allOptions.findIndex( x => x.id === selectedLetter );

      index > PAGE_SIZE ? 
        setCurrentRangeStart(index - PAGE_SIZE) :
        setCurrentRangeStart(0);

      setCurrentRangeEnd(index + PAGE_SIZE);
      tryScrollToElement(selectedLetter);
    }
  }, [selectedLetter]);

  useEffect(() => {
    var options = [];

    if(!isLoadingExercises){
      exercises
        .filter(
          re => 
            searchText == '' 
            || re.name.toLowerCase().includes(searchText.toLowerCase()) 
            || re.tags.filter(t => t.toLowerCase().includes(searchText.toLowerCase())).length > 0
        )
        .forEach(e => options.push(e));
  
      if(searchText == ''){
        alphabet.forEach(letter => options.push({ name: letter, id: letter, isLetter: true }));
      }
    }
    else{
      for(var i =0;i< 30;i++){
        options.push({ id: i, name: 'Loading...' });
      }
    }
  
    options.sort((a, b) => 
    {
      var x = a.name == '#' ? '0' : a.name;
      var y = b.name == '#' ? '0' : b.name;
      
      return isFinite(x[0]) - isFinite(y[0])
        || x.localeCompare(y, undefined, { numeric: true, sensitivity: 'base' });
    });

    setAllOptions(options);
    setCurrentRangeStart(0);
    setCurrentRangeEnd(PAGE_SIZE);
  }, [exercises, searchText, isLoadingExercises]);

  function isScrolledIntoView(el) {
    var scrollWindow = document.getElementById('plan-creator-exercise-selector-scroller');
    var containerRect = scrollWindow.getBoundingClientRect();
    var rect = el.getBoundingClientRect();

    return (rect.top >= containerRect.top) && (rect.bottom <= containerRect.bottom);
  }

  const tryScrollToElement = (id) => {
    setTimeout(scrollToElement, 100);

    function scrollToElement() {
      var el = document.getElementById(id);
      if(el){
        el.scrollIntoView({ behavior: 'instant' });
      }
    }
  };

  const handleScroll = () => {
    var min = allOptions.length;
    var max =  0;
    for(var i = 0;i < allOptions.length;i++){
      
      var el = document.getElementById(allOptions[i].id);
      if(el != null && isScrolledIntoView(el)){
        if(i > max){
          max = i;
        }
        if(i < min){  
          min = i;
        }
      }
    }

    if(min - currentRangeStart < 20 && currentRangeStart > 0){
      setCurrentRangeStart(currentRangeStart >= PAGE_SIZE ? currentRangeStart - PAGE_SIZE : 0);
    }

    if(currentRangeEnd - max < 20 && currentRangeEnd < allOptions.length){
      setCurrentRangeEnd(currentRangeEnd + PAGE_SIZE);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <Header title={`${isEdit ? 'Edit' : 'New'} Plan (${selectedExercises.length} Exercises Selected)`} 
        endButton={
          <IconButton color='primary' onClick={() => setIsNewExerciseDialogOpen(true)}>
            <AddCircleIcon  style={{ fontSize: 28 }}/>
          </IconButton>}
      />
      <Box p={1}>
        <TextField 
          inputProps={{ 'data-testid':'recovery-new-plan-exercises-search-input' }}
          disabled={isLoadingExercises}
          fullWidth
          label='Search By Name'
          size='small'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}/>
      </Box>
      <Box sx={{ overflow: 'hidden', flexGrow: 1, width: '100%' }}>
        <Box sx={{ 
          height:'100%',
          overflow: 'hidden',
          display: 'flex',
          width: '100%'
        }}>
          <Box 
            id='plan-creator-exercise-selector-scroller'
            onScroll={handleScroll}
            sx={{
              paddingBottom: '50px',
              width: '100%',
              overflow: 'auto', 
              height: '100%',
              '::-webkit-scrollbar': {
                display: 'none'
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none'
            }}>
            {allOptions.slice(currentRangeStart, currentRangeEnd).map((exercise) => {
              const isSelected = selectedExercises.filter(se => se.id == exercise.id).length == 1;
              return (
                <Box key={exercise.id}  id={exercise.id}>
                  {
                    exercise.isLetter ?
                      <Box sx={{ background: hexToRGB(theme.palette.primary.light, 0.5), p: 0.5, fontWeight: 'bold' }}>
                        {exercise.name}
                      </Box> :
                      <Box>
                        <Card variant='outlined' sx={{ my: 0.5, marginLeft: 0.5, marginRight: '20px' }}>
                          <Box sx={{ display : 'flex', alignItems: 'center' }}>
                            <Box>
                              <Checkbox
                                disabled={isLoadingExercises}
                                checked={isSelected}
                                onClick={() => {isSelected ? removeSelectedExercise(exercise) : addSelectedExercise(exercise);}}
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            </Box>
                            <Typography sx={{ flexGrow: 1, cursor: 'pointer' }}
                              data-testid={`new-plan-dialog-exercise-${exercise.id}`}
                              component='div'
                              onClick={() => {isSelected ? removeSelectedExercise(exercise) : addSelectedExercise(exercise);}}>
                              <LoadableText isLoading={isLoadingExercises} text={titleCase(exercise.name)} />
                            </Typography>
                            <ExerciseInfoDialog exercise={exercise} disabled={isLoadingExercises}/>
                          </Box>
                        </Card> 
                      </Box>
                  }
                </Box>
              );
            })}
          </Box>
          {searchText == '' &&
            <AlphabeticScroller isEnabled={!isLoadingExercises && searchText == ''} handleChange={(letter) => setSelectedLetter(letter)} selectedLetter={selectedLetter} />
          }
        
        </Box>
      </Box>
      
      {selectedExercises.length > 0 &&
        <Buttons 
          next={handleNext}
          back={() => clearSelectedExercises()}
          backText='Clear'
          allowSaveDraft={false}/>
      }
      <NewExerciseDialog isOpen={isNewExerciseDialogOpen || exerciseBeingEdited != null}
        close={() => {
          setIsNewExerciseDialogOpen(false);
          setExerciseBeingEdited(null);
        }}/>
    </Box>
  );
}

