import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';

import {
  Dialog,
  Box,
  Divider,
  Typography,
  Button
} from '@mui/material';

import { DialogHeader, LoadableText, DialogTransition } from '@components/atoms';
import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';

import ProfileImage from './profile-image';
import ProfileDetails from './profile-details';
import CommunityStats from './community-stats';
import Tabs from './tabs';
import { CommunityFilterProvider } from '@components/v2/organisms';

export default function Profile(props) {
  const { search } = useLocation();
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const values = queryString.parse(search);

  const loadProfile = async (profileId) => {
    setIsLoading(true);
    var profile = await api.get({ url: `profiles/${profileId}`, user });
    setProfile(profile);
    setIsLoading(false);
  };

  useEffect(() => {
    if(values.profileId && user?.id){
      loadProfile(values.profileId);
    }else if(profile){
      setProfile(null);
    }
  }, [values.profileId, user]);

  const isThisUser = () => {
    return user?.id && profile?.id == user?.id;
  };

  return (
    <Dialog
      open={values.profileId != null}
      fullScreen
      TransitionComponent={DialogTransition}
      sx={{ ...props.sx, marginTop: 'env(safe-area-inset-top)' }} >
      <Box >
        <DialogHeader doHistoryBack={true} />
        <Divider />
        <Box display='flex' flexDirection='column'>
          <Box width='100%'
            display='flex'
            flexDirection='row'
            px={1} >
            <Box fontWeight="fontWeightBold" flexGrow={1}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" fontSize="h5.fontSize">
                  <LoadableText text={profile?.name} isLoading={isLoading} dataTestId='profile-dialog-account-name-text'/>
                </Box>
              </Typography>
            </Box>
            <Box fontWeight="fontWeightBold"
              display='flex'
              alignItems="center">
              {isThisUser() &&
                <Box>
                  <Button component={Link}
                    data-testid='profile-dialog-account-edit-profile-button'
                    to={'/profile/edit'}
                    sx={{
                      fontSize: '0.75rem',
                      lineHeight: 1
                    }}
                    size="small"
                    variant="outlined">
                    Edit Profile
                  </Button>
                </Box>
              }
              {/* {!isThisUser() &&
            <ProfileOptionsMenu 
              userName={props.profile.name} 
              isBlocked={props.profile.isBlocked}
              userId={props.profile.id}
            />
              } */}
            </Box>
          </Box>
          <Box width='100%' display='flex' px={1}>
            <Box width='40%'>
              <ProfileImage profile={profile}/>
            </Box>
            <Box width='60%'>
              <ProfileDetails profile={profile}/>
            </Box>
          </Box>
          <Box width='100%'
            display='flex'
            p={1}>
            <Box width='100%'>
              {profile?.bio &&
            <Typography component="div">
              <Box fontSize="body2.fontSize"  data-testid='profile-dialog-account-bio-text'>
                {profile?.bio}
              </Box>
            </Typography>
              }
              {!profile?.bio && isThisUser() &&
            <Button component={Link}
              to={'/profile/edit'}
              sx={{
                padding: 0.5,
                fontSize: '0.75rem',
                lineHeight: 1
              }}
              size="small"
              variant="outlined">
              Add A Bio
            </Button>
              }
            </Box>
          </Box>
          <Box>
            <Box py={0.25}>
              <Divider flexItem light/>
            </Box>
            <CommunityStats profileId={profile?.id}/>
            <Box py={0.25}>
              <Divider flexItem light/>
            </Box>
          </Box>
          <CommunityFilterProvider isSearchEnabled={false} userId={profile?.id}>
            <Tabs profile={profile}/>
          </CommunityFilterProvider>
        </Box>
      </Box>
    </Dialog>
  );
}