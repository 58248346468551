import React from 'react';

import { 

} from '@mui/material';

import { Page } from '../../components/organisms';
import { Community as CommunityTemplate } from '@components/templates';

export default function Community() {
  return(
    <Page>
      <CommunityTemplate />
    </Page>
  );
}
