
import React from 'react';

export default function Blog(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M12.069 2.25C13.3923 2.24997 14.4836 2.24994 15.3472 2.36579C16.254 2.48741 17.0548 2.75258 17.696 3.39229C18.3372 4.03199 18.6029 4.83093 18.7249 5.73557C18.7803 6.14683 18.8092 6.60981 18.8244 7.125C18.8244 7.125 20.1147 7.12488 20.5796 7.18724C21.0872 7.25532 21.6079 7.4134 22.0344 7.83893C22.4609 8.26446 22.6194 8.78395 22.6876 9.29033C22.7501 9.75413 22.7501 10.3272 22.75 10.9649L22.75 18.825C22.75 20.4404 21.4374 21.75 19.8182 21.75H8.02197C6.69861 21.75 5.60737 21.7501 4.74369 21.6342C3.83695 21.5126 3.03615 21.2474 2.39495 20.6077C1.75375 19.968 1.48797 19.1691 1.36606 18.2644C1.24994 17.4028 1.24997 16.3141 1.25 14.9938V14.9938V9.00616V9.00613C1.24997 7.68589 1.24994 6.59722 1.36606 5.73557C1.48797 4.83093 1.75375 4.03199 2.39495 3.39229C3.03615 2.75258 3.83695 2.48741 4.74369 2.36579C5.60735 2.24994 6.6986 2.24997 8.02191 2.25H8.02194H12.069H12.069ZM19.8191 19.8C20.3588 19.8 20.7963 19.3634 20.7963 18.825V11.025C20.7963 10.308 20.7943 9.8683 20.7514 9.55011C20.7137 9.27043 20.6601 9.22373 20.6537 9.2182L20.6528 9.21723C20.6472 9.21089 20.6004 9.15741 20.3201 9.1198C20.0012 9.07702 19.5605 9.07495 18.8418 9.07495V18.825C18.8418 19.3634 19.2793 19.8 19.8191 19.8ZM6 7.25C5.58579 7.25 5.25 7.58579 5.25 8C5.25 8.41421 5.58579 8.75 6 8.75H14C14.4142 8.75 14.75 8.41421 14.75 8C14.75 7.58579 14.4142 7.25 14 7.25H6ZM6 11.25C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75H14C14.4142 12.75 14.75 12.4142 14.75 12C14.75 11.5858 14.4142 11.25 14 11.25H6ZM6 15.25C5.58579 15.25 5.25 15.5858 5.25 16C5.25 16.4142 5.58579 16.75 6 16.75H10C10.4142 16.75 10.75 16.4142 10.75 16C10.75 15.5858 10.4142 15.25 10 15.25H6Z"
        fill="currentColor"
        stroke={'none'} />
    </svg>
  );
}