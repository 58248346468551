const maxSize = 400;

function resize(canvas) {
  var tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = maxSize;
  tmpCanvas.height = maxSize;

  var ctx = tmpCanvas.getContext('2d');
  ctx.drawImage(canvas ,0,0, canvas.width, canvas.height, 0, 0, maxSize , maxSize);

  return tmpCanvas;
}

export default async function getCroppedImg(image, crop) {
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return new Promise((resolve, reject) => {
    resize(canvas)
      .toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/jpeg',
        1
      );
  });
}
