import React from 'react';

import {
  Box,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Backdrop
} from '@mui/material';

export default function ActionsButton(props) {
  const { dataTestId } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const handleSelection = (action) => {
    setOpen(false);
    action();
  };

  const handleOpen = (event, reason) => {
    if(reason != 'focus'){
      setOpen(true);
    }
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1600 }} open={open} />
      <Box sx={{ 
        position: 'fixed',
        right: 4,
        bottom: 'calc(64px + env(safe-area-inset-bottom))', 
        zIndex: (theme) =>  open ? 1601 : theme.fab
      }}>
        <SpeedDial
          data-testid={dataTestId}
          ariaLabel="SpeedDial basic example"
          icon={<SpeedDialIcon />}
          FabProps={{ color: 'braceGreenDark', 'data-testid': props.dataTestId }}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {props.actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              FabProps={{ 'data-testid': action.dataTestId }}
              onClick={() => handleSelection(action.onClick)}
              tooltipOpen
              sx={{
                '& .MuiSpeedDialAction-staticTooltipLabel': {
                  maxWidth: 'none', 
                  whiteSpace: 'nowrap'
                }
              }}
            />
          ))}
        </SpeedDial>
      </Box>
    </Box>
  );
}