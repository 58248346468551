import React, { useContext, useEffect, useState } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
}from '@mui/material';

import { sortByUpdatedAt } from '@helpers/sorter';
import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';

export default function SelectPlan(props) {
  var { selectedPlan, handleChange, handleLoadingStateChange, selectDataTestId, shouldAddCreateNewPlanOption } = props;
  const [plans, setPlans] = useState([]);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const { user, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);

  const loadPlans = async () => {
    setIsLoadingPlans(true);
    var result = await api.get({ url: 'recovery/plans', getUserToken });
    setPlans(sortByUpdatedAt(result.results));

    if(selectedPlan){
      handleChange(result.results.filter(p => p.id === selectedPlan.id)[0] ?? selectedPlan);
    }
    setIsLoadingPlans(false);
  };

  useEffect(() => {
    handleLoadingStateChange(isLoadingPlans);
  }, [isLoadingPlans]);

  useEffect(() => {
    if(user){
      loadPlans();
    }
  }, []);

  var options = [];

  plans.forEach((p) => {
    options.push(p);
  });

  if(shouldAddCreateNewPlanOption){
    options.push({
      id: 'CREATE_NEW_PLAN_OPTION',
      name: 'Create New Plan +'
    });
  }

  return (
    <FormControl fullWidth >
      <InputLabel>Exercise Plan</InputLabel>
      <Select
        data-testid={selectDataTestId}
        value={plans.includes(selectedPlan) > 0 ? selectedPlan : ''}
        label="Exercise Plan"
        disabled={isLoadingPlans}
        onChange={(e) => {handleChange(e.target.value);}}
      >
        {options.map((plan) => <MenuItem data-testid={`PLAN-${plan.id}`} key={plan.id} value={plan}>{plan.name}</MenuItem>)}
      </Select>
    </FormControl>
  );
}