import React, { useRef, useState } from 'react';

import { 
  Box,
  Typography
} from '@mui/material';
import { CircularProgress } from '@components/v2/molecules';
import { useTheme } from '@emotion/react';
import { LoadableText } from '@components/atoms';
import moment from 'moment';

export default function DayScroller(props){
  const ANIMATION_TIME_IN_SECONDS = 0.5;
  const BOX_WIDTH_VW = 14;
  const { activeDay, moveActiveDay, isLoading, data, thickness } = props;
  const [ animationDirection, setAnimationDirection ] = useState(null);
  const animationMagnitude = useRef(1);
  const theme = useTheme();

  const isToday = (dayDiff) => {
    return activeDay.clone().add(dayDiff, 'd').date() == moment().date();
  };

  const getDateNumber = (dayDiff) => {
    return activeDay.clone().add(dayDiff, 'd').date();
  };

  const getDayOfWeek = (dayDiff) => {
    return activeDay.clone().add(dayDiff, 'd').format('ddd');
  };

  const handleClick = (dayDiff) => {
    animationMagnitude.current = Math.abs(dayDiff);
    if(dayDiff != 0 && !isLoading){
      moveActiveDay(activeDay?.add(dayDiff, 'DAYS'));
      setAnimationDirection(dayDiff > 0 ? 'right' : 'left');
      setTimeout(() => {
        setAnimationDirection(null);
      }, ANIMATION_TIME_IN_SECONDS * 1000);
    }
  };

  return (
    <Box sx={{ position: 'static' }} >
      <Box sx={{ 
        position: 'relative',
        background: theme.palette.braceGreenDark.main, 
        display: 'flex',
        justifyContent: 'space-around',
        width: `${(BOX_WIDTH_VW * 15)}vw`,
        marginLeft: `-${(BOX_WIDTH_VW * 15) / 2 - 50}vw`,
        animation: animationDirection ? 
          animationDirection == 'right' ? 
            `moveRight${animationMagnitude.current} ${ANIMATION_TIME_IN_SECONDS * 0.9}s` : 
            `moveLeft${animationMagnitude.current} ${ANIMATION_TIME_IN_SECONDS * 0.9}s`
          : 'none',
        '@keyframes moveLeft1': {
          from : { left: `-${1 * BOX_WIDTH_VW}vw` },
          to : { left: '0vw' }
        },
        '@keyframes moveLeft2': {
          from : { left: `-${2 * BOX_WIDTH_VW}vw` },
          to : { left: '0vw' }
        },
        '@keyframes moveLeft3': {
          from : { left: `-${3 * BOX_WIDTH_VW}vw` },
          to : { left: '0vw' }
        },
        '@keyframes moveLeft4': {
          from : { left: `-${4 * BOX_WIDTH_VW}vw` },
          to : { left: '0vw' }
        },
        '@keyframes moveRight1': {
          from : { left: `${1 * BOX_WIDTH_VW}vw` },
          to : { left: '0vw' }
        },
        '@keyframes moveRight2': {
          from : { left: `${2 * BOX_WIDTH_VW}vw` },
          to : { left: '0vw' }
        },
        '@keyframes moveRight3': {
          from : { left: `${3 * BOX_WIDTH_VW}vw` },
          to : { left: '0vw' }
        } ,
        '@keyframes moveRight4': {
          from : { left: `${4 * BOX_WIDTH_VW}vw` },
          to : { left: '0vw' }
        } 
      }}>
        { [-7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7].map((value) => {
          var day =  activeDay.clone().add(value, 'd').startOf('day');
          const dayData = data.filter(d => d.day.isSame(day))[0];

          const dayValue = isLoading ?  0 : dayData?.value ?? 0;
          const dayCount = isLoading ? 0 : dayData?.count ?? 0;

          return (
            <Box 
              onClick={() => handleClick(value)}   
              sx={{
                textAlign: 'center',
                width: `${BOX_WIDTH_VW}vw`
              }}
              key={value}>
              <Box sx={{ px: 1, paddingBottom: 1 }}>

                <CircularProgress 
                  thickness={thickness}
                  isLoading={isLoading || animationDirection }
                  isActive={isToday(value)}
                  value={dayValue}
                  count={dayCount}
                  size={36}
                  completeIcon={dayData?.completeIcon}
                  text={getDateNumber(value)}/>
                <Typography component='div'
                  color={isToday(value) ? theme.palette.secondary.main : '#fff'}
                >
                  <LoadableText 
                    isLoading={isLoading || animationDirection } 
                    text={getDayOfWeek(value)} />
                </Typography>
              </Box>
            </Box>
          );
        })}

      </Box>
      <Box sx={{ width:`${BOX_WIDTH_VW}vw`, 
        marginX: 'auto',
        height: 3, 
        backgroundColor: theme.palette.braceGreenLight.main,
        borderRadius: '1px 1px 0 0'
      }}>
      </Box>
    </Box>
    
  );
}