import React from 'react';

import { 
  Box,
  ButtonGroup as MuiButtonGroup,
  Button
} from '@mui/material';

export default function ButtonGroup(props){
  const { handleChange, options, value } = props;

  return (
    <Box>
      <MuiButtonGroup size='small'>
        {options.map(o =>(
          <Button 
            sx={{ px: 1, py: 0.25 }}
            variant={value == o.value ? 'contained' : 'outlined'}
            key={o.name}
            onClick = {() => handleChange(o.value)}>
            {o.name}
          </Button>
        ))}
      </MuiButtonGroup>
    </Box>
  );
}