import React from 'react';

import { 
  Box,
  Skeleton
} from '@mui/material';

import CircularProgress, {
  
} from '@mui/material/CircularProgress';

import { Icon, MoodEmoji }  from '@components/atoms';

function FacebookCircularProgress(props) {
  var  { emojii } = props;
  return (
    <Box sx={{ 
      position: 'relative',
      display: 'inline-flex'
    }}>
      <CircularProgress
        variant="determinate"
        size={props.size ?? 100}
        thickness={props.thickness ?? 4}
        value={100}
        sx={{ 
          color: props.value != null ? '#eee' :  'rgba(100, 100, 100, 0)',
          strokeLinecap: 'round'
        }}
      />
      <CircularProgress
        variant="determinate"
        size={props.size ?? 100}
        thickness={props.thickness ?? 4}
        color={props.value === 100 ? 'primary' : 'secondary'}
        sx={{ 
          position: 'absolute',
          left: 0,
          strokeLinecap: 'round'
        }}
        value={props.value}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.emojiValue != null && props.emojiValue >= 0 &&
          <Box sx={{ position: 'relative' }}>
            <MoodEmoji displayText={false} level={props.emojiValue} size={26}/>
          </Box>
        }
        {props.icon != null &&
          <Box sx={{ position: 'relative' }}>
            <Icon type={props.icon} {...props.iconProps}/>
          </Box>
        }
      </Box>
    </Box>
  );
}

export default function ProgressSpinner(props) {
  return (
    <>
      {props.isLoading && <Skeleton variant="circular" width={props.size ?? 100} height={props.size ?? 100} />}
      {!props.isLoading && <FacebookCircularProgress {...props}/>}
     
    </>
  );
}