import { create } from 'zustand';

const useNotificationsStore = create((set) => ({
  hasLoaded: false,
  isLoading: false,
  unreadCount: 0,
  notifications: [],
  load: async (api, getUserToken) => {
    set({ isLoading: true });
    var result = await api.get({ url: 'notifications',  getUserToken });
    var results = result.results;
    set({ 
      isLoading: false, 
      hasLoaded: true, 
      notifications: results,
      unreadCount: results.filter(n => !n.hasBeenRead).length 
    });
  }
}));

export default useNotificationsStore;