import React from 'react';

import { 
  Stack,
  Box,
  Skeleton,
  Typography
} from '@mui/material';

import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

export default function InjuryDetails(props) {
  return (
    <Stack direction="row" sx={{ width: '100%', color:'text.secondary' }}>
      {props.isLoading ? (
        <Box sx={{ width: '100%' }}>
          <Typography component="div" variant="body1">
            <Skeleton />
          </Typography>
        </Box>
      ) : (
        <>
          {props.injuryType &&
            <Box>
              <Box paddingRight={0.5} display='flex' flexDirection='row'>
                <Box sx={{ lineHeight: 1 }}>
                  <LocalHospitalIcon fontSize="small" />
                </Box>
                <Box sx={{ typography: 'body2' }}>
                  {props.injuryType }
                </Box>
              </Box>
            </Box>
          }
          {props.activityType &&
            <Box>
              <Box paddingRight={0.5} display='flex' flexDirection='row'>
                <Box sx={{ lineHeight: 1 }}>
                  <DirectionsRunIcon fontSize="small"  />
                </Box>
                <Box sx={{ typography: 'body2' }}>
                  {props.activityType }
                </Box>
              </Box>
            </Box>
          }
        </>
      )}
      
    </Stack>
  );
}