import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { BillingContext } from '@context/billing';

export default function RedeemOfferSection(props) {
  const { close, handleDiscounts } = props;
  const { getOfferCodeDetails } = useContext(BillingContext);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [discountCode, setDiscountCode] = useState('');

  useEffect(() => {
    setDiscountCode('');
  }, []);

  const handleRedeemCode = async () => {
    if(discountCode?.length > 3){
      setIsRedeeming(true);
      setErrorMessage('');
      var details = await getOfferCodeDetails(discountCode);
      setIsRedeeming(false);

      if(details.isError){
        setErrorMessage(details.errorMessage);
      }
      else{
        handleDiscounts(details);
        close();
      }
    }else{
      close();
    }    
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <TextField
        error={errorMessage != ''}
        helperText={errorMessage}
        sx={{ marginBottom: 1 }}
        fullWidth
        placeholder='eg. BRACE50'
        value={discountCode}
        onChange={(event) => {
          setErrorMessage('');
          setDiscountCode(event.target.value?.toUpperCase());
        }}
      />
    
      <LoadingButton 
        sx={{ py: 1, px: 4, fontSize: 'h6.fontSize' }}
        size='large'
        variant='contained'
        color='braceGreenDark'
        fullWidth={true}
        loading={isRedeeming}
        onClick={handleRedeemCode}
      >
        {discountCode?.length > 3 ? 'Redeem' : 'Close'}
      </LoadingButton>
    </Box>
  );
}