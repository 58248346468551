import React, { useContext } from 'react';

import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel
}from '@mui/material';

import { NewPlanContext } from '../../context';

import Buttons from '../buttons';

export default function Index(props) {
  const { handleBack } = props;
  const { name, setName, savePlan, shouldAlsoUpdateSessions, setShouldAlsoUpdateSessions, isSavingPlan, isEdit } = useContext(NewPlanContext);

  const handleChange = (event) => {
    setShouldAlsoUpdateSessions(event.target.checked);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 1 }}>
      <TextField 
        inputProps={{ 'data-testid':'new-plan-dialog-plan-name-input' }}
        label='Plan Name'
        placeholder='Plan Name'
        size='small'
        value={name}
        onChange={(e) => setName(e.target.value)}/>
      {isEdit &&
       <FormControlLabel control={  
         <Checkbox
           checked={shouldAlsoUpdateSessions}
           onChange={handleChange}
           inputProps={{ 'aria-label': 'controlled' }}
         />}
       label="Update Plan On All Linked Programs" />
      
      }
      <Buttons 
        next={() => savePlan()} 
        nextText='Save' 
        isNextDisabled={name === ''}
        isNextLoading={isSavingPlan}
        back={handleBack}/>
    </Box>
  );
}

