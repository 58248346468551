export default class Storage {
  save(resourceId, entity){
    localStorage.setItem(resourceId, JSON.stringify(entity));
    return Promise.resolve();
  }
  get(resourceId){
    var itemJson = localStorage.getItem(resourceId);
    return Promise.resolve(JSON.parse(itemJson));
  }
  delete(resourceId){
    localStorage.removeItem(resourceId);
    return Promise.resolve();
  }
}
