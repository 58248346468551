import React from 'react';
import moment from 'moment';

import { red, amber, green } from '@mui/material/colors';

import {
  Chart as ChartJS,
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

import { buildDataset, buildOptions, getMinDate } from './helpers';

export default function Chart(props){
  const { data, activeDatasetName, activeRange } = props;

  const options = buildOptions({ minDate: getMinDate(data), activeRange  });

  const adjustGradient = function(gradient){
    gradient.addColorStop(0, activeDatasetName == 'pain' ? green[500] : red[500] );
    gradient.addColorStop(0.2, activeDatasetName == 'pain' ? green[500] : red[500] );
    gradient.addColorStop(0.5, amber[500]);
    gradient.addColorStop(0.8, activeDatasetName == 'pain' ? red[500] : green[500] );
    gradient.addColorStop(1, activeDatasetName == 'pain' ? red[500] : green[500] );
  };

  const chartData = {
    datasets: [
      buildDataset({
        label: 'Mood',  
        data: data.map(d => {return { y: d.mood, x: moment(d.createdAt) };}),
        activeDatasetName,
        adjustGradient
      }),
      buildDataset({
        label: 'Energy',  
        data: data.map(d => {return { y: d.energy, x: moment(d.createdAt) };}),
        activeDatasetName,
        adjustGradient
      }),
      buildDataset({
        label: 'Pain',  
        data: data.map(d => {return { y: d.pain, x: moment(d.createdAt) };}),
        activeDatasetName,
        adjustGradient
      }),
      buildDataset({
        label: 'Sleep',  
        data: data.filter(d => d.sleep > 0).map(d => {return { y: d.sleep, x: moment(d.createdAt) };}),
        activeDatasetName,
        adjustGradient
      })
    ]
  };

  return (
    <Line 
      options={options}
      data={chartData}/>
  );
}