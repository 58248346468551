import React, { useContext } from 'react';

import {
  Box
}from '@mui/material';

import { NewPlanContext } from '../../context';

import Buttons from '../buttons';
import ExerciseDetails from './exercise-details';

export default function Index(props) {
  const { handleNext, handleBack } = props;
  const { selectedExercises } = useContext(NewPlanContext);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'scroll' }}>
      <Box sx={{ display: 'flex', p: 1, flexGrow: 1 }}>
        <Box sx={{ width: '100%' }}> 
          {selectedExercises.map((e) => (
            <ExerciseDetails key={e.id} exercise={e}/>
          ))}
        </Box>
      </Box>
      <Buttons next={handleNext} back={handleBack}  isNextDisabled={selectedExercises.length === 0} />
    </Box>
  );
}

