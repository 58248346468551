import React, { useState, useEffect, useContext } from 'react';

import { 
  Divider,
  Tabs as MuiTabs,
  Tab
} from '@mui/material';

import { PostFeed } from '@components/organisms';
import RecoveryTab from './recovery-tab';
import { UserContext } from '@context/user';

export default function Tabs(props){
  const { profile } = props;
  const { user } = useContext(UserContext);
  const [activeTabValue, setActiveTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTabValue(newValue);
  };

  useEffect(() => {
    if(profile?.role){
      setActiveTabValue(profile.role === 'PRACTITIONER' ? 1 : 0);
    }
  }, [profile]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  return (
    <>
      {profile?.id &&
        <>
          <MuiTabs sx={{ 
            width: '100%', 
            minHeight: 0,
            '.MuiTab-root': {
              minHeight: 0,
              padding: '8px'
            } }}
          value={activeTabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example">
            <Tab sx={{ width: '50%', maxWidth: '50%', display: profile?.role !== 'PRACTITIONER' ? 'inline' : 'none' }} label="Metrics" {...a11yProps(0)} />
            <Tab sx={{ width: profile?.role !== 'PRACTITIONER' ? '50%' : '100%', maxWidth: '100%' }} label="Feed" {...a11yProps(1)} />
          </MuiTabs>
          <Divider />
          {activeTabValue == 0 &&
            <RecoveryTab profile={profile}/>
          }
          {activeTabValue == 1 &&
            <PostFeed 
              emptyStateTitle={`${profile?.id == user?.id ? 'You Have' : `${profile.name} Has`} No Checked-Ins Yet`} 
              emptyStateMessage=''
            />
          }
        </>
      }
    </>
  );
}