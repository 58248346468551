import React, { useContext, useEffect, useState } from 'react';

import { 
  Box,
  Typography,
  Button
} from '@mui/material';

import { Page } from '../../components/organisms';
import { UserContext, ServicesContext, EventsContext } from '@context';
import { ClientStats } from '@components/organisms';
import { TextField } from '@components/atoms';
import ClientsTable from './clients-table';
import AddUser from '../../components/organisms/add-user-dialog';
import { AccountRoles } from '@helpers/roles';
import { useTheme } from '@emotion/react';

export default function Dashboard() {
  const theme = useTheme();
  const { api } = useContext(ServicesContext);
  const { getUserToken, activeOrganisation } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const [clients, setClients] = useState([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [loadClientsCancellationToken, setLoadClientsCancellationToken] = useState(null);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState(null);

  const loadClients = async () => {
    setIsLoadingClients(true);
    var cancellationToken = api.getCancellationToken();
    if(loadClientsCancellationToken){
      loadClientsCancellationToken.cancel('Operation canceled as there is a new search to do');
    }
    setLoadClientsCancellationToken(cancellationToken);
    api.post({ url: `organisations/${activeOrganisation.id}/accounts/search`, data: { role: 'CLIENT', searchText }, getUserToken, cancellationToken: loadClientsCancellationToken })
      .then((response) =>{
        setClients(response.results);
        setIsLoadingClients(false);
        setLoadClientsCancellationToken(null);
      });
  };

  useEffect(() => {
    if(searchText != null){
      loadClients();
    }
  }, [searchText]);

  useEffect(() => {
    setIsLoadingClients(true);
    trackEvent('Dashboard:Open');
    loadClients();
  }, []);

  return(
    <Page>
      <ClientStats />
      <Box sx={{ p: 1, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography data-testid='dashboard-page-header' sx={{ fontWeight: 'medium', fontSize: 'h5.fontSize' }}>
          Clients
        </Typography>
      </Box>
      {(clients.length > 0 || searchText?.length > 0) &&
        <>
          <Box sx={{ px: 1, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
            <TextField label='Search By Name'
              sx={{ backgroundColor: theme.palette.background.paper }}
              size='small'
              value={searchText ?? ''}
              onChange={(e) => setSearchText(e.target.value)}/>
            <Button 
              data-testid= 'clients-add-client-button'
              onClick={() => setIsAddUserDialogOpen(true)}
              variant='contained'
              size='small'
              color='primary'>Add New Client
            </Button>
          </Box>
        </>
      }
      <ClientsTable clients={clients} isLoading={isLoadingClients} openAddNewClient={() => {setIsAddUserDialogOpen(true);}} />
      <AddUser isOpen={isAddUserDialogOpen}
        title='Add New Client'
        accountRoleOptions={[AccountRoles.CLIENT]}
        defaultAccountRole='CLIENT'
        close={(shouldRefresh) => {
          setIsAddUserDialogOpen(false);
          if(shouldRefresh){
            loadClients();
          }
        }}/>
    </Page>
  );
}
