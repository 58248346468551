import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Stepper,
  Step,
  StepLabel
}from '@mui/material';

import PickExercises from './1';
import ExerciseDetails from './2';
import PlanName from './3';

export default function Steps() {
  const [activeStep, setActiveStep] = useState(0);

  const steps = ['Exercises', 'Details', 'Name'];

  const handleNext = () => {
    setActiveStep((activeStep + 1));

  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Box sx={{ 
        height: '100%',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{ mx: 4, my: 1 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          {activeStep === 0 && <PickExercises handleNext={handleNext}/>}
          {activeStep === 1 && <ExerciseDetails handleNext={handleNext} handleBack={handleBack}/>}
          {activeStep === 2 && <PlanName handleNext={handleNext} handleBack={handleBack}/>}
        </Box>
      </Box>
      
    </>
  );
}

