import React from 'react';

export default function HomeIcon (props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2975 2.38724C11.296 1.60009 12.704 1.60008 13.7025 2.38724L20.7025 7.90553C21.364 8.42699 21.75 9.22285 21.75 10.0652V20.0001C21.75 21.5189 20.5188 22.7501 19 22.7501L16 22.7501C15.0335 22.7501 14.25 21.9666 14.25 21.0001V17.0001C14.25 16.3098 13.6904 15.7501 13 15.7501H11.0024C10.3123 15.7501 9.75272 16.3094 9.75239 16.9995L9.75048 21.001C9.75002 21.9672 8.96665 22.7501 8.00048 22.7501L5 22.7501C3.48122 22.7501 2.25 21.5189 2.25 20.0001V10.0652C2.25 9.22285 2.63602 8.42699 3.2975 7.90553L10.2975 2.38724Z"
        fill={props.fill}
        stroke={props.stroke} />
    </svg>
  );
}