import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Box,
  Divider
} from '@mui/material';

import { EmptyState } from '@components/molecules';
import { UserContext, ServicesContext, AccountOnboardingContext } from '@context';
import { LoadableText, LoadableList } from '@components/atoms';
import { getFromNow } from '@helpers/dates';
import { LikeAndCommentButtons, ProfileImage } from '@components/molecules';

import { grey } from '@mui/material/colors';
import { useTheme } from '@emotion/react';

const getChildComments = (comments, parentCommentId) => {
  var childComments = comments.filter(c => c.parentCommentId === parentCommentId);

  childComments.forEach(cc => {
    cc.childComments = getChildComments(comments, cc.id);
  });

  return childComments;
};

const groupComments = (comments) => {
  var topLevelComments = comments.filter(c => !c.parentCommentId);

  topLevelComments.forEach(comment => comment.childComments = getChildComments(comments, comment.id));

  return topLevelComments;
};

export default function ViewComments(props) {
  var { user } = useContext(UserContext);
  var { accountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  var { api } = useContext(ServicesContext);
  const theme = useTheme();
  var { emptyStateMessage, loadCommentsUrl, commentCount, openCreateComment, parentUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);

  var loadComments = async () => {
    setIsLoading(true);
    var result = await api.get({ url: loadCommentsUrl, user });
    setComments(groupComments(result.results));
    setIsLoading(false);
  };

  var likeComment = async (commentId) => {
    await api.put({ url: `community/comments/${commentId}/like`, user });

    if(!accountOnboarding?.hasContributedToCommunity){
      loadAccountOnboarding();
    }
  };

  useEffect(() => {
    if(commentCount > 0){
      loadComments();
    }
  },[commentCount]);

  const Comment = (props) => {
    var { level, comment, parentUser } = props;

    return (
      <Box sx={{ marginBottom: '1px' }}>
        <Divider light/>
        <Box sx={{ py: 1, px: level > 0 ? 0 : 1 }}>
          <Box sx={{ display: 'flex' }}>
            <ProfileImage 
              user={comment.user}
              isLoading={isLoading}
              linkTo={`?profileId=${comment.user?.id}`}
              name={comment.user?.name}/>
            <Box>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ paddingLeft: 1, typography: 'body2', fontWeight: 'medium', textDecoration: 'none', color: 'text.primary' }} 
                  component={Link} 
                  to={`?profileId=${comment.user?.id}`}>
                  <LoadableText text={comment.user?.name} isLoading={isLoading} minWidth={50} />
                </Box>
                <Box sx={{ paddingLeft: 0.5, typography: 'body2', fontWeight: 'light', color: 'text.secondary' }}>
                  <LoadableText text= {getFromNow(comment.createdAt)} isLoading={isLoading} minWidth={50}/>
                </Box>
              </Box>
              <Box sx={{ paddingLeft: 1, typography: 'body2', color: 'text.secondary' }}>
                {!props.isLoading &&
                  <Box sx={{ 'a': { textDecoration: 'none', color: 'text.primary' } }}>
                    Reply to <Link to={`?profileId=${parentUser?.id}`}>{`@${parentUser?.name}`}</Link>
                  </Box>
                }
                {isLoading &&
                  <LoadableText isLoading={isLoading}/>
                }
              </Box>
            </Box>
          </Box>
          <Box sx={{ paddingLeft: 1, paddingTop: 1, typography: 'body1' }}>
            <LoadableText text={comment.message} isLoading={isLoading}/>
          </Box>
          <Box sx={{ paddingLeft: 1 }}>
            <LikeAndCommentButtons 
              isLoading={isLoading}
              data={{
                likeCount:comment.likeCount, 
                commentCount: comment.commentCount, 
                likedByCurrentUser: comment.likedByCurrentUser
              }}
              likeAction= {() => likeComment(comment.id)}
              commentAction= {() => openCreateComment(comment)}
            />
          </Box>
          <Box sx={{ paddingLeft: 1 }}>
            {!isLoading && comment.childComments.map((childComment) =>
              <Box sx={{ display: 'flex', flexDirection: 'row' }} key={childComment.id}>
                <Divider orientation="vertical" flexItem sx={{ borderWidth: 0.5, marginTop: -1, borderColor: theme.palette.mode == 'light' ? grey[100]: grey[900], mx: 1 }}/>
                <Box sx={{ flexGrow: 1 }}>
                  <Comment level={level + 1}
                    comment={childComment}
                    parentUser={comment.user}
                    likeAction= {() => likeComment(childComment.id)}
                    commentAction= {() => openCreateComment(childComment)} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Divider light/>
      </Box>
    );
  };

  return (
    <Box>
      {!isLoading && comments.map((comment) =>
        <Comment 
          level={0}
          key={comment.id}
          comment={comment}
          parentUser={parentUser}
          likeAction= {() => likeComment(comment.id)}
          commentAction= {() => openCreateComment(comment)} />
      )}
      {props.isLoading && 
      <LoadableList item={<Comment level={0}
        comment={{}}
        parentUser={{}}
        isLoading={true}/>} />
      }
      {!isLoading && comments.length === 0 &&
      <Box sx={{ p: 1 }}>
        <EmptyState 
          iconType='chat'
          fullWidth={false}
          title='Be the first to comment'
          message={emptyStateMessage ?
            emptyStateMessage :
            'No one has commented on this check-in yet. Be the first to share your encouragement!'
          }
        />
      </Box>
      }
    </Box>
  );
}