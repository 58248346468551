import React from 'react';

import { 
  Box,
  Dialog,
  Typography,
  IconButton,
  DialogActions,
  Button,
  DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTransition, Icon } from '@components/atoms';

export default function SuccessDialog(props) {
  const { title, isOpen, close, iconType, message, action, actionText } = props;
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={DialogTransition}
      onClose={close}
    >
      <Box id="alert-dialog-slide-title" 
        sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ width:'30px' }}>

        </Box>
        <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'center' }}>
          {title}
        </Typography>
        <IconButton onClick={close}>
          <CloseIcon/>
        </IconButton>
      </Box>
      <DialogContent>
        <Box width="100%"
          display='flex'
          justifyContent='center'
          direction='column'>
          <Box>
            <Icon type={iconType ?? 'strength'} delay="0" trigger='none'/>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          {message}
        </Box>
      </DialogContent>
      <DialogActions>
        {action &&
          <Button 
            sx={{ width: '100%' }}

            color='primary'
            variant='contained'
            data-testid='success-modal-action-button'
            onClick={ action }>{actionText}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}