import React from 'react';

export default function GroupIcon (props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5861 1.99985C17.3671 1.2188 18.6334 1.2188 19.4145 1.99985L20.0002 2.58558L20.7929 1.79289C21.1834 1.40237 21.8166 1.40237 22.2071 1.79289C22.5976 2.18342 22.5976 2.81658 22.2071 3.20711L21.4144 3.99979L22.0003 4.58564C22.7813 5.36669 22.7813 6.63302 22.0003 7.41406L21.9005 7.51382C21.796 7.35135 21.6726 7.19748 21.5304 7.05531L16.9447 2.46952C16.8025 2.3274 16.6487 2.20408 16.4863 2.09958L16.5861 1.99985ZM15.6768 9.73744L9.73744 15.6768L11.3231 17.2624C12.0065 17.9458 12.0065 19.0539 11.3231 19.7373L10.2373 20.8231C9.55387 21.5065 8.44583 21.5065 7.76241 20.8231L3.17663 16.2373C2.49321 15.5539 2.49321 14.4458 3.17663 13.7624L4.26241 12.6766C4.94583 11.9932 6.05387 11.9932 6.73729 12.6766L8.32322 14.2626L14.2626 8.32322L12.6766 6.73729C11.9932 6.05387 11.9932 4.94583 12.6766 4.26241L13.7624 3.17663C14.4458 2.49321 15.5539 2.49321 16.2373 3.17663L20.8231 7.76241C21.5065 8.44583 21.5065 9.55387 20.8231 10.2373L19.7373 11.3231C19.0539 12.0065 17.9458 12.0065 17.2624 11.3231L15.6768 9.73744ZM3.99981 21.4144L4.58564 22.0002C5.36668 22.7813 6.63301 22.7813 7.41406 22.0002L7.51375 21.9005C7.35131 21.796 7.19746 21.6727 7.05531 21.5305L2.46952 16.9447C2.32737 16.8026 2.20404 16.6488 2.09953 16.4863L1.99985 16.586C1.2188 17.3671 1.2188 18.6334 1.99985 19.4144L2.5856 20.0002L1.79289 20.7929C1.40237 21.1834 1.40237 21.8166 1.79289 22.2071C2.18342 22.5976 2.81658 22.5976 3.20711 22.2071L3.99981 21.4144Z"
        fill={props.fill}
        stroke={props.stroke} />
    </svg>
  );
}