import React, { useContext, useState }from 'react';

import { 
  Typography,
  Box,
  Button,
  Card,
  TextField
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';
import { LoadingButton } from '@mui/lab';

import { ImageUploader } from '@components/molecules';
import { Avatar } from '@components/atoms';
import config from '@config';
import { useTheme } from '@emotion/react';

export default function OrganisationSettings() {
  var theme = useTheme();
  const [isUpdating, setIsUpdating] = useState(false);
  const { user, activeOrganisation, refresh } = useContext(UserContext);
  const [newOrganisationName, setNewOrganisationName] = useState(activeOrganisation?.name);
  const [newOrganisationImage, setNewOrganisationImage] = useState('');
  const [newOrganisationBio, setNewOrganisationBio] = useState(activeOrganisation?.bio ?? '');
  const [isSaving, setIsSaving] = useState(false);

  const { api } = useContext(ServicesContext);

  const hasChanged = () => {
    return (activeOrganisation?.name != newOrganisationName) || 
    (activeOrganisation?.bio != newOrganisationBio) || 
    newOrganisationImage != '';
  };

  const saveOrganisation = async () => {
    const formData = new FormData();
    formData.append('name', newOrganisationName);
    formData.append('bio', newOrganisationBio);

    if(newOrganisationImage){
      formData.append('image', newOrganisationImage);
    }

    setIsSaving(true);
    await api.put({ url: `organisations/${activeOrganisation.id}`, data: formData, user });
    setIsSaving(false);
    setIsUpdating(false);
    refresh();
  };
  
  const cancelUpdate = async () => {
    setIsUpdating(false);
    setNewOrganisationName(activeOrganisation.name);
    setNewOrganisationImage('');
  };

  const saveImage = (image) => {
    setIsUpdating(true);
    setNewOrganisationImage(image);
  };

  const getImageUrl = () => {
    if(newOrganisationImage){
      var urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(newOrganisationImage);
    }else if(activeOrganisation.imageUrl){
      return `${config.contentBaseUrl}images/${activeOrganisation.imageUrl}`;
    }
    return '';
  };

  return(
    <Card sx={{ p: 1 }}>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography data-testid='settings-organisation-header' sx={{ fontSize: 'h5.fontSize', flexGrow: 1 }}>
            Organisation
          </Typography>
          {!isUpdating &&
              <Button data-testid='settings-organisation-button-update'
                size='small'
                variant='contained'
                onClick={() => setIsUpdating(true)}>
                Update Information
              </Button>
          }
          {(isUpdating) &&
              <Button size='small'
                variant='contained'
                onClick={cancelUpdate}>
                Cancel
              </Button>
          }
          {(isUpdating && hasChanged()) &&
              <LoadingButton 
                size='small'
                sx={{ marginLeft: 1 }}
                data-testid='settings-organisation-button-save'
                color='secondary'
                variant='contained'
                loading={isSaving}
                onClick={saveOrganisation}>
                Save
              </LoadingButton>
          }
        </Box>
        <Box sx={{ }}>
          <Box>
            {!isUpdating &&
            <Box>
              <Typography variant='body2' color='text.secondary'>
                Name
              </Typography>
              <Typography variant='body1'>
                {activeOrganisation.name}
              </Typography>
            </Box>
            }
            {isUpdating &&
            <TextField 
              inputProps={{ 'data-testid': 'settings-organisation-text-name' }}
              fullWidth
              variant="standard"
              value={newOrganisationName}
              size='small'
              label='Name'
              onChange={(e) => {
                setNewOrganisationName(e.target.value);
              }}
            />
            }
          </Box>
          <Box  sx={{ marginTop: 1 }}>
            {!isUpdating &&
            <Box>
              <Typography variant='body2' color='text.secondary'>
                Description
              </Typography>
              <Typography variant='body1'>
                {activeOrganisation.bio && activeOrganisation.bio != '' ? activeOrganisation.bio : 'No Description Yet'}
              </Typography>
            </Box>
            }
            {isUpdating &&
            <TextField 
              inputProps={{ 'data-testid': 'settings-organisation-text-bio' }}
              multiline
              fullWidth
              autoFocus
              variant="standard"
              value={newOrganisationBio}
              size='small'
              label='Description'
              onChange={(e) => {
                setNewOrganisationBio(e.target.value);
              }}
            />
            }
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: 1 }}>
            <Typography variant='body2' color='text.secondary'>
              Logo
            </Typography>
            
            <ImageUploader isEnabled={true}
              saveImage={saveImage}
              imageId={activeOrganisation.id}
              aspect={3/2}>
              {!getImageUrl() &&
                  <Box  sx={{ 
                    p: 1,
                    borderRadius: 1,
                    width: 186, 
                    height: 124, 
                    border:'1px dashed', 
                    borderColor: theme.palette.text.disabled,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    cursor: 'pointer'
                  }}>
                    <CloudUploadIcon  sx={{ fontSize: 50 }} color='action'/>
                    <Typography variant='caption' color='text.secondary'>
                      Click to upload
                    </Typography>
                  </Box>
              }
              {getImageUrl() &&
                  <Avatar 
                    clickable={true}
                    size={124}
                    aspect={3/2}
                    src={getImageUrl()}
                    iconType='imageUpload'
                    iconSize={50}/>
              }
            </ImageUploader>
          </Box>
        </Box>
        
      </Box>
    </Card>
  );
}