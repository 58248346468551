import React, { useState } from 'react';

import {
  Box

}from '@mui/material';

import PickExercises from './1';
import ExerciseDetails from './2';
import PlanName from './3';

export default function Steps() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((activeStep + 1));

  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Box sx={{ 
        height: '100%',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {activeStep === 0 && <PickExercises handleNext={handleNext}/>}
        {activeStep === 1 && <ExerciseDetails handleNext={handleNext} handleBack={handleBack}/>}
        {activeStep === 2 && <PlanName handleNext={handleNext} handleBack={handleBack}/>}
      </Box>
      
    </>
  );
}

