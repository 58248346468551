import * as React from 'react';
import { StatusBar, Style } from '@capacitor/status-bar';
import { CssBaseline, darkScrollbar, GlobalStyles, Box } from '@mui/material';

import { grey } from '@material-ui/core/colors';

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import '@fontsource-variable/inter';
import { Capacitor } from '@capacitor/core';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export {
  ColorModeContext
};

const COLOR_MODE_STORAGE_KEY = 'color_mode';

export default function ToggleColorMode(props) {
  var { children, shouldForceLight } = props;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState('light');
  const [statusBarColor, setStatusBarColor] = React.useState('#003F43');

  const calculateColorMode = () => {
    var getColorMode = localStorage.getItem(COLOR_MODE_STORAGE_KEY);
    if(getColorMode){
      return getColorMode;
    }
    return (prefersDarkMode && !shouldForceLight ) ? 'dark' : 'light';
  };

  const toggleColorMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    localStorage.setItem(COLOR_MODE_STORAGE_KEY, newMode);
    setMode(newMode);
  };

  const clearColorMode = () => {
    localStorage.removeItem(COLOR_MODE_STORAGE_KEY);
  };
  
  React.useEffect(() => {
    setMode(calculateColorMode());
  }, [prefersDarkMode]);

  var buttonColor = {
    main: mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : grey[100],
    light: mode === 'light' ? grey[900] : grey[100],
    dark: mode === 'light' ? grey[900] : grey[100],
    contrastText: mode === 'light' ? '#fff' : '#111'
  };

  const toggleBackgroundColor = (isDark = true) => {
    setStatusBarColor(isDark ? '#003F43' : '#FAFAFA');
    if(Capacitor.getPlatform() != 'web'){
      StatusBar.setStyle({ style: isDark ? Style.Dark : Style.Light });
      StatusBar.setBackgroundColor({ color: isDark ? '#003F43' : '#FAFAFA' });
    }
  };

  var theme = React.useMemo(
    () =>
      createTheme({
        typography:{
          fontFamily: ['Inter Variable'],
          button: {
            textTransform: 'capitalize'
          }
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: () => ({
              body: {
                background: '#FAFAFA'
              },
              html: {
                ...darkScrollbar(
                  mode === 'light'
                    ? {
                      track: grey[200],
                      thumb: grey[400],
                      active: grey[400]
                    }
                    : undefined
                ),
                scrollbarWidth: 'thin'
              },
              styleOverrides: `
                @font-face {
                  font-family: 'Inter Variable', sans-serif;
                }
              `
            })
          }
        },
        palette: {
          mode,
          background: {
            default: '#FAFAFA'
          },
          primary: {
            contrastText: '#fff',
            light: '#336569',
            main: '#003F43',
            dark: '#003236'
          },
          secondary: {
            main: '#00E39A',
            light: '#1ae6a4',
            dark: '#00cc8b',
            contrastText: '#003F43'
          },
          warning: {
            main: '#FFBF0F',
            light: '#1ae6a4',
            dark: '#D69D00',
            contrastText: '#000'
          },
          success: {
            main: '#34A853',
            light: '#50C970',
            dark: '#2A8843',
            contrastText: '#000'
          },
          button: buttonColor,
          braceGreenDark: {
            contrastText: '#fff',
            light: '#336569',
            main: '#003F43',
            dark: '#003236'
          },
          braceGreenLight: {
            main: '#00E39A',
            light: '#1ae6a4',
            dark: '#00cc8b',
            contrastText: '#003F43'
          }
        }
      }),
    [mode]
  );

  theme = responsiveFontSizes(theme);

  return (
    <ColorModeContext.Provider value={{ toggleColorMode, clearColorMode, toggleBackgroundColor }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: { background: '#FAFAFA' }
          }}
        />
        <Box sx={{
          backgroundColor: statusBarColor,
          width: '100vw',
          height: '200px',
          position: 'fixed',
          top: 0,
          zIndex: -1
        }}>

        </Box>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}