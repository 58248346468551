import React from 'react';

import { 
  Button as MuiButton
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';

export default function Button(props){
  const { size, isRounded, color, sx, isLoadable, isLoading, ...muiProps } = props;
  let finalSx = sx;

  if(size == 'veryLarge'){
    finalSx = {
      ...finalSx,
      py: 1, 
      px: 4, 
      fontSize: 'h6.fontSize', 
      minWidth: '250px'
    };
  }
  var finalColor = color;
  if(color == 'negative'){
    finalColor = 'secondary';
    finalSx = {
      ...finalSx,
      borderColor: grey[300],
      color: '#000',
      ':hover': {
        borderColor: grey[300],
        color: '#000'
      }
    };
  }

  if(isLoadable){
    return <LoadingButton 
      {...muiProps}
      loading={isLoading}
      color={finalColor}
      sx={{
        ...finalSx,
        fontWeight: 'medium',
        borderRadius: isRounded ? 10 : 1
      }}
    />; 
  }

  return (
    <MuiButton 
      {...muiProps}
      color={finalColor}
      sx={{
        ...finalSx,
        fontWeight: 'medium',
        borderRadius: isRounded ? 10 : 1
      }}
    /> 
  );
}