import React from 'react';

import { 
  Card,
  Box,
  Typography,
  LinearProgress
} from '@mui/material';

import { useTheme } from '@emotion/react';

import { getProgramCompletionPercentage, getDaysLeft } from '@helpers/programs';
import { LoadableText } from '@components/atoms';

export default function ProgramCard(props) {
  const { program, isLoading } = props;
  const theme = useTheme();

  return(
    <Card variant='outlined'
      sx={{
        [theme.breakpoints.down('md')]: {
          width: '50%',
          minWidth: '50%'
        },
        [theme.breakpoints.up('md')]: {
          width: '33%',
          minWidth: '33%'
        } }}>
      <Box sx={{ p: 1 }}>
        <Typography component="div">
          <Box sx={{ fontSize: 'body1.fontSize', fontWeight:'medium', color: 'text.primary' }}>
            <LoadableText isLoading={isLoading} text={program?.name}/>
          </Box>
        </Typography>
      </Box>
      <Box>
        <LinearProgress variant="determinate" value={program ? getProgramCompletionPercentage(program) : 0}/>
      </Box>
      <Box sx={{ flexGrow: 1,  p: 1, display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Typography component="div"
            variant='body1'
            lineHeight={1}
            fontWeight='medium'>
            <LoadableText isLoading={isLoading} text={`${program ? getDaysLeft(program) : 0}`}/>
          </Typography>
          <Typography variant='caption' lineHeight={1} color='text.secondary'>
            {`Day${(program ? getDaysLeft(program) : 0 ) > 1 ? 's' : ''}`} Remaining
          </Typography>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', marginTop: 1 }}>
            <Typography component="div"
              variant='body1'
              lineHeight={1}
              fontWeight='medium'>
              <LoadableText minWidth={15} isLoading={isLoading} text={program?.sessions?.completeCount} />
            </Typography>
            <Typography variant='body1'
              lineHeight={1}
              fontWeight='medium'
              color='text.secondary'>
              /
            </Typography>
            <Typography component="div"
              variant='body1'
              lineHeight={1}
              fontWeight='medium'>
              <LoadableText minWidth={15} isLoading={isLoading} text={program?.sessions?.totalCount}  />
            </Typography>
          </Box>
          <Typography variant='caption' lineHeight={1} color='text.secondary'>
            Sessions Complete
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
