import React, { useState, useContext } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Button
}from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { NewPlanContext } from '../../context';

import ExerciseSet from './exercise-set';

export default function ExerciseDetails(props) {
  const { exercise } = props;
  const { addSet, removeSelectedExercise } = useContext(NewPlanContext);
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography data-testid={`recovery-new-plan-exercises-details-${exercise.id}`}>{exercise.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {exercise.sets.map((set) => (
          <ExerciseSet key={`${exercise.id}-${set.number}`} exercise={exercise} set={set}/>
        ))}
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button onClick={() => addSet(exercise.id)} variant='outlined'>
            Add Set
          </Button>
          <Button onClick={() => removeSelectedExercise(exercise)}>
            Remove Exercise
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

