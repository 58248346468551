import React, { useContext, useEffect, useState } from 'react';

import {
  Dialog,
  Stack,
  Box,
  Divider,
  TextField
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { 
  LocalizationProvider, 
  DatePicker 
} from '@mui/x-date-pickers';

import { UserContext } from '@context/user';
import { DialogTransition } from '@components/atoms';
import { useTheme } from '@emotion/react';
import { ServicesContext } from '@context/services';

export default function CreateGoalDialog(props) {
  const { isOpen, close, userId } = props;
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [description, setDescription]= useState('');
  const [targetDate, setTargetDate]= useState(null);
  const [isSaving, setIsSaving]= useState(false);
  const theme = useTheme();
  
  const save = async () => {
    setIsSaving(true);
    await api.post({ url: 'goals', data: {
      description,
      targetDate: targetDate?.startOf('day').toISOString(),
      userId: userId ?? user.id
    }, user });
    setIsSaving(false);
    close(true);
  };

  useEffect(() => {
    setDescription('');
    setTargetDate(null);
    setIsSaving(false);
  }, [isOpen]);

  return (
    <Dialog 
      sx={{
        '.MuiPaper-root' : {
          [theme.breakpoints.down('md')]: {
            width: '90%'
          },
          [theme.breakpoints.up('md')]: {
            width: '50%'
          }
        }
      }}
      open={isOpen}
      onClose={close}
      TransitionComponent={DialogTransition} >
      <Box sx={{
        p: 1, 
        display: 'flex',
        justifyContent: 'space-between', 
        alignItems: 'center'
      }} >
        <CloseIcon onClick={close} style={{ fontSize: 32 }} color="action" />
        <Box>
          <LoadingButton disabled={description === ''}
            loading={isSaving}
            onClick={save}
            size='small'
            variant='contained'
            color='secondary'>
            Create
          </LoadingButton>
        </Box>
      </Box>
      <Divider />
      <Stack sx={{ p: 1 }} spacing={1}>
        <TextField 
          fullWidth
          multiline
          autoFocus
          label="Description"
          value={description} 
          onChange = {(e) => setDescription(e.target.value)}
          disabled={isSaving}
          variant="outlined" />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker 
            label='Target Date'
            sx={{ width: '100%' }}
            value={targetDate}
            onChange={(newValue) => setTargetDate(newValue)}
            TextField={(params) => <TextField fullWidth size='small' {...params} />}
          />
        </LocalizationProvider>
      </Stack>
    </Dialog>
  );
}