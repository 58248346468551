import React from 'react';

import { 
  TextField as MuiTextField
} from '@mui/material';

export default function TextField(props) {
  return (
    <MuiTextField {...props}/>
  );
}