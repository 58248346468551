import React, { useEffect, useState } from 'react';

import { 
  Box ,
  TextField,
  IconButton
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { LoadableText } from '@components/atoms';

export default function EditableText(props) {
  const { text, isLoading, label, handleSubmit } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleClick = () => {
    setIsEditing(true);
  };

  const submit = async (event) => {
    event.preventDefault();
    setIsSaving(true);
    await handleSubmit(value);
    setIsSaving(false);
    setIsEditing(false);
  };

  useEffect(() => {
    if(isEditing){
      setValue(text);
    }
  }, [isEditing]);

  return (
    <Box>
      {!isEditing &&
        <Box onClick={handleClick}>
          <LoadableText text={text} isLoading={isLoading} />
        </Box>
      }
      {isEditing &&
      <form onSubmit={submit}>
        <TextField 
          size='small'
          disabled={isSaving}
          autoFocus
          value={value}
          id="outlined-basic"
          label={label}
          onChange={(e) => setValue(e.target.value)}
          variant="outlined"
          InputProps={{ endAdornment: <IconButton disabled={isSaving}onClick={submit}><SaveIcon /></IconButton> }}/>
      </form>
      }
    </Box>
  );
}