import React, { useContext } from 'react';

import {
  Box,
  Dialog,
  Slide,
  Divider
} from '@mui/material';

import { NewPostContext } from './new-post-context';
import Header from './header';
import User from './user';
import Content from './content';
import Access from './access';

import Image from './image';
import { UserContext } from '@context/user';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

import { NewPostInputProvider } from './new-post-input-context';

export default function NewPost(props) {
  const { activeOrganisation } = useContext(UserContext);
  const { isOpen, settings, initialImage } = useContext(NewPostContext);
  
  return (
    <Dialog open={isOpen}
      fullScreen
      TransitionComponent={Transition}
      sx={{ ...props.sx, marginTop: 'env(safe-area-inset-top)' }} >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <NewPostInputProvider settings={settings} initialImage={initialImage}>
          <Header />
          <Divider light/>
          <User />
          <Box sx={{ flexGrow: 1 }}>
            <Content />
          </Box>
          {(settings.isAccessControlEnabled) &&
          <>
            <Divider light/>
            <Access isOpen={isOpen}/>
          </>
          }
          {settings.isImageEnabled &&
          <>
            <Divider light/>
            <Image />
          </>
          }
        </NewPostInputProvider>
      </Box>
    </Dialog>
  );
}