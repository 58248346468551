import React from 'react';

import {
  Box,
  Grid,
  IconButton,
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LoadableText from '../loadable/text';
import { useTheme } from '@emotion/react';

export default function DialogHeader(props) {
  var history = useHistory();
  const theme = useTheme();
  var { doHistoryBack, title, subTitle, isLoading, close, iconColor=theme.palette.text.primary } = props;
  const doClose = () => {
    if(close){
      close();
    }else{
      if(doHistoryBack){
        history.goBack();
      }
    }
  };

  return (
    <Box sx={{
      p: 1
    }} >
      <Grid container direction="row">
        <Grid item xs={1}>
          <IconButton onClick={doClose} size='small'>
            {close ? <CloseIcon sx={{ color: iconColor }}/> : <ArrowBackIcon sx={{ color: iconColor }}/>}
          </IconButton>
        </Grid>
        <Grid item xs={10} sx={{ display:'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box>
            {title && 
          
          <Typography variant='h5'>
            <LoadableText text={title} isLoading={isLoading}/>
          </Typography>
            }

          </Box>
        </Grid>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={12}>
          {subTitle && 
          
          <Typography textAlign='center' variant='body2' component='div'>
            <LoadableText text={subTitle} isLoading={isLoading}/>
          </Typography>
          }
        </Grid>
      
      </Grid>
    </Box>
     
  );
}