import UserAvatar from './user-avatar';
import CircularProgress from './circular-progress';
import Tabs, { Tab, a11yProps } from './tabs';
import UserDetails from './user-details';
export { 
  UserAvatar,
  CircularProgress,
  UserDetails,
  Tabs,
  Tab,
  a11yProps
};