import React from 'react';

import { 
  Box 
} from '@mui/material';

import { teal } from '@mui/material/colors';

export default function PostItNote(props){
  var { children } = props;
  return (
    <Box>
      <Box
        sx={{ 
          position: 'relative',
          background: teal[100], 
          color: 'rgba(0, 0, 0, 0.87)',
          margin: '1em auto',
          minHeight: '5em',
          '.ql-container':{
            border: 'none'
          },
          ':after':{
            content: '""',
            position: 'absolute',
            bottom: '-1em',
            left: 0,
            right: '1em',
            borderWidth: '1em',
            borderStyle: 'solid',
            borderColor: teal[100]
          },
          ':before': {
            content: '""',
            position: 'absolute',
            bottom: '-1em',
            right: 0,
            borderWidth: '1em 1em 0 0',
            borderStyle: 'solid',
            borderColor: `${teal[200]} transparent`
          } }}>
        {children}
      </Box>
    </Box>
  );
}