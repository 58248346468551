import React from 'react';

import { 
  IconButton as MuiIconButton
} from '@mui/material';

export default function IconButton(props){
  const { variant, backgroundColor = 'rgba(255, 255, 255, 0.12)' } = props;

  return (
    <MuiIconButton 
      {...props}
      sx={{
        ...props.sx,
        border: variant != 'rounded-outlined' ? 'none' : `1px solid ${backgroundColor}`,
        backgroundColor: variant != 'rounded-outlined' ? backgroundColor : 'none'
      }}
    /> 
  );
}