import React, { useState } from 'react';

import {
  Box,
  Stack,
  Button,
  Grid,
  MobileStepper
} from '@mui/material';
import { useTheme } from '@emotion/react';

import Image from 'mui-image';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const steps =  [
  {
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding-carousel-image-step-1.png'
  },
  {
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding-carousel-image-step-2.png'
  },
  {
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding-carousel-image-step-3.png'
  }
];

export default function Stepper() {
  var theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const maxSteps = steps.length;

  return (
    <Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          autoplay={false}
          interval={10000}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          style={{ width: '100vw', flexGrow: 1, marginTop: 4 }}
          containerStyle={{  width: '100%', height: '100%', p: 2 } }
        >
          {steps.map((step, index) => (
            <Box key={index} sx={{  width: '100%', height: '100%' }}>
              <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                color: 'braceGreenDark.main',
                fontWeight: 'medium',
                height: '100%',
                alignItems: 'center' }}>
                <Box>
                  <Image 
                    duration={500} 
                    width='50vw'
                    src={step.image}/>
                </Box>
              </Stack>
                
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
      <Grid container sx={{ p: 1, px: 4 }}>
        <Grid item xs={5} size='small'>
        </Grid>
        <Grid item xs={2}>
          <MobileStepper
            sx={{ backgroundColor: '#fff', justifyContent: 'center', 
              '.MuiMobileStepper-dotActive' : {
                backgroundColor: theme.palette.braceGreenLight.main
              } }}
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
          />
        </Grid>
      </Grid>
    </Box>
  );
}