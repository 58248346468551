
import React from 'react';

export default function SentimentContent(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={'none'}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM7.54964 14.4003C7.88084 14.1515 8.35099 14.2184 8.59974 14.5496C9.37631 15.5835 10.6103 16.25 12 16.25C13.3898 16.25 14.6238 15.5835 15.4003 14.5496C15.6491 14.2184 16.1192 14.1515 16.4505 14.4003C16.7817 14.649 16.8485 15.1192 16.5997 15.4504C15.5521 16.8453 13.8816 17.75 12 17.75C10.1185 17.75 8.44802 16.8453 7.40035 15.4504C7.1516 15.1192 7.21843 14.649 7.54964 14.4003ZM8 7.75C7.30964 7.75 6.75 8.30964 6.75 9C6.75 9.69036 7.30964 10.25 8 10.25H8.00897C8.69933 10.25 9.25897 9.69036 9.25897 9C9.25897 8.30964 8.69933 7.75 8.00897 7.75H8ZM15.991 7.75C15.3007 7.75 14.741 8.30964 14.741 9C14.741 9.69036 15.3007 10.25 15.991 10.25H16C16.6904 10.25 17.25 9.69036 17.25 9C17.25 8.30964 16.6904 7.75 16 7.75H15.991Z"
        fill={props.fill}
        stroke={props.stroke} />
    </svg>
  );
}