import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';

import { 
  Box,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import config from '@config';
import { toShortDateString } from '@helpers/dates';
import { ServicesContext } from '@context/services';
import { UserContext } from '@context/user';
import { LoadableText, LoadableList, Avatar, TextField, OptionalTooltip } from '@components/atoms';
import { ConfirmationModal, EmptyState, EditableText } from '@components/molecules';

import { Page } from '../../components/organisms';

import AddClientsToGroup from './add-clients';
import { ClientStats } from '@components/organisms';
import { useTheme } from '@emotion/react';

export default function Group() {
  let { id } = useParams();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { api } = useContext(ServicesContext);
  const { getUserToken, activeOrganisation } = useContext(UserContext);
  const [group, setGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddUsersDialogOpen, setIsAddUsersDialogOpen] = useState(false);
  const [accountBeingRemoved, setAccountBeingRemoved] = useState(null);
  const [isRemovingAccount, setIsRemovingAccount] = useState(false);
  const [searchText, setSearchText] = useState('');

  const loadGroup = () => {
    setIsLoading(true);
    api.get({ url: `organisations/${activeOrganisation.id}/teams/${id}`, getUserToken })
      .then((response) => {
        setGroup(response);
        setIsLoading(false);
      });
  };

  const removeGroupMember = () => {
    setIsRemovingAccount(true);
    api.put({ url: `organisations/${activeOrganisation.id}/teams/${id}`, getUserToken, data:{
      accountIdsToRemove: [accountBeingRemoved.account.id]
    } })
      .then(() => {
        enqueueSnackbar(`Successfully removed ${accountBeingRemoved.account.name} from this group.`, { 
          variant: 'success'
        });
        setAccountBeingRemoved(null);
        setIsRemovingAccount(false);
        loadGroup();

      });
  };

  const updateGroupName = (name) => {
    api.put({ url: `organisations/${activeOrganisation.id}/teams/${id}`, getUserToken, data:{
      name
    } })
      .then(() => {
        enqueueSnackbar(`Successfully update group name to ${name}.`, { 
          variant: 'success'
        });
        loadGroup();
      });
  };

  useEffect(() => {
    if(id){
      loadGroup();
    }
  }, [id]);

  const GroupTableRow = (props) => {
    var { groupAccount } = props;
    return (
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell >
          <Avatar 
            isLoading={isLoading}
            src={`${config.contentBaseUrl}images/${groupAccount?.account?.imageLocation}`}
            name={groupAccount?.account?.name} />
        </TableCell>
        <TableCell >
          <LoadableText isLoading={isLoading} text={groupAccount?.account?.name}/>
        </TableCell>
        <TableCell >
          <LoadableText isLoading={isLoading} text={toShortDateString(groupAccount?.createdAt)}/>
        </TableCell>
        <TableCell align="right"  >
          <OptionalTooltip isEnabled={group?.accounts?.length == 1} title='Cannot delete the last client in a group'>
            <span>
              <IconButton onClick={() => {
                setAccountBeingRemoved(groupAccount);
              }}
              disabled={isLoading || isRemovingAccount || group?.accounts?.length == 1}
              >
                <DeleteIcon fontSize="small"/>
              </IconButton>
            </span>
          </OptionalTooltip>
        </TableCell>
      </TableRow>
    );
  };

  return(
    <Page enableBackButton>
      <Box sx={{ width: '100%', p: 1 }}>
        <ClientStats groupId={id}/>
        <Typography data-testid={`group-page-header-${group?.id}`} component='div' sx={{ fontWeight: 'medium', py: 1, fontSize: 'h5.fontSize' }}>
          <EditableText isLoading={isLoading}
            text={group?.name}
            label='Group Name'
            handleSubmit={updateGroupName} />
        </Typography>
        <Box sx={{ paddingBottom: 1, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
          <TextField label='Search By Name'
            sx={{ backgroundColor: theme.palette.background.paper }}
            size='small'
            value={searchText ?? ''}
            onChange={(e) => setSearchText(e.target.value)}/>
          <Button 
            data-testid= 'clients-add-client-button'
            onClick= {() => {setIsAddUsersDialogOpen(true);}}
            variant='contained'
            size='small'
            color='primary'>Add New Client
          </Button>
        </Box>
        {(isLoading || group?.accounts.length > 0) && 
          <Paper sx={{ width: '100%' }}>
            <TableContainer>
              <Table aria-label="simple table" size="small" >
                <TableHead>
                  <TableRow >
                    <TableCell sx={{ color: 'text.secondary', py: 0.5 }} ></TableCell>
                    <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Name</TableCell>
                    <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Joined</TableCell>
                    <TableCell sx={{ color: 'text.secondary', py: 0.5 }} ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <LoadableList 
                    isLoading={isLoading}
                    rows={group?.accounts.filter(a => searchText == '' || a.account?.name?.toLowerCase().includes(searchText.toLowerCase()))}
                    getRow={(groupAccount) => {return (<GroupTableRow groupAccount={groupAccount} key={groupAccount.id}/>);} }
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        }
        {(!isLoading && group?.accounts.length === 0) && 
          <EmptyState 
            title='Add Group Members'
            message='You can add group members to this group'
            buttonAction={() => {setIsAddUsersDialogOpen(true);}}
            buttonText='Add A Member'
            iconType='people'
          /> 
        }
      </Box>
      <AddClientsToGroup 
        group={group}
        isOpen={isAddUsersDialogOpen}
        close={(shouldRefresh) => {
          setIsAddUsersDialogOpen(false);
          if(shouldRefresh){
            loadGroup();
          }
        }}/>
      {accountBeingRemoved &&
        <ConfirmationModal 
          title={`Remove ${accountBeingRemoved.account.name} from ${group.name}?`}
          message={`Are you sure you want to remove ${accountBeingRemoved.account.name} from ${group.name}?`}
          isOpen={accountBeingRemoved != null}
          isDeleteAction={true}
          handleClose={() => {
            setAccountBeingRemoved(null);
          }}
          handleConfirmation={removeGroupMember} />
      }
    </Page>
  );
}
