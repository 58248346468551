export default function LoadableList(props) {
  var { rows, isLoading, getRow, numberOfRows = 10 } = props;

  var dummyData= [];

  for(var i = 0; i < numberOfRows; i++) {
    dummyData.push({ id: i });
  }

  var data = isLoading ? dummyData: rows;
  
  return data.map(getRow);
}