import React, { useState } from 'react';

import {
  Box,
  Divider,
  Skeleton
} from '@mui/material';
import Likes from './likes';

export default function LikeAndCommentCounts(props) {
  const [isLikesOpen, setIsLikesOpen] = useState(false);
  const { isUpVote } = props;
  return (
    <Box>
      <Divider light />
      {!props.isLoading &&
        <Box>
          <Box sx={{ display: 'flex', p: 1 }}>
            <Box sx={{ display: 'flex', textDecoration: 'none', color: 'text.primary', cursor: 'pointer' }} onClick={() => setIsLikesOpen(true)}>
              <Box>
                {props.likeCount}
              </Box>
              <Box sx={{ color: 'text.secondary', fontWeight: 'light', paddingLeft: 0.5 }}>
                {isUpVote ? 'Votes' : 'Likes'}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', paddingLeft: 1 }}>
              <Box>
                {props.commentCount}
              </Box>
              <Box sx={{ color: 'text.secondary', fontWeight: 'light', paddingLeft: 0.5 }}>
                Comments
              </Box>
            </Box>
          </Box>
        </Box>
      }
      {props.isLoading &&
        <Skeleton height={40} width={100}/>
      }
      <Divider light />
      <Likes isOpen={isLikesOpen} close={() => setIsLikesOpen(false)} parentId={props.parentId}/>
    </Box>
  );
}