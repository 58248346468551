
import React from 'react';

export default function CommentPlus(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={'none'}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M18.375 1.125C18.9273 1.125 19.375 1.57272 19.375 2.125V4.625H21.875C22.4273 4.625 22.875 5.07272 22.875 5.625C22.875 6.17728 22.4273 6.625 21.875 6.625H19.375V9.125C19.375 9.67728 18.9273 10.125 18.375 10.125C17.8227 10.125 17.375 9.67728 17.375 9.125V6.625H14.875C14.3227 6.625 13.875 6.17728 13.875 5.625C13.875 5.07272 14.3227 4.625 14.875 4.625H17.375V2.125C17.375 1.57272 17.8227 1.125 18.375 1.125Z"
        fill={props.fill}
        stroke={props.stroke}/>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8744 2.52662C15.8743 2.27822 15.7223 2.05352 15.4873 1.97301C14.3567 1.58564 13.1402 1.375 11.875 1.375C5.97023 1.375 1.125 5.96338 1.125 11.6917C1.125 14.4394 2.24472 16.9315 4.05951 18.7744C4.32727 19.0463 4.41437 19.325 4.37588 19.5302C4.23363 20.2763 3.91197 20.9695 3.44417 21.5449C3.27724 21.7502 3.23086 22.0283 3.32213 22.2767C3.4134 22.525 3.62881 22.7069 3.88896 22.7553C5.50004 23.055 7.17042 22.785 8.60214 22.0236C8.83568 21.8994 8.98822 21.8185 9.10275 21.7641C9.13431 21.7469 9.21834 21.7157 9.30196 21.7286C9.41409 21.7439 9.56479 21.772 9.80198 21.8166C10.4853 21.9449 11.1791 22.0093 11.875 22.0084C17.7798 22.0084 22.625 17.42 22.625 11.6917C22.625 10.595 22.4474 9.54005 22.1187 8.5511C22.033 8.29316 21.7833 8.13032 21.5115 8.1295C21.16 8.12843 20.875 8.40995 20.875 8.76144V9.125C20.875 10.5057 19.7557 11.625 18.375 11.625C16.9943 11.625 15.875 10.5057 15.875 9.125C15.875 8.6536 15.875 8.41789 15.7286 8.27145C15.5821 8.125 15.3464 8.125 14.875 8.125C13.4943 8.125 12.375 7.00571 12.375 5.625C12.375 4.24429 13.4943 3.125 14.875 3.125H15.2766C15.6071 3.125 15.8746 2.85709 15.8744 2.52662ZM7.875 11.125C7.32272 11.125 6.875 11.5727 6.875 12.125C6.875 12.6773 7.32272 13.125 7.875 13.125H7.88397C8.43626 13.125 8.88397 12.6773 8.88397 12.125C8.88397 11.5727 8.43626 11.125 7.88397 11.125H7.875ZM11.8705 11.125C11.3182 11.125 10.8705 11.5727 10.8705 12.125C10.8705 12.6773 11.3182 13.125 11.8705 13.125H11.8795C12.4318 13.125 12.8795 12.6773 12.8795 12.125C12.8795 11.5727 12.4318 11.125 11.8795 11.125H11.8705Z"
        fill={props.fill}
        stroke={props.stroke}/>
    </svg>
  );
}