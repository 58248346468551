import moment from 'moment';

export function getProgramCompletionPercentage(program){
  if(!program || !program.sessions || program.sessions.totalCount === 0){
    return 0;
  }
  return Math.round(( program.sessions.completeCount * 100 ) / program.sessions.totalCount);
}

export function getDaysLeft(program){
  var endDate = moment(program.endDate);
  var daysRemaining = endDate.diff(moment().utc(), 'days');
  return daysRemaining > 0 ? daysRemaining : 0;
}