import React from 'react';

export default function StretchingPerson (props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path d="M10.3602 2.20685C10.7981 2.5433 10.8804 3.17109 10.544 3.60907C9.62395 4.80672 9.87547 6.43298 11.2853 7.3846L16.0421 10.5953C16.5089 10.9103 16.9365 11.1988 17.2443 11.4694C17.5623 11.7488 17.9454 12.1703 17.9942 12.7907C18.0061 12.9409 17.9997 13.0916 17.9754 13.24C17.8755 13.8493 17.4641 14.2394 17.1266 14.4956C16.7983 14.7447 16.3491 15.0046 15.856 15.2898L15.856 15.2898L14.861 15.8654C14.3829 16.142 13.7712 15.9787 13.4946 15.5006C13.218 15.0226 13.3814 14.4109 13.8594 14.1343L14.8031 13.5883C15.3314 13.2827 15.6608 13.0901 15.8777 12.932C15.6761 12.7633 15.3673 12.5528 14.8734 12.2194L13.6152 11.3701C13.1482 12.0618 12.7925 12.718 12.5063 13.3222C12.0239 14.3405 11.9659 14.5315 11.9816 14.737C11.9864 14.8005 12.0097 14.9146 12.1516 15.1716C12.3006 15.4415 12.5264 15.777 12.8784 16.2974C13.0608 16.567 13.2415 16.8368 13.4207 17.1044L13.4208 17.1045C14.2271 18.3084 15.0028 19.4667 15.7594 20.3494C16.1188 20.7688 16.0703 21.4001 15.6509 21.7595C15.2316 22.1189 14.6003 22.0703 14.2409 21.651C13.3989 20.6686 12.5386 19.3831 11.7314 18.1767C11.41 17.7017 10.5987 16.4969 10.4007 16.1381C10.3091 15.9722 10.2256 15.802 10.1575 15.6242L8.72647 15.2343C8.23887 17.3999 8 19.5647 8 21.0002C8 21.5525 7.55228 22.0002 7 22.0002C6.44772 22.0002 6 21.5525 6 21.0002C6 19.1461 6.34781 16.3348 7.04186 13.7025C7.55619 11.7518 8.29618 9.75703 9.32159 8.33424C7.75733 6.70996 7.51002 4.2755 8.95795 2.39067C9.29441 1.9527 9.9222 1.8704 10.3602 2.20685Z" 
        fill={props.fill}
        stroke={'none'}/>
      <path d="M12.25 5.49988C12.25 4.25724 13.2574 3.24988 14.5 3.24988C15.7426 3.24988 16.75 4.25724 16.75 5.49988C16.75 6.74252 15.7426 7.74988 14.5 7.74988C13.2574 7.74988 12.25 6.74252 12.25 5.49988Z" 
        fill={props.fill}
        stroke={'none'} />
    </svg>
  );
}
