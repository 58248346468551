import React from 'react';

import { 
  Stack,
  Checkbox,
  Avatar
} from '@mui/material';

const DayCheckBoxIcon = (props) => {
  return (
    <Avatar
      sx={{ 
        bgcolor: props.checked ? 'secondary.light' : '#fafafa',
        color: props.checked ? '#fff' : 'secondary.light',
        border: props.checked ? 'none' : '1px solid',
        borderColor: 'secondary.light',
        fontSize: 12,
        margin: 0,
        height: 35,
        width: 35
      }}>
      {props.day}
    </Avatar>
  );
};

export default function DaysOfTheWeek(props) {
  const { values, onChange, isDisabled } = props;

  const toggleDay = (day) => {
    if(values.includes(day)){
      onChange(values.filter(v => v != day));
    }else{
      onChange([...values, day]);
    }
  };

  const DayCheckBox = (props) => {
    const { value, dayText } = props;
    return  (
      <Checkbox 
        name={value.toLowerCase()}
        checked={values.includes(value)}
        onChange={() => toggleDay(value)}
        disabled={isDisabled}
        sx={{ padding: 0 }}
        inputProps={{ 'aria-label': 'controlled' }}
        icon={<DayCheckBoxIcon checked={false} day={dayText}/>}
        checkedIcon={<DayCheckBoxIcon checked day={dayText}/>}
      />
    );
  };

  return (
    <Stack direction='row' spacing={1} justifyContent="space-around">
      <DayCheckBox 
        value='MONDAY'
        dayText='Mon'
      />
      <DayCheckBox 
        value='TUESDAY'
        dayText='Tue'
      />
      <DayCheckBox 
        value='WEDNESDAY'
        dayText='Wed'
      />
      <DayCheckBox 
        value='THURSDAY'
        dayText='Thu'
      />
      <DayCheckBox 
        value='FRIDAY'
        dayText='Fri'
      />
      <DayCheckBox 
        value='SATURDAY'
        dayText='Sat'
      />
      <DayCheckBox 
        value='SUNDAY'
        dayText='Sun'
      />
    </Stack>
  );
}