import React from 'react';
import { Link } from 'react-router-dom';

import {
  Box
} from '@mui/material';

import { LoadableText, InjuryDetails } from '@components/atoms';

import { getFromNow } from '@helpers/dates';

import { ProfileImage }from '@components/molecules';

export default function UserDetails(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ProfileImage
        user={props.user}
        linkTo={`?profileId=${props.user?.id}`}
        isLoading={props.isLoading}
        name={props?.user?.name} 
      />
      <Box>
        <Box sx={{ display: 'flex' }} >
          <Box sx={{ paddingLeft: 1, typography: 'body1', fontWeight: 'medium', color: 'text.primary', textDecoration: 'none' }} 
            component={Link}
            to={`?profileId=${props.user?.id}`}>
            <LoadableText text={props.user?.name} isLoading={props.isLoading} minWidth={100}/>
          </Box>
          {props.date &&
            <Box sx={{ paddingLeft: 0.5, typography: 'body1', fontWeight: 'light', color: 'text.secondary' }}>
              {getFromNow(props.date)}
            </Box>
          }
        </Box>
        {props.user?.role !== 'PRACTITIONER' &&
          <Box sx={{ paddingLeft: 1, typography: 'body1', fontWeight: 'medium' }}>
            <InjuryDetails isLoading={props.isLoading}
              injuryType={props.user?.injuryType}
              activityType={props.user?.activityType}  />
          </Box>
        }

      </Box>
      
    </Box>
  );
}