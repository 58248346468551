import React from 'react';

import { 
  Box
} from '@mui/material';

import config from '@config';

import { Image as MuiImage } from 'mui-image';

export default function Image(props) {
  const { src, sx } = props;
  return (
    <Box sx={{ borderRadius: 1 }}>
      <MuiImage
        src={`${config.contentBaseUrl}images/${src}`}
        sx={{ borderRadius: 1, ...sx }}
        duration={500}
        shift="top" />
    </Box>
  );
}