import React from 'react';

import {
  Box
}from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { grey } from '@mui/material/colors';

import { Tabs, Tab, a11yProps } from './../../v2/molecules';

import Posts from './posts';
import Topics from './topics';

export default function CommunityTabs(props) {
  const theme = useTheme();
  const { activeTab, setActiveTab } = props;

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        style={{ flexGrow: 1, overflow: 'hidden' }}
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ height: '100%', background: theme.palette.mode === 'dark' ? grey[900] : '#fafafa'  }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
      >
        <Tab dataTestId='community-tabs-check-ins'
          label="Check-Ins"
          width='50%'
          {...a11yProps(0)} />
        <Tab dataTestId='community-tabs-forums'
          label="Discussions"
          width='50%'
          {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={activeTab} index={0} dir={theme.direction}>
        <Posts/>
      </TabPanel>
      <TabPanel value={activeTab} index={1} dir={theme.direction}>
        <Topics />
      </TabPanel>

    </Box>
  );
  
}