import React, { useContext } from 'react';

import { 
  Box
} from '@mui/material';

import { grey } from '@mui/material/colors';

import { MenuDrawer } from '../molecules';

export default function Page(props) {
  const { children, title, enableBackButton } = props;

  return(
    <Box sx={{ background: grey[100] }}>
      <MenuDrawer title={title} enableBackButton={enableBackButton}>
        {children}
      </MenuDrawer>
    </Box>
  );
}
