import React, { useContext, useState } from 'react';

import { 
  Box,
  Typography
} from '@mui/material';
import { UserContext } from '@context';
import { UserAvatar } from '@components/v2/molecules';
import MenuDrawer from './menu-drawer';

export default function TopNavBarProfile(){
  const { user } = useContext(UserContext);
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false);
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <UserAvatar user={user} onClick={() => setIsDrawerOpen(true)} dataTestId='menu-drawer-button-open'/>
        <Box sx={{ paddingLeft: 1 }}>
          <Typography fontSize='body2.fontSize' lineHeight={1.25} sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          Hello {user?.firstName}
          </Typography>
          <Typography fontSize='1.125rem' lineHeight={1.25} fontWeight='medium'>
          Welcome Back
          </Typography>
        </Box>
      </Box>
      <MenuDrawer isOpen={isDrawerOpen} close={() => setIsDrawerOpen(false)} />
    </>
  );
}