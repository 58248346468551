import React, { createContext, useContext, useEffect, useState  } from 'react';

import { UserContext } from './user';
import { ServicesContext } from './services';
import { FeaturesContext } from './features';
import { areDatesTheSameIgnoringMinutes } from '@helpers/dates';
import { getDiscountDetails } from '@helpers/discounts';
import { AppContext } from './app';

export const BillingContext = createContext();

async function sleep(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

export function BillingProvider(props){
  const { user, getUserToken } = useContext(UserContext);
  const { api, logger, payments } = useContext(ServicesContext);
  const { isFeatureEnabled } = useContext(FeaturesContext);
  const { accessCode } = useContext(AppContext);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ subscription, setSubscription ] = useState(null);
  const [ isLoadingPaymentsSubscription, setIsLoadingPaymentsSubscription ] = useState(true);
  const [ paymentsSubscription, setPaymentsSubscription ] = useState(null);
  const [ offerings, setOfferings ] = useState([]);
  const [ offers, setOffers ] = useState([]);
  const [ isPurchasingPackage, setIsPurchasingPackage ] = useState(false);
  const [ isConsumingAccessCode, setIsConsumingAccessCode ] = useState(false);
  const [ isPollingForSubscription, setIsPollingForSubscription ] = useState(false);
  const [ haveReSyncedSubscriptions, setHaveReSyncedSubscriptions ] = useState(false);

  const syncSubscriptions = (callback) => {
    logger.info('Syncing Subscriptions', { userId: user.id });
    setIsLoading(true);
    api.put({ url: 'billing/subscriptions/sync', getUserToken })
      .then((result) => {
        setSubscription(result);
        setIsLoading(false);
        if(callback){
          callback(subscription);
        }
        
      });
  };

  const initiatePaymentPlugin = async () => {
    await payments.init(user);
    await payments.syncBilling();
    var subscription = await payments.getCustomerInfo((subscription));
    setPaymentsSubscription(subscription);
    setIsLoadingPaymentsSubscription(false);
    payments.getOfferings((o) => {
      if(o?.offerings){
        setOfferings(o?.offerings);
      }
      if(o?.offers){
        setOffers([...offers, ...o.offers]);
      }
    });  
  };

  const loadSubscription = async () => {
    setIsLoading(true);
    var subscription = await api.get({ url: 'billing/subscriptions', params:{ forceSync: true }, getUserToken });
    setSubscription(subscription);
    setIsLoading(false);
  };

  useEffect(() => {
    if(user?.id && accessCode && subscription?.id && subscription.status != 'FREE'){
      window.location = '#/paywall';
    }
  }, [accessCode, subscription]);

  useEffect(() => {
    if(user?.id && payments.isAvailable()){
      logger.info('Loading Billing Context', { userId: user.id });
      loadSubscription();
      initiatePaymentPlugin();
    }
  }, [user]);

  useEffect(() => {
    if(user && 
      (subscription || !isLoading) &&
      (paymentsSubscription || !isLoadingPaymentsSubscription) &&
      !haveReSyncedSubscriptions
    ){
      var shouldSyncSubscriptions = false;

      if(subscription && !subscription.isTrial && !paymentsSubscription){
        shouldSyncSubscriptions = true;
      }
      else if(paymentsSubscription){
        shouldSyncSubscriptions = !subscription ||
          subscription.store.toLowerCase() !== paymentsSubscription.store.toLowerCase() ||
          !areDatesTheSameIgnoringMinutes(subscription.expiresAt, paymentsSubscription.expiresAt) ||
          !areDatesTheSameIgnoringMinutes(subscription.billingIssueDetectedAt, paymentsSubscription.billingIssueDetectedAt ||
          !areDatesTheSameIgnoringMinutes(subscription.unsubscribedAt, paymentsSubscription.unsubscribedAt));
      }

      if(shouldSyncSubscriptions){
        logger.info('Subscriptions are out of sync so going to force a re-sync', { userId: user.id });
        syncSubscriptions();
        setHaveReSyncedSubscriptions(true);
      }else{
        logger.info('Subscriptions are in sync', { userId: user.id });
      }
    }
  }, [subscription, paymentsSubscription]);

  const hasActiveSubscription = () => {
    return subscription &&
    subscription?.status != 'EXPIRED' &&
    subscription?.status != 'REFUNDED' &&
    subscription?.status != 'OVERDUE' &&
    subscription?.status != 'PAUSED';
  };

  const checkBillingStatus = async (history) => {
    if(payments.isAvailable()){
      if(user?.id && user.status != 'PENDING' && !subscription && !isLoading && isFeatureEnabled('billing')){
        history.push('/onboarding/info');
      }else if(subscription && !hasActiveSubscription()){
        history.push('/paywall');
      }
    }  
  };

  const startFreeTrial = async (callback) => {
    var subscription = await api.put({ url: 'billing/subscriptions/startTrial', getUserToken });
    setSubscription(subscription);
    if(callback){
      callback();
    }
  };

  const purchasePackage = async (p, callback) => {
    setIsPurchasingPackage(true);
    payments.purchasePackage(p, (subscription) => {
      setIsPurchasingPackage(false);
      setPaymentsSubscription(subscription);
      if(subscription){
        syncSubscriptions(callback);
      }
      
    });
  };

  const consumeAccessCode = async (accessCode) => {
    setIsConsumingAccessCode(true);
    var subscription = await api.put({ url: `billing/accesscodes/${accessCode.id}`, getUserToken });
    setSubscription(subscription);
    setIsConsumingAccessCode(false);
  };

  const purchaseDiscount = async (discount, callback) => {
    setIsPurchasingPackage(true);
    payments.redeemOffer(discount, (result) => {
    
      if(result?.shouldPollForSubscription){
        pollForSubscription()
          .then((s) => {
            setIsPurchasingPackage(false);
            if(s){
              syncSubscriptions(callback);
            }
          });
      }else if(result?.subscription){
        setIsPurchasingPackage(false);
        setPaymentsSubscription(subscription);
        if(subscription){
          syncSubscriptions(callback);
        }
      }else{
        setIsPurchasingPackage(false);
      }
    });
  };

  const getOfferCodeDetails = async (offerCode) => {
    var discounts = getDiscountDetails(offerCode);

    if(!discounts.isError){
      discounts.forEach(discount => {
        discount.offer =  offers.filter(o => o.id == discount.offerId)[0];
      });
    }

    return discounts;
  };

  const pollForSubscription = async (timeout = 10000) => {
    logger.info('Starting Polling For Subscription');
    if(subscription && paymentsSubscription){
      return subscription;
    }

    setIsPollingForSubscription(true);

    var startTime = Date.now();
    var result = null;
    await payments.syncBilling();
    while(Date.now() - startTime < timeout) {
      result = await payments.getCustomerInfo();

      if(result != null){
        break;
      }

      await sleep(1000);
    }

    logger.info('Finished Polling For Subscription');

    if(result){
      logger.info('Subscription Found');
      setPaymentsSubscription(result);
    }

    setIsPollingForSubscription(false);

    return result;
  };

  const cancelPollingForSubscription = async () => {
    logger.info('Cancel Polling For Subscription');
    setIsPurchasingPackage(false);
    setIsPollingForSubscription(false);
  };

  return (
    <BillingContext.Provider value={{ 
      isAvailable: true,
      subscription,
      hasActiveSubscription,
      rcSubscription: paymentsSubscription,
      isLoading,
      checkBillingStatus,
      startFreeTrial,
      offerings,
      purchasePackage,
      consumeAccessCode,
      isConsumingAccessCode,
      purchaseDiscount,
      getOfferCodeDetails,
      loadSubscription,
      pollForSubscription,
      isPollingForSubscription,
      cancelPollingForSubscription,
      isPurchasingPackage
    }}>
      {props.children}
    </BillingContext.Provider>
  );
}