import React, { createContext, useState  } from 'react';

export const DialogContext = createContext({});

import FeedbackDialog from './../components/templates/feedback/feedback-dialog';
import SurveyDialog from '@components/v2/organisms/feedback/feedback-dialog';

export function DialogProvider(props){
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [isSurveyDialogOpen, setIsSurveyDialogOpen] = useState(false);
  const [surveyDialogHandleClose, setSurveyDialogHandleClose] = useState(null);

  const handleCloseSurveyDialog = (shouldShowSurvey, wasSubmitted) => {
    if(surveyDialogHandleClose){
      surveyDialogHandleClose(shouldShowSurvey, wasSubmitted);
    }

    setIsSurveyDialogOpen(false);
  };

  return (
    <DialogContext.Provider value={{
      openFeedbackDialog: () => setIsFeedbackDialogOpen(true),
      openSurveyDialog: (handleClose) => {
        setSurveyDialogHandleClose(() => handleClose);
        setIsSurveyDialogOpen(true);
      },
      closeSurveyDialog: () => setIsSurveyDialogOpen(false)
    }}>
      {props.children}
      <FeedbackDialog isOpen={isFeedbackDialogOpen} close={() => setIsFeedbackDialogOpen(false)}/>
      <SurveyDialog isOpen={isSurveyDialogOpen} close={handleCloseSurveyDialog}/>
    </DialogContext.Provider>
  );
}