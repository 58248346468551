
import React from 'react';

export default function HeartBeat(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0377 2.42144C15.7623 2.24997 14.0161 2.24999 11.9431 2.25C9.87002 2.24999 8.23769 2.24998 6.96234 2.42144C5.65376 2.59738 4.60928 2.9661 3.78769 3.78769C2.9661 4.60928 2.59737 5.65376 2.42144 6.96234C2.24997 8.23769 2.24999 9.98391 2.25 12.0569C2.24999 14.13 2.24998 15.7623 2.42144 17.0377C2.59738 18.3462 2.9661 19.3907 3.78769 20.2123C4.60928 21.0339 5.65376 21.4026 6.96234 21.5786C8.23769 21.75 9.98391 21.75 12.0569 21.75C14.13 21.75 15.7623 21.75 17.0377 21.5786C18.3462 21.4026 19.3907 21.0339 20.2123 20.2123C21.0339 19.3907 21.4026 18.3462 21.5786 17.0377C21.75 15.7623 21.75 14.13 21.75 12.0569C21.75 9.9839 21.75 8.23769 21.5786 6.96234C21.4026 5.65376 21.0339 4.60928 20.2123 3.78769C19.3907 2.9661 18.3462 2.59737 17.0377 2.42144ZM11.4363 7.64888C11.2967 7.27647 10.9496 7.02223 10.5524 7.00138C10.1552 6.98054 9.78345 7.19705 9.60557 7.55279L7.88197 11H6C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H8.5C8.87877 13 9.22504 12.786 9.39443 12.4472L10.3689 10.4983L12.5637 16.3511C12.7033 16.7235 13.0504 16.9778 13.4476 16.9986C13.8448 17.0195 14.2166 16.803 14.3944 16.4472L16.118 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H15.5C15.1212 11 14.775 11.214 14.6056 11.5528L13.6311 13.5017L11.4363 7.64888Z"
        fill={props.fill}
        stroke={'none'}/>
    </svg>
  );
}