import React, { useContext, useEffect, useState } from 'react';

import { 
  Box,
  Grid
} from '@mui/material';

import { LoadableText } from '@components/atoms';
import { ServicesContext } from '@context/services';
import { UserContext } from '@context/user';

const Stat = (props) => {
  const { value, name, isLoading } = props;
  return (
    <Box sx={{ textAlign : 'center', my: 0.25, lineHeight: 1.1 }}>
      <Box sx={{ fontSize: 'h5.fontSize', fontWeight: 'bold' }}>
        <LoadableText text={value} isLoading={isLoading} minWidth={5}/>
      </Box>
      <Box sx={{ fontSize: 'caption.fontSize', color: 'text.secondary' }}>
        {name}
      </Box>
      
    </Box>
  );
};

export default function CommunityStats(props){
  var { profileId } = props ;
  const { api } = useContext(ServicesContext);
  const { user } = useContext(UserContext);
  var [stats, setStats] = useState(null);
  var [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(profileId){
      setIsLoading(true);
      api.get({ url: `profiles/${profileId}/stats`, user })
        .then((result) => {
          setStats(result);
          setIsLoading(false);
        });
    }
  }, [profileId]);
  
  return (
    <Grid container>
      <Grid item xs={4}>
        <Stat name='Posts' value={stats?.postsCount} isLoading={isLoading}/>
      </Grid>
      <Grid item xs={4}>
        <Stat name='Comments' value={stats?.commentsCount} isLoading={isLoading}/>
      </Grid>
      <Grid item xs={4}>
        <Stat name='Likes' value={stats?.likesCount} isLoading={isLoading}/>
      </Grid>
    </Grid>
  );
}
