import React, { useContext } from 'react';

import { 
  Box,
  Badge
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import config from '@config';
import { Avatar } from '@components/atoms';
import { ImageUploader, ProfileImage as ProfileImageComponent } from '@components/molecules';
import { UserContext } from '@context/user';

export default function  ProfileImage(props){
  const { user, handleSaveImage } = useContext(UserContext);
  const { profile }  = props;

  const saveImage = async (image) => {
    return handleSaveImage(image);
  };

  const isThisUser = () => {
    return user?.id && profile?.id == user?.id;
  };

  const getImageUrl = () => {
    if(isThisUser()){
      return user?.imageLocation ? `${config.contentBaseUrl}images/${user.imageLocation}?lastmod=${user.userImageModified ?? '1'}` : null;
    }else{
      return profile?.imageLocation ? `${config.contentBaseUrl}images/${profile.imageLocation}` : null; 
    }
  };

  return (
    <Box>
      <ImageUploader isEnabled={isThisUser()} saveImage={saveImage} imageId='profile-screen'>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          badgeContent={
            profile?.role === 'PRACTITIONER' ? 
              <AddIcon sx={{ color: '#fff', fontSize: 26, backgroundColor: 'primary.main', borderRadius: 1 }} />:
              <></>
          }
        >
          <ProfileImageComponent 
            profile={profile}
            size={112}
            iconSize={60}
            name={profile?.name}
            iconType={isThisUser() ? 'camera' : 'person'}
            text={(!getImageUrl() && isThisUser()) ? 'Click To Add An Image' : null } 
          />
        </Badge>
        
      </ImageUploader>
    </Box>
  );
}