import React, { useContext, useEffect, useRef, useState } from 'react';

import { Typography } from '@mui/material';
import { Button, Dialog, Icon, Box } from '../../atoms';
import FeedbackSurvey from './feedback-survey';

import { ServicesContext } from '@context';
import { UserContext } from '@context/user';
import { AccountOnboardingContext } from '@context/account-onboarding';
import { BillingContext } from '@context/billing';
import { EventsContext } from '@context/events';

export default function FeedbackDialog(props){
  const { isOpen, close, isDeleteAccount = false  } = props;
  const [shouldShowSurvey, setShouldShowSurvey ] = useState(false);
  const { user, getUserToken } = useContext(UserContext);
  const { subscription, loadSubscription } = useContext(BillingContext);
  const { trackEvent } = useContext(EventsContext);
  const { handleFeedbackSurveyComplete } = useContext(AccountOnboardingContext);
  const { api } = useContext(ServicesContext);
  const wasSubmitted = useRef(false);

  const handleClose = async () => {
    setShouldShowSurvey(false);
    if(wasSubmitted.current == true){
      await api.put({ url: 'accounts/feedback/complete', getUserToken });
      await loadSubscription();
      handleFeedbackSurveyComplete();
      close(shouldShowSurvey, wasSubmitted.current);
    }else{
      close(shouldShowSurvey, wasSubmitted.current);
    }
    
  };

  useEffect(() => {
    if(isOpen){
      trackEvent('Feedback:Dialog:Open');
    }

  }, [isOpen]);

  useEffect(() => {
    if(shouldShowSurvey){
      trackEvent('Feedback:Survey:Open');
    }

  }, [shouldShowSurvey]);

  return (
    <Dialog isOpen={isOpen}
      close={handleClose} 
      variant={shouldShowSurvey ? 'fullscreen' : 'near-fullscreen'}
      theme = {shouldShowSurvey ? 'dark' : 'light'}
      enableCloseButton={shouldShowSurvey ? true : false}
    >
        
      {!shouldShowSurvey &&
       <Box center='horizontal' sx={{ height: '100%', p: 2, paddingTop: 3 }}>
         <Box center='horizontal' sx={{ flexGrow: 1 }}>
           <Icon type='chat' size={60}/>
           {isDeleteAccount &&
            <>
              <Typography variant='h5' textAlign='center' py={2}>
                Before You Go! How About A Month FREE Access?
              </Typography>
              <Typography variant='body2' textAlign='center' paddingBottom={3}>
              There are obviously some parts of Brace that have not lived up to your expectations. We would love to hear from members like you who aren’t seeing enough value in the platform.
              </Typography>
            </>
           }
           {!isDeleteAccount &&
            <>
              {}
              <Typography variant='h5' textAlign='center' py={2}>
                {subscription?.isTrial && subscription.status == 'EXPIRED' ? 
                  'Your Free Trial Ended, Give Feedback For 1 Month FREE Access!' :
                  'Get 1 Month FREE Access For Feedback!'
                }
              </Typography>
              <Typography variant='body2' textAlign='center' paddingBottom={3}>
                At Brace, we are building a best-in-class rehabilitation platform for real injured people. To be the best platform, we need to hear from our members—<b>you!</b>
              </Typography>
            </>
           }
           <Typography variant='body1'
             fontWeight='medium'
             textAlign='center'
             paddingBottom={2}>
              What’s in it for you?
           </Typography>
           <Typography variant='body2' textAlign='center' paddingBottom={2}>
              We will give you a <b>month</b> of <b>free</b> access to Brace so you can see the changes based on your feedback.
           </Typography>
           <Typography variant='body2' textAlign='center' paddingBottom={2}>
              We want you on this journey with us, and together we can build Brace to support rehabilitation globally!
           </Typography>
         </Box>
         <Box>
           <Button fullWidth
             sx={{ marginBottom: 1 }}
             variant='contained'
             onClick={() => setShouldShowSurvey(true)}
             size='veryLarge'>
          I Want To Give Feedback!
           </Button>
           <Button fullWidth
             color='negative'
             variant='outlined'
             size='veryLarge'
             onClick={() => {
               setShouldShowSurvey(false);
               handleClose(false);
             }}>
            No Thanks
           </Button>
         </Box>
       </Box>
      }
      {shouldShowSurvey &&
        <FeedbackSurvey 
          userId={user.id}
          userFirstName={user.firstName}
          userEmail={user.email}
          close={handleClose}
          wasSubmitted={wasSubmitted} />
      }
    </Dialog>
  );
}