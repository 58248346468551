
import React from 'react';

export default function ThumbUp(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={'none'}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 12.625C1.25 11.0372 2.49721 9.75003 4.03571 9.75003C6.08706 9.75003 7.75 11.4663 7.75 13.5834V17.4167C7.75 19.5338 6.08706 21.25 4.03571 21.25C2.49721 21.25 1.25 19.9628 1.25 18.375V12.625ZM4.03571 11.6667C3.52288 11.6667 3.10714 12.0958 3.10714 12.625V18.375C3.10714 18.9043 3.52288 19.3334 4.03571 19.3334C5.06139 19.3334 5.89286 18.4752 5.89286 17.4167V13.5834C5.89286 12.5248 5.06139 11.6667 4.03571 11.6667Z"
        fill={props.fill}
        stroke={props.stroke}/>
      <path d="M12.799 3.18499C13.5298 2.54898 14.6525 2.61713 15.2964 3.34567C15.4097 3.48751 15.6704 3.83281 15.807 4.07931C16.4367 5.11969 16.6286 6.35887 16.3392 7.53622C16.3068 7.66781 16.2656 7.80085 16.2023 8.00546L15.9289 8.88818C15.8154 9.25476 15.7468 9.47927 15.7117 9.64734C15.6855 9.74004 15.6985 9.9283 15.9601 9.93979C16.1402 9.95585 16.8457 9.95655 17.2394 9.95655C18.4766 9.95653 19.4811 9.95651 20.2488 10.0581C21.0337 10.162 21.7462 10.3913 22.2315 10.9996C22.3273 11.1197 22.4127 11.2476 22.4867 11.3821C22.8663 12.0718 22.7859 12.8147 22.5573 13.5625C22.335 14.2901 21.9188 15.1861 21.409 16.2837C20.9359 17.3024 20.516 18.2066 20.1401 18.8407C19.7512 19.4967 19.3355 20.0124 18.7609 20.4046C18.0878 20.8641 17.1903 21.1044 16.4221 21.1783C15.6771 21.25 14.7621 21.25 13.6144 21.25C12.2303 21.25 10.5147 21.25 9.64051 21.1362C8.73584 21.0183 7.97376 20.7667 7.3663 20.1781C6.75553 19.5863 6.49136 18.8385 6.36819 17.9508C6.25001 17.0992 6.25003 16.0174 6.25005 14.6802L6.25003 13.5075C6.24964 12.1895 6.24939 11.3282 6.55913 10.5294C6.86812 9.73266 7.45104 9.08753 8.34723 8.09572L12.5574 3.43371C12.6369 3.34545 12.7182 3.25529 12.799 3.18499Z" 
        fill={props.fill}
        stroke={props.stroke}/>
    </svg>
  );
}