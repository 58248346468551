import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { 
  LocalizationProvider, 
  DatePicker 
} from '@mui/x-date-pickers';

import { 
  Box,
  Divider,
  TextField,
  Stack,
  Typography
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';

import { CountrySelect } from '@components/atoms';
import moment from 'moment';
import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';

const validationSchema = yup.object({
  name: yup
    .string('Name your email')
    .required('Name is required')
});

export default function ProfileForm(){
  const { enqueueSnackbar } = useSnackbar();
  const { user, handleUpdate } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const history = useHistory();

  const formik = useFormik({
    initialValues:{
      name: user?.name,
      bio: user?.bio ?? '',
      occupation: user.properties?.occupation ?? '',
      country: user?.country,
      injuryType: user?.injury?.type,
      activityType: user?.injury?.activityType,
      injuryDate: user?.injury?.date ? moment(user?.injury?.date) : null,
      practiceName: user.properties?.practiceName ?? '',
      practiceLocation: user.properties?.practiceLocation ?? ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      var properties = {};

      api.put({ url: 'users', user, data: {
        country: values.country,
        name: values.name,
        bio: values.bio,
        injury: {
          activityType: values.activityType,
          date: values.injuryDate,
          type: values.injuryType
        },
        properties
      } })
        .then((updatedUser) => {
          setSubmitting(false);
          handleUpdate(updatedUser);
          enqueueSnackbar('Successfully updated profile', { 
            variant: 'success'
          });
          history.goBack();
        })
        .catch(error => {
          enqueueSnackbar('Failed to update profile', { 
            variant: 'error'
          });
          setSubmitting(false);
        });
    }
  });

  return (
    <Box sx={{ height: '100%' }}>
      {user.id && 
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <form onSubmit={formik.handleSubmit}>
          
          <Box p={1} display='flex' alignItems='center'>
            <Box flexGrow={1}>
              <CloseIcon onClick={() => history.goBack()} style={{ fontSize: 28 }} color="primary" />
            </Box>
            <Box>
              <LoadingButton 
                data-testid='profile-edit-save-button'
                size='small'
                type="submit"
                color="primary"
                variant='contained'
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}>
                  Save
              </LoadingButton>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ padding: 2 }}>
            <Stack spacing={2}>
              <TextField
                inputProps={{ 'data-testid': 'profile-edit-name-input' }}
                fullWidth
                size='small'
                label='Name'
                id='name'
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                inputProps={{ 'data-testid': 'profile-edit-bio-input' }}
                fullWidth
                rows={4}
                multiline
                size='small'
                label='Bio'
                id='bio'
                onChange={formik.handleChange}
                value={formik.values.bio}
                error={formik.touched.bio && Boolean(formik.errors.bio)}
                helperText={formik.touched.bio && formik.errors.bio}
              />

              <CountrySelect 
                handleChange={(value) => {formik.setFieldValue('country', value);}}
                value={formik.values.country}
                touched={formik.touched.country}
                error={formik.errors.country}/>
            </Stack>
            {(!user.role || user?.role == 'CLIENT') &&
              <Stack spacing={2}>
                <Box width='100%' marginTop={1}>
                  <Typography variant="h6">
                  Injury Information
                  </Typography>
                </Box>
                <TextField
                  inputProps={{ 'data-testid': 'profile-edit-injury-type-input' }}
                  fullWidth
                  size='small'
                  label='Injury Type'
                  id='injuryType'
                  onChange={formik.handleChange}
                  value={formik.values.injuryType}
                  error={formik.touched.injuryType && Boolean(formik.errors.injuryType)}
                  helperText={formik.touched.injuryType && formik.errors.injuryType}
                />
                <TextField
                  fullWidth
                  size='small'
                  label='Activity Type'
                  id='activityType'
                  onChange={formik.handleChange}
                  value={formik.values.activityType}
                  error={formik.touched.activityType && Boolean(formik.errors.activityType)}
                  helperText={formik.touched.activityType && formik.errors.activityType}
                />
                <DatePicker
                  fullWidth
                  label='Injury Date'
                  id='injuryDate'
                  value={formik.values.injuryDate}
                  error={formik.touched.injuryDate && Boolean(formik.errors.injuryDate)}
                  helperText={formik.touched.injuryDate && formik.errors.injuryDate}
                  onChange={(value) => {formik.setFieldValue('injuryDate', value);}}
                  renderInput={(params) => <TextField size='small' {...params} />}
                />
              </Stack>
            }
          </Box>
          
        </form>
      </LocalizationProvider>
      }
    </Box>
  );
}

