import React from 'react';
import moment from 'moment';

import {
  Stack,
  Card,
  Box,
  Typography
} from '@mui/material';

import { ProgressLinear } from '@components/atoms';

function getProgramCompletionPercentage(program){
  if(program.sessions.totalCount === 0){
    return 0;
  }
  return ( program.sessions.completeCount * 100 ) / program.sessions.totalCount;
}

function getDaysLeft(program){
  var endDate = moment(program.endDate);
  var daysRemaining = endDate.diff(moment().utc(), 'days');
  return daysRemaining > 0 ? daysRemaining : 0;
}

export default function InProgressProgram(props){
  return (
    <Card sx={{ padding:1, height: '100%' }}>
      <Stack spacing={1}>
        <Box>
          <Typography component="div">
            <Box sx={{ fontSize: 'body1.fontSize', fontWeight:'medium', color: 'text.primary' }}>
              {props.program.name} 
            </Box>
          </Typography>
        </Box>
        <Box>
          <ProgressLinear value={ getProgramCompletionPercentage(props.program)} />
        </Box>
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Stack direction="column">
            <Typography component="div" sx={{ width: '100%' }}>
              <Box sx={{ fontSize: 'body2.fontSize', fontWeight:'medium', color: 'text.primary', lineHeight: 1 }}>
                {`${props.program.sessions.completeCount} Sessions`}
              </Box>
            </Typography>
            <Typography component="div" sx={{ width: '100%' }}>
              <Box sx={{ fontSize: 'caption.fontSize', fontWeight:'normal', color: 'text.secondary' }}>
                Complete
              </Box>
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography component="div" sx={{ width: '100%' }}>
              <Box sx={{ fontSize: 'body2.fontSize', fontWeight:'medium', color: 'text.primary', lineHeight: 1 }}>
                {`${getDaysLeft(props.program)} Days`}
              </Box>
            </Typography>
            <Typography component="div" sx={{ width: '100%' }}>
              <Box sx={{ fontSize: 'caption.fontSize', fontWeight:'normal', color: 'text.secondary' }}>
                Remaining
              </Box>
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
}
