import React from 'react';

import {
} from '@mui/material';

import { Box, Icon, IconButton } from './../../../v2/atoms';
import { useTheme } from '@emotion/react';
import Image from 'mui-image';

export default function PaywallPage(props) {
  var { children, backAction } = props;
  const theme = useTheme();
  return (
    <Box
      flexColumn
      sx={{
        height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))'
      }}>
      <Box flex sx={{ background: theme.palette.primary.main, p: 2, width: '100%' }}>
        <Box center='vertical' sx={{ width: '20%' }}>
          {backAction &&
            <IconButton 
              variant='rounded-outlined' 
              onClick={backAction}
            >
              <Icon 
                size={20}
                type={'back'}
                fill='rgba(255, 255, 255, 0.12)'
                stroke={theme.palette.braceGreenDark.contrastText}
              />
            </IconButton>
          }
        </Box>
        <Box sx={{ width: '60%', display: 'flex', justifyContent: 'center' }}>
          <Image 
            duration={500} 
            width='50%' 
            src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_White%20and%20grad.png'/>
        </Box>
        
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        {children}
      </Box>
      
    </Box>
  );
}