import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Box,
  BottomNavigation,
  BottomNavigationAction
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { UserContext } from '@context';

import { Icon } from './../../atoms';

export default function BottomNavBar(){
  const location = useLocation();

  const [value, setValue] = useState(location.pathname);
  const { user } = useContext(UserContext);
  var theme = useTheme();

  const isActive = (expectedPath) => {
    return value && 
      value.includes &&
      value.includes(expectedPath);
  };

  const NavBarItem = (props) => {
    const { label, iconType, to } = props;
    return (
      <BottomNavigationAction 
        data-testid={props.dataTestId}
        sx={{ 
          minWidth: 0,
          width: 'calc(20% + 2px)',
          height: 'calc(100% + 2px)',
          marginX: '-1px',
          marginY: '-1px',
          color: props.isActive ? 
            theme.palette.braceGreenLight.main : 
            '#BFC4C3',
          backgroundColor: theme.palette.braceGreenDark.main,
          fontSize: '8px'
        }}
        component={Link}
        showLabel
        to={to}
        label={label} 
        icon={
          <Icon 
            sx={{ marginBottom: 0.5 }}
            size={24}
            stroke={theme.palette.braceGreenDark.main}
            fill={props.isActive ? theme.palette.braceGreenLight.main : '#BFC4C3'}
            type={iconType}
          />
        } />
    );
  };

  return (
    <Box 
      id='bottom-nav-bar'
      sx={{
        position: 'relative',
        width:'100%',
        zIndex: 1199,
        paddingTop: 1,
        paddingBottom: 1,
        color: '#BFC4C3',
        overflow: 'hidden',
        backgroundColor: theme.palette.braceGreenDark.main
      }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {(!user?.role || user?.role === 'CLIENT') &&
              <NavBarItem isActive={isActive('learn')}
                dataTestId='bottom-navigation-menu-button-trophies'
                to='/learn'
                label="Learn"
                iconType='learn'/>
        }
        <NavBarItem isActive={isActive('community')}
          dataTestId='bottom-navigation-menu-button-community'
          to='/community'
          label="Community"
          iconType='group'/>
        <NavBarItem 
          isActive={isActive('home')}
          dataTestId='bottom-navigation-menu-button-home'
          to='/home'
          label="Home"
          iconType='home'/>
        <NavBarItem isActive={isActive('recovery')}
          dataTestId='bottom-navigation-menu-button-recovery'
          to={'/recovery'}
          label="Recovery"
          iconType='dumbbell'/>
        {(!user?.role || user?.role === 'CLIENT') &&
              <NavBarItem isActive={isActive('/diary')}
                dataTestId='bottom-navigation-menu-button-diary'
                to='/diary'
                label="Diary"
                iconType='diary'/>
        }
      </BottomNavigation>
    </Box>
  );
}