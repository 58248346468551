import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { 
  Box,
  Typography,
  Stack,
  Badge
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { BillingContext, UserContext, ServicesContext } from '@context';
import { IconButton, Icon } from './../../atoms';
import useNotificationsStore from '@stores/notifications';
import useTrophiesStore from '@stores/trophies';
import TopNavBarProfile from './top-bar-profile';

export default function TopNavBar(props){
  const { title, includeProfile } = props;
  const history = useHistory();
  const { subscription, isAvailable } = useContext(BillingContext);
  const { user, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  var theme = useTheme();

  const { unreadCount, hasLoaded : hasLoadedNotifications, isLoading : isLoadingNotifications, load : loadNotifications } = useNotificationsStore();
  const { completeCount, hasLoaded : hasLoadedTrophies, isLoading : isLoadingTrophies, load : loadTrophies } = useTrophiesStore();

  useEffect(() => {
    if(user?.id && !hasLoadedNotifications && !isLoadingNotifications){
      loadNotifications(api, getUserToken);
    }
    if(user?.id && !hasLoadedTrophies && !isLoadingTrophies){
      loadTrophies(api, getUserToken);
    }
  }, []);

  const TopBarButton = (props) => {
    const { 
      iconType, 
      onClick, 
      badgeColor=theme.palette.braceGreenLight.main, 
      badgeFontColor=theme.palette.braceGreenDark.main, 
      badgeCount = 0 } = props;
    return (
      <Badge badgeContent={badgeCount}
        color="primary"
        overlap="circular"
        sx={{ '.MuiBadge-badge': { height: '11px', minWidth: '14px', width: '14px', fontSize: '8px', backgroundColor: badgeColor, color: badgeFontColor } }}>
        <IconButton 
          variant='rounded' 
          onClick={onClick}
        >
          <Icon 
            size={16}
            type={iconType}
            fill='rgba(255, 255, 255, 0.12)'
            stroke={theme.palette.braceGreenDark.contrastText}
          />
        </IconButton>
      </Badge>
    );
  };

  return (
    <Box
      sx={{
        color: theme.palette.braceGreenDark.contrastText,
        paddingX: 2,
        paddingY: 2,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {includeProfile &&
      <Box sx={{ flexGrow: 1 }} >
        <TopNavBarProfile />
      </Box>
      }
      {!includeProfile &&
        <Typography sx={{ flexGrow: 1 }} variant='h5' fontWeight='medium'>
          {title}
        </Typography>
      }
      <Stack direction="row" spacing={1}>
        <TopBarButton 
          badgeCount={completeCount}
          iconType='trophy'
          onClick={() => {
            history.push('trophies');
          }}
        />
        {(subscription?.isTrial && isAvailable) &&
          <TopBarButton 
            iconType='crown'
            onClick={() => {
              history.push('paywall');
            }}
          />
        }
        <TopBarButton 
          badgeColor={'#EA4334'}
          badgeFontColor={'#FFFFFF'}
          badgeCount={unreadCount}
          iconType='bell'
          onClick={() => {
            history.push('notifications');
          }}
        />
      </Stack>
    </Box>
  );
}