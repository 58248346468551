import React, { useContext, useEffect, useState } from 'react';

import { 
  Box, 
  Card, 
  Typography,
  Stack
} from '@mui/material';

import { ClientContext } from '../../../client-context';
import { ServicesContext } from '@context/services';
import { UserContext } from '@context/user';
import ProgramCard from './program-card';
import { EmptyState } from '@components/molecules';

export default function Programs() {
  const { id, profile } = useContext(ClientContext);
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setPrograms([]);
    api.get({ url: 'recovery/programs', params: { userId: id }, user })
      .then((result) => {
        setIsLoading(false);
        setPrograms(result.results);
      });
  }, [id]);

  return(
    <Card sx={{ width: '100%', maxWidth: '100%', p: 1 }}>
      <Typography color='text.secondary'>
        Programs
      </Typography>
      <Box sx={{ width: '100%', maxWidth: '100%' }}>
        <Stack spacing={1} direction='row' sx={{ overflowX: 'auto' }}>
          {!isLoading && programs.map((p => (
            <ProgramCard program={p} key={p.id} isLoading={false} />
          )))}
          {(!isLoading && programs.length == 0 ) &&
            <EmptyState 
              size={100}
              iconType='appointment'
              message={`Once ${profile?.name} creates a Program, their progress will be shown here.`}
            />
          }
          {isLoading && [1, 2, 3, 4, 5, 6].map((index => (
            <ProgramCard key={index} isLoading={true} />
          )))}
        </Stack>
      </Box>
      
    </Card>
  );
}
