import React, { useContext } from 'react';

import {
  Dialog,
  Box,
  Slide
}from '@mui/material';

import config from '@config';

import { NewPlanProvider, NewPlanContext } from './context';

import Steps from './steps';
import StepsV2 from './steps-v2';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function NewPlanDialog() {
  const { isOpen, close } = useContext(NewPlanContext);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Box sx={{ height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))', marginTop: 'calc(env(safe-area-inset-top))', marginBottom: 'calc(env(safe-area-inset-bottom))', display: 'flex', flexDirection: 'column' }}>
        {(!config.isWebApp) ? <StepsV2 /> : <Steps />}
      </Box>
    </Dialog>
  );
}

export default function NewPlanDialogWrapper(props) {
  return (
    <NewPlanProvider isOpen={props.isOpen} close={props.close} planBeingEdited={props.planBeingEdited}>
      <NewPlanDialog />
    </NewPlanProvider>
  );
  
}