import React, { useContext } from 'react';

import {
  Box, 
  Grid,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { NewPostContext } from './new-post-context';
import { NewPostInputContext } from './new-post-input-context';
import { LoadingButton } from '@mui/lab';

export default function Header() {
  const { closeNewPostDialog, isSaving, settings, save } = useContext(NewPostContext);
  const { message, title, image, isButtonEnabled } = useContext(NewPostInputContext);

  return (
    <Box sx={{
      p: 1 
    }} >
      <Grid container direction="row" alignItems="center">
        <Grid item xs={4}>
          <IconButton onClick={closeNewPostDialog} size='small'>
            <CloseIcon/>
          </IconButton>
          
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', alignItems: 'right' }}>
          <LoadingButton 
            size='small'
            data-testid='new-post-dialog-button'
            variant='contained'
            color='secondary'
            onClick={() => save({ message, title, image })}
            loading={isSaving}
            disabled={!isButtonEnabled}>
            {settings.buttonText}
          </LoadingButton>
        </Grid>
      </Grid>
     
    </Box>
  );
}