import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { 
  Dialog,
  DialogTitle,
  Slide,
  DialogContent,
  DialogActions,
  Button,
  Box 
} from '@mui/material';

import { EmptyState } from '@components/molecules';
import { MultipleSelect } from '@components/atoms';
import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';
import { useTheme } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddClientsToGroup(props) {
  const { isOpen, close, group } = props;
  const { user, activeOrganisation } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme  = useTheme();
  const { api } = useContext(ServicesContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoadingOrganisationAccounts, setIsLoadingOrganisationAccounts] = useState(false);

  const loadOrganisationAccounts = () => {
    setIsLoadingOrganisationAccounts(true);
    api.post({ url: `organisations/${activeOrganisation.id}/accounts/search`, data: {}, user })
      .then((response) => {
        var accountsNotAlreadyInGroup = response
          .results.filter(a => group.accounts.filter(ga => ga.account.id == a.account.id).length === 0);
        
        setOptions(accountsNotAlreadyInGroup.map(a => {return { id: a.account.id, label: a.account.name };}));
        setIsLoadingOrganisationAccounts(false);
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setIsSaving(true);
    api.put({ url: `organisations/${activeOrganisation.id}/teams/${group.id}`, 
      data: { accountIdsToAdd:selectedOptions.map(so => so.id) }
      , user })
      .then(() => {
        enqueueSnackbar('Successfully updated group', { 
          variant: 'success'
        });
        close(true);
        setIsSaving(false);
      })
      .catch((err) => {
        if(err.data.message){
          enqueueSnackbar(err.data.message, { 
            variant: 'error'
          });
        }else{
          enqueueSnackbar('Group update failed', { 
            variant: 'error'
          });
        }
       
        setIsSaving(false);
      });

  }

  useEffect(() => {
    if(isOpen){
      setSelectedOptions([]);
      loadOrganisationAccounts();
    }
  }, [isOpen]);

  return(
    <Dialog 
      sx={{
        '.MuiPaper-root' : {
          [theme.breakpoints.down('md')]: {
            width: '90%'
          },
          [theme.breakpoints.up('md')]: {
            width: '50%'
          }
        }
      }}
      TransitionComponent={Transition}
      open={isOpen}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <DialogTitle>{'New Client'}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <>
              {!isLoadingOrganisationAccounts && options?.length == 0 &&
              <EmptyState 
                title='No Clients Available'
                message='You do not have any clients available to add to this group.'
                iconType='avatar-search'
              /> 
              }
              {isLoadingOrganisationAccounts || options?.length > 0 &&
              <MultipleSelect
                fullWidth
                disabled={isLoadingOrganisationAccounts}
                options={options}
                label="Users"
                handleChange={(value) => setSelectedOptions(value)}
              />
              }
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close(false)}>Cancel</Button>
            <LoadingButton loading={isSaving || isLoadingOrganisationAccounts}
              disabled={selectedOptions.length == 0}
              variant='contained'
              color='secondary'
              type="submit">
                Add
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>

  );
}