import React, { useContext, useState, useEffect } from 'react';

import { 
  Box,
  Divider,
  InputBase,
  Typography
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Close, ChevronLeft } from '@material-ui/icons';

import { ImageUploader, ProfileImage } from '@components/molecules';
import { Dialog, Button } from '@components/v2/atoms';
import { UserContext, AccountOnboardingContext } from '@context';
import { useTheme } from '@emotion/react';
import PickCategories from './pick-categories';
import { ServicesContext } from '@context/services';
import { EventsContext } from '@context/events';
import { LoadingButton } from '@mui/lab';

export default function CreateTopicDialog(props) {
  const { isOpen, close } = props;
  const theme = useTheme();
  const { user, getUserToken } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { api } = useContext(ServicesContext);
  const { accountOnboarding } = useContext(AccountOnboardingContext);
  const [ title, setTitle ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ image, setImage ] = useState(null);
  const [ activeStep, setActiveStep ] = useState(0);
  const [ isSaving, setIsSaving ] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const loadCategories = async () => {
    var result = await api.get({ url: 'community/categories', getUserToken });
    setCategories(result.results);
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  
  var isContinueEnabled = activeStep == 0 ?
    (title != '' && message != '') :
    selectedCategories.length > 0;

  const handleClose = ({ topic }) => {
    if(activeStep > 0 && !topic){
      setActiveStep(activeStep - 1);
    }else{
      setTitle('');
      setMessage('');
      setImage(null);
      setActiveStep(0);
      setSelectedCategories([]);
      setIsSaving(false);
      close(topic);
    }
  };

  const handleContinue = async () => {
    if(activeStep == 0){
      setActiveStep(activeStep + 1);
    }
    else{
      setIsSaving(true);
      const formData = new FormData();
      formData.append('name', title);
      formData.append('description', message);
      formData.append('categories', selectedCategories.map(c => c.name));

      if(image){
        formData.append('image', image);
      }

      var topic = await api.post({ url: 'community/topics', data: formData, getUserToken });
      trackEvent('Community:Topic:Create:Save');
      handleClose({ topic });
    }
  };

  return (
    <Dialog 
      enableCloseButton={true}
      closeButtonIcon={activeStep == 0 ? <Close /> : <ChevronLeft /> }
      variant='fullscreen'
      isOpen={isOpen}
      close={handleClose}
      title={activeStep == 0 ? 'Create Discussion' : 'Add Categories'}
      button={
        <LoadingButton 
          data-testid='new-topic-dialog-button'
          loading={isSaving}
          variant='contained'
          color='secondary'
          disabled={!isContinueEnabled}
          onClick={handleContinue}
          size='small'>
          {activeStep == 0 ? 'Continue' : 'Post'}
        </LoadingButton>
      }
    >
      {activeStep == 0 &&
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ px: 2, paddingTop: 2, display: 'flex', alignItems: 'center' }}>
          <ProfileImage 
            user={user}
            isPro={accountOnboarding?.isComplete}
          />
          <Box sx={{ flexGrow: 1, paddingLeft: 1 }}>
            <Typography fontWeight='medium'>
              {user?.name}
            </Typography>
          
          </Box>
        </Box>
        <Box sx={{  px: 2, flexGrow: 1 }}>
          <Box paddingTop={1} width='100%' sx={{ flexGrow: 1 }}>
            <InputBase
              data-testid='new-topic-dialog-title-input'
              fullWidth
              multiline
              autoFocus
              sx={{ color: 'text.primary', px: 0, fontSize: 'h5.fontSize' }}
              placeholder={'Discussion Title'}
              value={title}
              onChange={handleTitleChange} />
          </Box>
          <Box width='100%'>
            <InputBase
              data-testid='new-topic-dialog-description-input'
              fullWidth
              multiline
              sx={{ color: 'text.primary', flexGrow: 1 }}
              placeholder='Write your thoughts in details here '
              value={message}
              onChange={handleChange} />
          </Box>
          {image &&
            <Box
              width='100%'  
              sx={{ 
                justifyContent: 'center',
                width: '100%',
                display: 'flex'
              
              }}>
              <Box
                sx={{ 
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '80%'
                  },
                  [theme.breakpoints.only('md')]: {
                    maxWidth: '50%'
                  },
                  [theme.breakpoints.up('lg')]: {
                    maxWidth: '33%'
                  }   
                        
                }}>

                <img style= {{ width: '100%', borderRadius:'4px' }} src={URL.createObjectURL(image)} />
              </Box>
            </Box>
          }
        </Box>
        <Divider />
        <Box sx={{ paddingTop: 1 }}>
          <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
          
            <ImageUploader imageId='NewPostImage'
              isEnabled={true}
              saveImage={setImage}
              button={
                <Button size='large' color='braceGreenDark' startIcon={<AddPhotoAlternateIcon />}>
                Photo
                </Button>
              } />
          </Box>
        </Box>
      </Box>
      }
      {activeStep == 1 &&
        <PickCategories categories={categories} selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories}/>
      }
    </Dialog>
  );
}