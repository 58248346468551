import React from 'react';

import { 
  Card, 
  Box, 
  Typography,
  Button
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { Icon } from '../atoms';

export default function EmptyState(props) {
  const { title, message, buttonText, buttonAction, iconType, fullWidth=false, size=150, buttonVariant='contained', shouldHideBorder= false, buttonColor='primary', buttonDataTestId='' } = props;
  const theme = useTheme();

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center',
      width: '100%'
    }}>
      <Card variant='outlined'
        sx={{ 
          borderStyle : shouldHideBorder ? 'none' : 'solid',
          [theme.breakpoints.down('md')]: {
            width: '100%'
          },
          [theme.breakpoints.up('md')]: {
            width: !fullWidth ? '50%' : '100%'
          }, 
          textAlign: 'center', 
          p: 1 
        }}>
        <Icon type={iconType}
          color='red'
          delay={500}
          size={`${size}px`}/>
        <Typography variant='h5' data-testid={props['data-testid']}>
          {title}
        </Typography>
        <Typography variant='body1' py={title ? 2 : 0} component='div'>
          {message}
        </Typography>
        {(buttonAction && buttonText) &&
        <Button size='small'
          sx={{ marginTop: 2 }}
          data-testid= {buttonDataTestId}
          onClick={buttonAction}
          variant={buttonVariant}
          color={buttonColor}>
          {buttonText}
        </Button>
        }
      </Card>
    </Box>
  );
}