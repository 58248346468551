import React, { createContext, useContext, useState  } from 'react';

import { UserContext } from './user';
import { ServicesContext } from './services';
import { sortByUpdatedAt } from '@helpers/sorter';
import moment from 'moment';

export const RecoveryContext = createContext();

export function RecoveryProvider(props){
  const { getUserToken, user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);

  const [activeDay, setActiveDay] = useState(moment());
  const [activeDaysSessions, setActiveDaysSessions] = useState([]);
  const [isLoadingActiveDaysSessions, setIsLoadingActiveDaysSessions] = useState(true);

  const [dailySessionStats, setDailySessionStats] = useState([]);
  const [isLoadingDailySessionStats, setLoadingIsDailySessionStats] = useState(true);

  const [programs, setPrograms] = useState([]);
  const [isLoadingPrograms, setIsLoadingPrograms] = useState(true);

  const [plans, setPlans] = useState([]);
  const [plansIncludedDraftsAndArchived, setPlansIncludedDraftsAndArchived] = useState(true);
  const [isLoadingPlans, setIsLoadingPlans] = useState(true);

  const loadPrograms = async (forceRefresh) => {
    if(forceRefresh || programs.length === 0) {
      setIsLoadingPrograms(true);
      var result = await api.get({ url: 'recovery/programs', params: {  }, getUserToken });
      setPrograms(sortByUpdatedAt(result.results));
      setIsLoadingPrograms(false);
    }
  };

  const loadActiveDaySessions = async (forceRefresh, newActiveDay) => {
    if(forceRefresh || plans.length === 0) {
      setIsLoadingActiveDaysSessions(true);
      var day = newActiveDay ?? activeDay;
      var result = await api.get({ url: 'recovery/sessions', params: { plannedStartDate: day.clone().startOf('day').toISOString() }, getUserToken });
      setActiveDaysSessions(sortByUpdatedAt(result.results));
      setIsLoadingActiveDaysSessions(false);
    }
  };

  const loadDailySessionStats = async (forceRefresh, newActiveDay) => {
    if(forceRefresh || plans.length === 0) {
      setLoadingIsDailySessionStats(true);
      var day = newActiveDay ?? activeDay;
      var result = await api.get({ url: 'recovery/stats/sessions/daily', params: { 
        fromDate: day.clone().subtract(7, 'days').startOf('day').toISOString(),
        toDate: day.clone().add(7, 'days').startOf('day').toISOString(),
        userId: user.id
      }, getUserToken });
      setDailySessionStats(result);
      setLoadingIsDailySessionStats(false);
    }
  };

  const loadPlans = async (forceRefresh) => {
    if(forceRefresh || plans.length === 0) {
      setIsLoadingPlans(true);
      var result = await api.get({ url: 'recovery/plans', params: { shouldIncludeDeleted: true, shouldIncludeDrafts: true }, getUserToken });
      setPlans(sortByUpdatedAt(result.results.filter(p => !p.deletedAt && !p.isDraft)));
      setPlansIncludedDraftsAndArchived(sortByUpdatedAt(result.results));
      setIsLoadingPlans(false);
    }
  };

  const loadAll = async(forceRefresh) => {
    loadPrograms(forceRefresh);
    loadPlans(forceRefresh);
    loadActiveDaySessions(forceRefresh);
    loadDailySessionStats(true);
  };

  const moveActiveDay = async(newActiveDay) => {
    setActiveDay(newActiveDay);
    setDailySessionStats([]);
    loadActiveDaySessions(true, newActiveDay);
    loadDailySessionStats(true, newActiveDay);
  };

  return (
    <RecoveryContext.Provider value={{ 
      loadAll,
      loadPrograms,
      programs,
      isLoadingPrograms,
      loadPlans,
      plans,
      plansIncludedDraftsAndArchived,
      isLoadingPlans,
      loadDailySessionStats,
      loadActiveDaySessions,
      dailySessionStats,
      isLoadingDailySessionStats,
      activeDay,
      moveActiveDay,
      activeDaysSessions,
      isLoadingActiveDaysSessions
    }}>
      {props.children}
    </RecoveryContext.Provider>
  );
}