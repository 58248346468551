import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor';
import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';
import { isNative } from '@helpers/device';

const mapCustomerInfoToSubscription = (customerInfoParam) => {
  var customerInfo = customerInfoParam;
  if(customerInfoParam?.customerInfo){
    customerInfo = customerInfoParam.customerInfo;
  }
  if(customerInfo?.entitlements?.active && Object.keys(customerInfo?.entitlements?.active).length > 0){
    var entitlement = customerInfo?.entitlements?.active[Object.keys(customerInfo?.entitlements?.active)[0]];
    var expiresAt = entitlement?.expirationDate;
    var billingIssueDetectedAt = entitlement?.billingIssueDetectedAt;
    var unsubscribedAt = entitlement.unsubscribeDetectedAt;
    var store = entitlement?.store;
    var managementUrl = customerInfo.managementURL;
    
    return { expiresAt, unsubscribedAt, billingIssueDetectedAt, store, managementUrl };
  }
  return null;
};

export default class Payments {
  constructor(config, logger) {
    this.config = config;
    this.logger = logger;
  }
  isAvailable(){
    return isNative() && typeof Purchases !== 'undefined';
  }
  async init(user){
    if(this.isAvailable()){
      try{
        this.logger.info('Configuring Payment Plugin');
        await Purchases.setLogLevel(LOG_LEVEL.DEBUG);
        await Purchases.configure({
          apiKey: Capacitor.getPlatform() == 'android' ?
            'goog_NvQTtzPUcfiiTbEfPifvNkRxBtR' : 
            'appl_pweIdpjorNNvfxrUhTxvjsBjXaT',
          appUserID: user.id
        });
        await Purchases.setEmail({ email: user.email });
      }catch(e){
        this.logger.error('Failed to initPaymentPlugin', e);
      }
    }
  }
  async getCustomerInfo(){
    this.logger.info('Loading Customer Info');
    if(this.isAvailable()){
      try{
        var customerInfo =  await Purchases.getCustomerInfo();
        try{
          return mapCustomerInfoToSubscription(customerInfo);
        }catch(e){
          this.logger.error('Failed to parse getCustomerInfo', e);
          return null;
        }
      }catch(e){
        this.logger.error('Failed to getCustomerInfo', e);
      }
    }else{
      this.logger.warn('Payment plugin is not available so cannot getCustomerInfo');
    }
    return null;
  }
  async syncBilling(){
    this.logger.info('Syncing Billing Info');
    if(this.isAvailable()){
      try{
        await Purchases.syncPurchases();
      }catch(e){
        this.logger.error('Failed to syncBilling', e);
      }
    }else{
      this.logger.warn('Payment plugin is not available so cannot syncBilling');
    }
    return null;
  }
  async getOfferings(cb){
    if(this.isAvailable()){
      this.logger.info('Loading Offering');
      try{
        var result = await Purchases.getOfferings();
        var offerings = [];
        var offers = [];

        result.current.availablePackages.forEach(o => {

          offerings.push({
            id: o.identifier,
            rcOffering: o,
            period: o.product.subscriptionPeriod,
            price: o.product.priceString,
            currencyCode: o.product.currencyCode
          });

          if(o.product.subscriptionOptions){
            offers = [...offers, ...o.product.subscriptionOptions.filter(so => !so.isBasePlan)];
          }
        });

        cb({ offerings, offers });
      }
      catch(e){
        this.logger.error('Failed to getOfferings', e);
      }
    }else{
      this.logger.warn('Payment plugin is not available so cannot getOfferings');
    }
  }
  async purchasePackage(p, cb) {
    if(this.isAvailable()){
      this.logger.info(`Purchasing Package - ${p.identifier}`);

      try{
        var result = await Purchases.purchasePackage({ aPackage: p });

        this.logger.info('Purchasing Package Complete');
        cb(mapCustomerInfoToSubscription(result.customerInfo));
      }
      catch(e){
        this.logger.error('Failed to purchasePackage', e);
        cb();
      }
      
    }else{
      this.logger.warn('Payment plugin is not available so cannot purchasePackage');
      cb();
    }
  }
  async redeemOffer(discount, cb) {
    this.logger.info(`Redeeming Offer - ${discount.id}`);
    
    if(this.isAvailable()){
      try{
        if(Capacitor.getPlatform() == 'android'){
          this.logger.info(`Redeeming Offer android - ${discount.offerId}`);
          var result = await Purchases.purchaseSubscriptionOption({ subscriptionOption: discount.offer });
          cb({ subscription: result });
        }else{
          this.logger.info(`Redeeming Offer iOS - ${discount.promoCode}`);
          App.addListener('appStateChange', ({ isActive }) => {
            if(isActive){
              cb({ shouldPollForSubscription: true });
              App.removeAllListeners();
            }});
          
          await Browser.open({ url: `https://apps.apple.com/redeem?ctx=offercodes&id=1582299189&code=${discount.promoCode}`, presentationStyle: 'popover' });
        }
      }catch(e){
        this.logger.error('Failed to redeemOffer', e);
        cb();
      }
    }else{
      this.logger.warn('Payment plugin is not available so cannot redeemOffer');
      cb();
    }
  }
}
