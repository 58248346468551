import React from 'react';

import {
  Box
}from '@mui/material';

import { PostFeed } from '@components/organisms';

export default function Posts() {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <PostFeed showCreateCheckInFab={true} areQuickFilersEnabled={true}/>
    </Box>
  );
  
}