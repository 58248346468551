
import React from 'react';

export default function Chat(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path d="M14.0494 1.25C14.7142 1.24996 15.2871 1.24993 15.7458 1.31161C16.2374 1.37771 16.7087 1.52677 17.0909 1.90901C17.4732 2.29126 17.6222 2.76252 17.6883 3.25416C17.75 3.7129 17.75 4.28576 17.7499 4.95062V4.95063V6.04937V6.04938C17.75 6.71425 17.75 7.2871 17.6883 7.74585C17.6222 8.23748 17.4732 8.70875 17.0909 9.09099C16.7087 9.47324 16.2374 9.6223 15.7458 9.6884C15.287 9.75007 14.7142 9.75004 14.0493 9.75001L13.227 9.75L10.416 11.624C10.1858 11.7775 9.88992 11.7918 9.64605 11.6613C9.40218 11.5307 9.24994 11.2766 9.24994 11V9.7469C8.8765 9.74158 8.54271 9.7272 8.2541 9.6884C7.76246 9.6223 7.2912 9.47324 6.90895 9.09099C6.52671 8.70875 6.37765 8.23748 6.31155 7.74585C6.24987 7.28711 6.2499 6.71427 6.24994 6.04943V6.04938V4.95063V4.95058C6.2499 4.28574 6.24987 3.71289 6.31155 3.25416C6.37765 2.76252 6.52671 2.29126 6.90895 1.90901C7.2912 1.52677 7.76246 1.37771 8.2541 1.31161C8.71283 1.24993 9.28567 1.24996 9.95052 1.25H9.95057H14.0493H14.0494Z" 
        stroke={'none'}
        fill={props.fill} />
      <path d="M3.1726 18.1177C4.89965 16.9608 7.1003 16.9608 8.82735 18.1177C9.13741 18.3137 9.84341 18.7614 10.2142 19.1697C10.4495 19.4288 10.6975 19.7967 10.7428 20.2626C10.7914 20.7627 10.5934 21.2221 10.2316 21.6099C9.6845 22.1963 8.95289 22.75 7.95959 22.75H4.04036C3.04706 22.75 2.31546 22.1963 1.76834 21.6099C1.40653 21.2221 1.20855 20.7627 1.25714 20.2626C1.30241 19.7967 1.55046 19.4288 1.7858 19.1697C2.15655 18.7614 2.86254 18.3137 3.1726 18.1177Z" 
        stroke={'none'}
        fill={props.fill} />
      <path d="M15.1726 18.1177C16.8997 16.9608 19.1003 16.9608 20.8274 18.1177C21.1374 18.3137 21.8434 18.7614 22.2142 19.1697C22.4495 19.4288 22.6975 19.7967 22.7428 20.2626C22.7914 20.7627 22.5934 21.2221 22.2316 21.6099C21.6845 22.1963 20.9529 22.75 19.9596 22.75H16.0404C15.0471 22.75 14.3155 22.1963 13.7683 21.6099C13.4065 21.2221 13.2085 20.7627 13.2571 20.2626C13.3024 19.7967 13.5505 19.4288 13.7858 19.1697C14.1565 18.7614 14.8625 18.3137 15.1726 18.1177Z" 
        stroke={'none'}
        fill={props.fill} />
      <path d="M3.24997 13.5C3.24997 11.9812 4.48119 10.75 5.99997 10.75C7.51875 10.75 8.74997 11.9812 8.74997 13.5C8.74997 15.0188 7.51875 16.25 5.99997 16.25C4.48119 16.25 3.24997 15.0188 3.24997 13.5Z" 
        stroke={'none'}
        fill={props.fill} />
      <path d="M15.25 13.5C15.25 11.9812 16.4812 10.75 18 10.75C19.5188 10.75 20.75 11.9812 20.75 13.5C20.75 15.0188 19.5188 16.25 18 16.25C16.4812 16.25 15.25 15.0188 15.25 13.5Z" 
        stroke={'none'}
        fill={props.fill} />
    </svg>
  );
}