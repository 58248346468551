import React, { useState } from 'react';

import { 
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function PasswordTextField(props) {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const { value, error, touched, handleChange, inputProps } = props;

  return (
    <FormControl fullWidth size='small' variant="outlined">
      <InputLabel error={touched && Boolean(error)} >Password</InputLabel>
      <OutlinedInput
        inputProps={inputProps}
        label='Password'
        id='password'
        type={isPasswordShown ? 'text' : 'password'}
        onChange={handleChange}
        value={value}
        error={touched && Boolean(error)}
        endAdornment={
          <>
            {value !== '' &&
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsPasswordShown(!isPasswordShown)}
                  edge="end"
                >
                  {isPasswordShown ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          </>
        }
      />
      <FormHelperText 
        error={touched && Boolean(error)}>
        {touched && error}
      </FormHelperText>
    </FormControl>
  );
}