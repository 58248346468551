import React, { useContext } from 'react';

import { 
  Card, 
  Typography
} from '@mui/material';

import { ClientContext } from '../../client-context';
import { DiaryGraph as DiaryGraphComponent } from '@components/molecules';
import { EmptyState } from '@components/molecules';

export default function DiaryGraph(props) {
  const { profile, diaries, isLoadingDiaries } = useContext(ClientContext);

  return(
    <Card sx={{ width: '100%', height: '100%', p: 1 }}>
      <Typography color='text.secondary'>
        Diaries
      </Typography>
      {(!isLoadingDiaries && diaries.length > 0) &&
        <DiaryGraphComponent diaries={diaries} isLoading={isLoadingDiaries}/>
      }
      {(!isLoadingDiaries && diaries.length == 0) &&

        <EmptyState 
          size={100}
          fullWidth={true}
          iconType='inkwell'
          message={`Once ${profile?.name} creates a Diary Entry, their markers will be shown here.`}
        />
      }
      {isLoadingDiaries &&
        <EmptyState 
          size={100}
          fullWidth={true}
          iconType='inkwell'
          message={'Loading Diarys..'}
        />
      }
    </Card>
  );
}
