import React, { useState, useContext } from 'react';
import { 
  Box,
  Divider,
  Dialog
} from '@mui/material';

import { DialogHeader, DialogTransition } from '@components/atoms';
import TopicContent from './content';
import { UserContext, ServicesContext, EventsContext, AccountOnboardingContext } from '@context';
import { NewPostContext, Comments } from '@components/organisms';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';

export default function ViewTopic(props){
  const { topicBeingViewed, setTopicBeingViewed, backLocation } = props;
  var theme = useTheme();
  var history = useHistory();
  const { user } = useContext(UserContext);
  var { accountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  const { api } = useContext(ServicesContext);
  const { trackEvent } = useContext(EventsContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  const [isLoading, setIsLoading] = useState(false);

  const loadTopic = async () => {
    setIsLoading(true);
    var result = await api.get({ url: `community/topics/${topicBeingViewed?.id}`, user });
    setTopicBeingViewed(result);
    setIsLoading(false);
  };

  const openCommentDialog = async (comment) => {
    trackEvent('Community:Topic:Comment:Open', { topicId: topicBeingViewed.id });
    openNewPostDialog({ 
      title: 'New Topic', 
      placeholder: 'Your comment...', 
      buttonAction: async ({ message }) => {
        await api.post({ url: `community/topics/${topicBeingViewed.id}/comments`, user, data: { message, parentCommentId: comment?.id } });
        trackEvent('Community:Topic:Comment:Save', { topicId: topicBeingViewed.id });
        await loadTopic();

        if(!accountOnboarding?.hasContributedToCommunity){
          loadAccountOnboarding();
        }
      }, 
      buttonText: 'COMMENT',
      successToastMessage : 'Comment Created!',
      isImageEnabled: false
    });
  };

  const close = () => {
    if(backLocation){
      history.goBack();
    }else{
      setTopicBeingViewed(null);
    }
  };

  return (
    <Dialog
      open={topicBeingViewed?.id != null}
      fullScreen
      TransitionComponent={DialogTransition}
      sx={{ ...props.sx, marginTop: 'env(safe-area-inset-top)' }} >
      <Box sx={{
        backgroundColor: theme.palette.background.paper, marginBottom: '1px'
      }} >
        <DialogHeader close={close}/>
        <Divider light/>
        <Box sx={{ px: 1, paddingTop: 1,  backgroundColor: theme.palette.background.paper }}>
          <TopicContent isLoading={isLoading} topic={topicBeingViewed} openCommentDialog={openCommentDialog}/>
        </Box>
        <Comments 
          openCreateComment={openCommentDialog}
          parentUser={topicBeingViewed?.user}
          commentCount={topicBeingViewed?.commentCount}
          emptyStateMessage='No one has commented on this topic yet. Be the first to share your thoughts!'
          loadCommentsUrl={`community/topics/${topicBeingViewed?.id}/comments`}
        />
      </Box>
    </Dialog>
  );
}

