import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { 
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { ServicesContext } from '@context/services';
import { UserContext } from '@context/user';
import { ClientContext } from '../../client-context';

import { PostItNote } from '@components/atoms';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateNote(props) {
  const { isOpen, close } = props;
  const { id, loadNotes, profile, noteBeingEdited, setNoteBeingEdited } = useContext(ClientContext);
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { api } = useContext(ServicesContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setIsSaving(false);
    setMessage('');
  }, [isOpen]);

  useEffect(() => {
    if(noteBeingEdited){
      setMessage(noteBeingEdited.message);
    }
  }, [noteBeingEdited]);

  const handleClose = () => {
    setMessage('');
    close();
    setNoteBeingEdited();
  };

  const handleSave = async () => {
    setIsSaving(true);
    try{
      if(noteBeingEdited){
        await api.put({ url: `notes/${noteBeingEdited.id}`, data: { message }, user });
      }else{
        await api.post({ url: 'notes', data: { message, userId: id }, user });
      }

      loadNotes();
      enqueueSnackbar(`Successfully ${noteBeingEdited ? 'updated' : 'created'} note for ${profile.name}`, { 
        variant: 'success'
      });
      handleClose();
    }catch(e){
      enqueueSnackbar(`Failed to ${noteBeingEdited ? 'update' : 'create'} note`, { 
        variant: 'error'
      });
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    try{
      setIsSaving(true);
      await api.delete({ url: `notes/${noteBeingEdited.id}`, user });
      loadNotes();
      enqueueSnackbar(`Successfully delete note for ${profile.name}`, { 
        variant: 'success'
      });
      handleClose();
    }catch(e){
      enqueueSnackbar('Failed to delete note', { 
        variant: 'error'
      });
      setIsSaving(false);
    }
  };

  return(
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth='sm'
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'User Note'}</DialogTitle>
        <DialogContent
          sx={{
            '.ql-toolbar':{
              border: 'none'
            }
          }}>
          <PostItNote>
            <ReactQuill 
              modules={{
                toolbar:
                  [
                    ['bold', 'italic', 'underline'], 
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }]
                  ] 
              }}
              theme="snow"
              value={message}
              onChange={setMessage} />
          </PostItNote>
        </DialogContent>
        <DialogActions>
          {noteBeingEdited &&
            <LoadingButton 
              loading={isSaving}
              color='error'
              onClick={handleDelete}>
                Delete
            </LoadingButton>
          }
          <Button variant='outlined'  disabled={isSaving} onClick={handleClose}>
            {(!noteBeingEdited || noteBeingEdited.message != message) ? 'CANCEL' : 'CLOSE'}
          </Button>
          {(!noteBeingEdited || noteBeingEdited.message != message) &&
            <LoadingButton 
              loading={isSaving}
              variant='contained'
              onClick={handleSave}
              disabled={message == ''}>
                Save
            </LoadingButton>
          }
        </DialogActions>
      </Dialog>
    </>
  );
}
