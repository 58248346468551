import React, { useContext } from 'react';

import {
  Card,
  Box,
  Button,
  Typography,
  Divider,
  IconButton,
  FormControlLabel,
  Checkbox
}from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { NewPlanContext } from '../../context';

import ExerciseSets from './exercise-sets';

import ExerciseInfoDialog from '@components/molecules/exercise-info-dialog';
import { titleCase } from '@helpers/text';
import { TextField } from '@components/atoms';

export default function ExerciseDetails(props) {
  const { exercise } = props;
  const { addSet, removeSelectedExercise, toggleIsEachSide, setExerciseNotes } = useContext(NewPlanContext);

  return (
    <Card variant='outlined' sx={{ my: 1 }}>
      <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h6' data-testid={`recovery-new-plan-exercises-details-${exercise.id}`}>
          {titleCase(exercise.name)}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <ExerciseInfoDialog exercise={exercise}/>
          <IconButton sx={{ p: 0.5 }} onClick={() => removeSelectedExercise(exercise)}><CloseIcon fontSize='small' /></IconButton>
        </Box>
      </Box>
      <Divider />
      <ExerciseSets exercise={exercise}/>
      
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', p: 1 }}>
        <Button onClick={() => addSet(exercise.id)} variant='outlined' size='small'>
            Add Set
        </Button>
        <FormControlLabel size='small'
          control={<Checkbox 
            sx={{ py: 0.5 }}
            checked={exercise.isEachSide == true}
            onClick={() => toggleIsEachSide(exercise.id)}
            size='small'
          />}
          label="Each Side" />
      </Box>
      <Box sx={{ p: 1 }}>
        <TextField 
          inputProps={{ 'data-testid':`new-plan-dialog-exercise-notes-${exercise.id}` }}
          autoComplete='off'
          size='small'
          label='Exercise Notes'
          placeholder='Exercise Notes'
          fullWidth
          multiline
          value={exercise.notes ?? ''}
          onChange={(e) => setExerciseNotes(exercise.id, e.target.value)}/>
      </Box>
    </Card>
  );
}

