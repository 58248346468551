import $ from 'jquery';
import React, { useRef, useState } from 'react';

import {
  Box
} from '@mui/material';

import ImageCropper from './image-cropper';

export default function ImageUploader(props) {
  const [cropperOpen, setCropperOpen] = useState(false);
  const [uncroppedImage, setUncroppedImage] = useState(null);
  const inputRef = useRef(null);

  const initCropper = (image) => {
    const reader = new FileReader();
    reader.addEventListener('load', function () {
      var image = new Image();
      image.onload = function (imageEvent) {

        // Resize the image
        var canvas = document.createElement('canvas'),
          max_size = 400,// TODO : pull max size from a site config
          width = image.width,
          height = image.height;

        if (width > height) {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        } else {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
              
        setUncroppedImage(canvas.toDataURL('image/jpeg'));
        setCropperOpen(true);
      };
      image.src = reader.result;

    }, false);

    reader.readAsDataURL(image.target.files[0]);
  }; 

  const saveImage = (image) => {
    setCropperOpen(false);
    props.saveImage(image);
    setUncroppedImage(null);
    $('#icon-button-file').val(null);
  };

  const getHtmlId = () => {
    var id = 'icon-button-file';

    if(props.imageId){
      id += '-' + props.imageId;
    }

    return id;
  };

  const buttonElement = props.button ? React.cloneElement(props.button, {
    onClick: () => {inputRef.current.click();}
  }) : null;

  return (
    <Box>
      {props.isEnabled &&
        <>
          <Box sx={{ display: 'none' }}>
            <input 
              ref={inputRef}
              accept="image/*"
              id={getHtmlId()}
              type="file"
              onChange={initCropper} />
          </Box>
          <ImageCropper 
            open={cropperOpen} 
            inputImage={uncroppedImage}
            saveImage={(saveImage)}
            close={() => {setCropperOpen(false);}}
          />
        </>
      }
      {buttonElement}
      {!props.button &&
        <Box sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
          
          <label htmlFor={getHtmlId()}>
            {props.children}
          </label>
        </Box>
      }
    </Box>
  );
}