export function titleCase(str) {
  if(!str){
    return '';
  }

  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  // Directly return the joined string
  return splitStr.join(' '); 
}

export function moodToText(mood) {
  switch(mood){
  case 0:
    return 'OK';
  case 1:
    return 'Frustrated';
  case 2:
    return 'Tired';
  case 3:
    return 'OK';
  case 4:
    return 'Content';
  case 5:
    return 'Delighted';
  }
}

export function firstName(name){
  return name?.split(' ')[0] ?? 'Guest';
}

export function billingPeriodToNumberOfMonths(period){
  switch(period){
  case 'P1Y':
    return '12 Months';
  case 'P6M':
    return '6 Months';
  case 'P1M':
  default:
    return '1 Month';
  }
}

export function billingPeriodToFrequency(period){
  switch(period){
  case 'P1Y':
    return 'annually';
  case 'P6M':
    return 'every 6 months';
  case 'P1M':
  default:
    return 'monthly';
  }
}

export function billingPeriodToPlanTitle(period){
  switch(period){
  case 'P1Y':
    return 'Annual';
  case 'P6M':
    return '6 Months';
  case 'P1M':
  default:
    return 'Monthly';
  }
}

export function optionalPlural(baseString, count){
  return `${baseString}${count > 1 ? 's' : ''}`;
}

export function isNullOrEmptyOrBlack(s){
  return !s || s.trim() == '';
}