import React, { useContext, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import PaywallPage from './components/page';
import { Box } from './../../v2/atoms';
import { UserContext, ServicesContext, EventsContext, BillingContext } from '@context';
import { LoadingButton } from '@mui/lab';
import Image from 'mui-image';
import { useHistory } from 'react-router-dom';
import BulletPoint from './components/bullet-point';
import { titleCase } from '@helpers/text';
import { AppContext } from '@context/app';

export default function AccessCode(props) {
  var { code, clearCode } = props;
  const theme = useTheme();
  const history = useHistory();
  const { getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { trackEvent } = useContext(EventsContext);
  const { isConsumingAccessCode, consumeAccessCode } = useContext(BillingContext);
  const { clearAccessCode } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [accessCode, setAccessCode] = useState(null);

  const isInvalid = !isLoading && (accessCode == null || accessCode?.isConsumed);

  const handleClick = async () => {
    if(!isInvalid){
      await consumeAccessCode(accessCode);
      clearAccessCode();
      history.push('/');
    }else{
      clearCode();
    }

  };

  const loadAccessCode = async () => {
    setIsLoading(true);
    try{
      var result = await api.get({ url: `billing/accesscodes/${code}`, getUserToken });
      setAccessCode(result);
    }catch(err){
      console.error(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if(code){
      trackEvent(`Payments:Paywall:AccessCode:Open:${code}`);
      loadAccessCode();
    }
  }, [code]);

  const getImageUrl = () => {
    if(accessCode?.source == 'HEKA'){
      return 'https://bracesocial-prod-cdn.azureedge.net/static/heka.png';
    }else{
      return 'https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_Dark%20and%20grad.png';
    }
    
  };

  return (
    <PaywallPage backAction={isInvalid ? clearCode : null}>
      <Box fullHeight flexColumn>
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              flex
              center='h'
              px={3}
              py={4}
            >
              <Box width='50%' >
                <Image 
                  width='100%' 
                  duration={500} 
                  src={getImageUrl()}/>
              </Box>
      
            </Box>
            {!isInvalid &&
            <Box>
              <Typography variant='h4'
                textAlign='center'
                px={3}
                paddingBottom={3}
                sx={{ fontWeight: 600 }}
                color={theme.palette.braceGreenDark.main}>
                  Welcome to Brace! Thank you for subscribing.
              </Typography>
              <Typography variant='body1'
                textAlign='center'
                px={3}
                paddingBottom={3}
                sx={{ fontWeight: 'medium' }}
                color={theme.palette.braceGreenDark.main}>
                Our goal is to empower your recovery so you can come back stronger.
              </Typography>
              <Typography variant='body1'
                px={3}
                paddingBottom={1}
                sx={{ fontWeight: 'medium' }}
                color={theme.palette.braceGreenDark.main}>
                With Brace you can:
              </Typography>
              <Box  px={3}>
                <BulletPoint text='Prioritise Your Health & Wellbeing'/>
                <BulletPoint text='Stay On Track With Your Recovery Plan'/>
                <BulletPoint text='Access Community Insights & Motivation'/>
                <BulletPoint text='Build Your Knowledge on the Recovery Journey'/>
              </Box>
            </Box>
            }
            {isInvalid &&
            <Box>
              <Typography variant='h4'
                textAlign='center'
                px={3}
                paddingBottom={3}
                sx={{ fontWeight: 600 }}
                color={theme.palette.braceGreenDark.main}>
                 Unique Link Invalid
              </Typography>
              <Typography variant='body1'
                textAlign='center'
                px={3}
                paddingBottom={3}
                sx={{ fontWeight: 'medium' }}
                color={theme.palette.braceGreenDark.main}>
                The link you have used is invalid. This could be due to it having already been used. 
              </Typography>
              {accessCode &&
                <Typography variant='body1'
                  px={3}
                  textAlign='center'
                  paddingBottom={3}
                  sx={{ fontWeight: 'medium' }}
                  color={theme.palette.braceGreenDark.main}>
                  {`Please reach out to ${titleCase(accessCode.source)} to ensure it was the correct link.`}
                </Typography>
              }
              <Typography variant='body1'
                px={3}
                textAlign='center'
                paddingBottom={3}
                sx={{ fontWeight: 'medium' }}
                color={theme.palette.braceGreenDark.main}>
                If you are still experiencing issues reach out to support@bracesocial.com
              </Typography>
            </Box>
            }
          </Box>
          <Box sx={{ width: '100%', p: 3, paddingBottom: 4 }}>
            <LoadingButton 
              sx={{ marginBottom: 1, py: 1, px: 4, fontSize: 'h6.fontSize' }}
              disabled={isLoading}
              loading={isConsumingAccessCode}
              size='large'
              fullWidth
              variant='contained'
              color='braceGreenDark'
              data-testid='paywall-start-with-access-code'
              onClick={handleClick}
            >
              {isInvalid ? 'Back' : 'Continue To Brace' }
            </LoadingButton>
          </Box>
        </>
      </Box>
    </PaywallPage>
  );
}