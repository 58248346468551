import React from 'react';

export default function Learn (props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2668 3.16517C16.088 2.3837 18.7538 1.8328 22.1771 2.66483C22.5133 2.74654 22.75 3.04763 22.75 3.39362V17.8936C22.75 18.1237 22.6444 18.3411 22.4635 18.4833C22.2826 18.6254 22.0465 18.6767 21.8229 18.6224C18.7462 17.8746 16.412 18.3769 14.8582 19.0436C14.077 19.3789 13.4873 19.7584 13.0972 20.0497C12.9541 20.1566 12.8382 20.2513 12.75 20.3272L12.75 3.96721C13.1598 3.70452 13.6661 3.42289 14.2668 3.16517ZM11.25 3.96722L11.25 20.3272C11.1618 20.2513 11.0459 20.1566 10.9028 20.0497C10.5127 19.7584 9.92301 19.3789 9.14176 19.0436C7.588 18.3769 5.25379 17.8746 2.17713 18.6224C1.95355 18.6767 1.7174 18.6254 1.53651 18.4833C1.35561 18.3411 1.25 18.1237 1.25 17.8936V3.39362C1.25 3.04763 1.48667 2.74654 1.82287 2.66483C5.24621 1.8328 7.912 2.3837 9.73324 3.16517C10.3339 3.42289 10.8402 3.70453 11.25 3.96722ZM5 6.25098C4.58579 6.25098 4.25 6.58676 4.25 7.00098C4.25 7.41519 4.58579 7.75098 5 7.75098C6.82506 7.75098 7.70239 8.18965 8.65627 8.6666L8.66459 8.67076C9.03507 8.856 9.48558 8.70583 9.67082 8.33535C9.85606 7.96486 9.70589 7.51436 9.33541 7.32912C8.29252 6.80767 7.16964 6.25098 5 6.25098ZM5 10.2499C4.58579 10.2499 4.25 10.5857 4.25 10.9999C4.25 11.4142 4.58579 11.7499 5 11.7499C6.82506 11.7499 7.70239 12.1886 8.65627 12.6656L8.66459 12.6697C9.03507 12.855 9.48558 12.7048 9.67082 12.3343C9.85606 11.9638 9.70589 11.5133 9.33541 11.3281C8.29252 10.8066 7.16964 10.2499 5 10.2499Z"
        fill={props.fill}
        stroke={props.stroke}  />
    </svg>
  );
}