import React, { useContext } from 'react';

import {
  Box,
  Button,
  Stack
}from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@emotion/react';
import { NewPlanContext } from '../context';

export default function Buttons(props) {
  const { next, nextText, isNextDisabled, isNextLoading, back, backText, allowSaveDraft } = props;
  const { savePlan, isDraft } = useContext(NewPlanContext);
  const theme = useTheme();
  var activeButtons = 0;
  if(next){
    activeButtons += 1;
  }
  if(back){
    activeButtons += 1;
  }

  return (
    <Box sx={{ 
      position: 'absolute', 
      bottom: 'calc(env(safe-area-inset-bottom))', 
      paddingBottom: 1,
      width: '100%',
      zIndex: 1000 }}>
     
      <Stack direction='row' spacing={1} sx={{ width: '100%', justifyContent: 'center' }}>
       
        {back && 
        <Button 
          size="large"
          sx={{ width: activeButtons == 1 ? '50%' : '40%', backgroundColor: '#FAFAFA' }}
          onClick={back}
          color='primary'
          variant='outlined'>
          {backText ?? 'Back'}
        </Button>
        }
        {next && 
        <LoadingButton 
          size="large"
          data-testid='recovery-new-plan-next-button'
          disabled={isNextDisabled}
          loading={isNextLoading}
          sx={{ width: activeButtons == 1 ? '50%' : '40%' }}
          onClick={next}
          color='primary'
          variant='contained'>
          {nextText ?? 'Next'}
        </LoadingButton>
        }
       
      </Stack>
      {allowSaveDraft && 
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button 
              size="large"
              onClick={() => savePlan({ isDraft: true })}
              color='primary'>
              {isDraft ? 'Update Draft' : 'Save as Draft'}
            </Button>
          </Box>
      }
    </Box>
  );
}

