import React, { useState, useContext, useEffect } from 'react';

import {
  useHistory
} from 'react-router';

import { 
  Box,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  AvatarGroup,
  IconButton,
  Button
} from '@mui/material';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { UserContext, ServicesContext, EventsContext } from '@context';
import config from '@config';

import { Page } from '../../components/organisms';
import { EmptyState } from '@components/molecules';
import { LoadableList, LoadableText, Avatar, TextField } from '@components/atoms';
import CreateGroup from './create-group';
import { toShortDateString } from '@helpers/dates';
import { useTheme } from '@emotion/react';

export default function Groups() {
  const theme = useTheme();
  const { api } = useContext(ServicesContext);
  const { getUserToken, activeOrganisation } = useContext(UserContext);
  const history = useHistory();
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isCreateGroupDialogOpen, setIsCreateGroupDialogOpen] = useState(false);
  const { trackEvent } = useContext(EventsContext);

  const loadTeams = () => {
    setIsLoading(true);
    api.get({ url: `organisations/${activeOrganisation?.id}/teams`, getUserToken })
      .then((result) => {
        setGroups(result.results);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if(activeOrganisation?.id){
      loadTeams();
      trackEvent('Groups:Open');
    }
    
  }, []);

  const GroupTableRow = (props) => {
    var { group } = props;
    return (
      <TableRow
        data-testid={`groups-row-${group?.id}`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'  }}
        onClick={() => history.push(`/group/${group.id}`)}
      >
        <TableCell >
          <LoadableText isLoading={isLoading} text={group.name}/>
        </TableCell>
        <TableCell >
          <AvatarGroup max={6} sx={{ flexDirection: 'row' }}>
            {group?.accounts?.filter(ga => ga.account.role == 'CLIENT').map((ga =>  
              <Avatar 
                showTooltip={true}
                key={ga.id}
                iconSize={14}
                size={20}
                isLoading={isLoading}
                src={`${config.contentBaseUrl}images/${ga.account?.imageLocation}`}
                name={ga.account.name} />))}
           
          </AvatarGroup>
        </TableCell>
        <TableCell >
          <AvatarGroup max={5} sx={{ flexDirection: 'row' }}>
            {group?.accounts?.filter(ga => ga.account.role != 'CLIENT').map((ga =>  
              <Avatar 
                showTooltip={true}
                key={ga.id}
                iconSize={14}
                size={20}
                isLoading={isLoading}
                src={`${config.contentBaseUrl}images/${ga.account?.imageLocation}`}
                name={ga.account.name} />))}
           
          </AvatarGroup>
        </TableCell>
        <TableCell >
          <LoadableText isLoading={isLoading} text={toShortDateString(group.createdAt)}/>
        </TableCell>
        <TableCell component="th" scope="row" align='right'>
          <IconButton>
            <KeyboardArrowRightIcon size='small'/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return(
    <Page>
      <Box sx={{ p: 1, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography data-testid='groups-page-header' sx={{ fontWeight: 'medium', fontSize: 'h5.fontSize' }}>
          Groups
        </Typography>
      </Box>
      {groups.length > 0 &&
      <Box sx={{ px: 1, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
        <TextField label='Search By Name'
          sx={{ backgroundColor: theme.palette.background.paper }}
          size='small'
          value={searchText ?? ''}
          onChange={(e) => setSearchText(e.target.value)}/>
        <Button 
          data-testid= 'groups-create-group-button'
          onClick={() => setIsCreateGroupDialogOpen(true)}
          variant='contained'
          size='small'
          color='primary'>
            Create New Group
        </Button>
      </Box>
      }
      {(isLoading || groups.length > 0) && 
      <Box sx={{ overflow: 'auto', p: 1 }}>
        <Paper sx={{ width: '100%' }}>
          <TableContainer>
            <Table aria-label="simple table" size="small" >
              <TableHead>
                <TableRow >
                  <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Name</TableCell>
                  <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Members</TableCell>
                  <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Practitioners</TableCell>
                  <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Created</TableCell>
                  <TableCell sx={{ color: 'text.secondary', py: 0.5 }} ></TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                <LoadableList 
                  isLoading={isLoading}
                  rows={groups.filter(g => searchText == '' || g?.name?.toLowerCase().includes(searchText.toLowerCase()))}
                  getRow={(group) => {return (<GroupTableRow group={group} key={group.id}/>);} }
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      }
      {(!isLoading && groups.length === 0) && 
      <Box p={1}>
        <EmptyState 
          title='Create A Group'
          message='Create private groups for you and your clients to interact in.'
          buttonAction={() => {setIsCreateGroupDialogOpen(true);}}
          buttonText='Create A Group'
          iconType='organization'
          buttonDataTestId='groups-create-group-button'
        /> 
      </Box>
      }
      <CreateGroup isOpen={isCreateGroupDialogOpen}
        close={(shouldRefresh) => {
          setIsCreateGroupDialogOpen(false);
          if(shouldRefresh){
            loadTeams();
          }
        }}/>
    </Page>
  );
}
