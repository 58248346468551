import React, { createContext, useContext, useState, useEffect } from 'react';

import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';

export const ClientContext = createContext();

export function ClientProvider(props){
  const { id } = props;
  const services = useContext(ServicesContext);
  const { user } = useContext(UserContext);
  const [profile, setProfile] = useState(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [goals, setGoals] = useState([]);
  const [isLoadingGoals, setIsLoadingGoals] = useState(false);
  const [diaries, setDiaries] = useState([]);
  const [plans, setPlans] = useState([]);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [isLoadingDiaries, setIsLoadingDiaries] = useState(false);
  const [notes, setNotes] = useState([]);
  const [noteBeingEdited, setNoteBeingEdited] = useState(null);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);
  const [activeAction, setActiveAction] = useState(null);

  async function loadProfile(){
    setIsLoadingProfile(true);
    setProfile(null);
    services.api.get({ url: `profiles/${id}`, user })
      .then((p) => {
        setProfile(p);
        setIsLoadingProfile(false);
      });
  }

  async function loadGoals(){
    setIsLoadingGoals(true);
    setGoals([]);
    services.api.get({ url:'goals', params: { userId: id }, user })
      .then((result) => {
        setIsLoadingGoals(false);
        setGoals(result.results);
      });
  }

  async function loadPlans(){
    setIsLoadingPlans(true);
    setPlans([]);
    services.api.get({ url:'recovery/plans', params: { userId: id }, user })
      .then((result) => {
        setIsLoadingPlans(false);
        setPlans(result.results);
      });
  }

  async function loadNotes(){
    setIsLoadingNotes(true);
    setNotes([]);
    services.api.get({ url:'notes', params: { userId: id }, user })
      .then((result) => {
        setIsLoadingNotes(false);
        setNotes(result.results);
      });
  }

  async function loadDiaries(){
    setIsLoadingDiaries(true);
    setDiaries([]);
    services.api.get({ url: `community/diary/user/${id}`, user })
      .then(result => {
        setIsLoadingDiaries(false);
        setDiaries(result.results);
      });
  }

  useEffect(() => {
    loadProfile();
    loadGoals();
    loadDiaries();
    loadNotes();
    loadPlans();
  }, [id]);

  return (
    <ClientContext.Provider value={{ 
      id,
      profile,
      isLoadingProfile,
      goals,
      isLoadingGoals,
      loadGoals,
      diaries,
      plans,
      isLoadingPlans,
      loadPlans,
      isLoadingDiaries,
      notes,
      loadNotes,
      noteBeingEdited, 
      setNoteBeingEdited,
      isLoadingNotes,
      activeAction, 
      setActiveAction
    }}>
      {props.children}
    </ClientContext.Provider>
  );
}