import React from 'react';

import { 
  Box,
  Skeleton
} from '@mui/material';

import Linkify from 'react-linkify';
import { emojify } from '../../../src/utils/emojii';

export default function LoadableText(props) {
  var { text, isLoading, minWidth, shouldNotLinkify, dataTestId, sx } = props;
  return (
    <Box data-testid={dataTestId} sx={sx}>
      {isLoading && 
      <Box sx={{ minWidth: minWidth ?? 0 }}>
        <Skeleton />
      </Box> 
      }
      {(!isLoading && text != null && !shouldNotLinkify) &&
      <Linkify properties={{ target: '_blank' }}>
        {emojify(text)}
      </Linkify>
      }
      {(!isLoading && text != null && shouldNotLinkify) &&
        <>
          {emojify(text)}
        </>
      }
    </Box>
  );
}