import React, { useContext, useState } from 'react';

import { 
  Box,
  InputBase
} from '@mui/material';
import { Search } from '@material-ui/icons';

import { CommunityFilterContext } from './context';
import CommunityFiltersDialog from './filters-dialog';
import { Icon, IconButton } from '@components/v2/atoms';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';

export default function CommunityFilters(){
  const theme = useTheme();
  const { isSearchEnabled, handleSearchTermChange, setIsFiltersDialogOpen } = useContext(CommunityFilterContext);
  const [value, setValue] = useState('');
  const [valueChangeTimeout, setValueChangeTimeout] = useState('');

  if(!isSearchEnabled){
    return <></>;
  }

  const handleChange = (newValue) => {
    setValue(newValue);
    if(valueChangeTimeout){
      clearTimeout(valueChangeTimeout);
    }
    setValueChangeTimeout(setTimeout(() => {
      handleSearchTermChange(newValue);
    }, 500));
    
  };

  return (
    <Box>
      <Box sx={{ 
        flexGrow: 1,
        borderRadius: 10, 
        backgroundColor: '#eee',
        display:'flex', 
        alignItems: 'center',
        height: '36px',
        px: 1
      }}>
        <Icon 
          size={20}
          type='search' 
          fill={grey[500]}
          stroke={grey[500]}
        />
        <InputBase
          disabled={!isSearchEnabled}
          sx={{ color: 'text.primary', flexGrow: 1, px: 1, borderRadius: 10 }}
          placeholder="Quick Search"
          value={value}
          onChange={(event) => handleChange(event.target.value)} />
        <IconButton  onClick={() => setIsFiltersDialogOpen(true)}>
          <Icon 
            size={20}
            type='filter' 
            fill={grey[500]}
            stroke={grey[500]}
          />
        </IconButton>
       
      </Box>
      <CommunityFiltersDialog />
    </Box>
    
  );
}