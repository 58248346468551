import React, { useContext, memo } from 'react';

import { UserContext } from '@context';
import { 
  Dialog, 
  Box, 
  Slide } from '@mui/material';

import { DialogHeader } from '@components/atoms';

import config from '@config';

var IFrame;

if(config.isHubspotChatEnabled){
  IFrame = memo(function IFrame({ user }) {
    return  (
      <iframe 
        style={{ border: 0, height:'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 50px)', width:'100vw' }}
        src={`https://support-bot.bracesocial.com?email=${user?.email}`}>
      </iframe>
    );
  }, (p1, p2) => true);
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FeedbackDialog(props){
  const { user } = useContext(UserContext);
  const { isOpen, close } = props;

  return (
    <Dialog keepMounted
      fullScreen
      open={isOpen}
      TransitionComponent={Transition}>
      <Box sx={{  height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))', marginTop: 'env(safe-area-inset-top)' }}>
        <DialogHeader close={close}/>
        {(config.isHubspotChatEnabled && user?.email && user?.status != 'PENDING') &&
          <IFrame user={user}/>
        }
      </Box>
    </Dialog>
  );
}
