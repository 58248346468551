import React, { createContext, useContext, useEffect  } from 'react';
import { UserContext } from './user';
import Statsig from 'statsig-js';
import config from '@config';
export const FeaturesContext = createContext({});

export function FeaturesProvider(props){
  var { user } = useContext(UserContext);

  const isFeatureEnabled = (name) => {
    return Statsig.checkGate(name);
  };

  useEffect(() => {
    Statsig.initialize(
      config.statsigKey,
      { },
      { environment: { tier: config.environment } }
    );
  }, []);

  useEffect(() => {
    if(user?.id){
      Statsig.updateUser(
        { userID: user?.id }
      );
    }
  }, [user]);

  return (
    <FeaturesContext.Provider value={{
      isFeatureEnabled
    }}>
      {props.children}
    </FeaturesContext.Provider>
  );
}