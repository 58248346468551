import React, { useContext, useEffect, useState } from 'react';

import { 
  Box, 
  Card, 
  Typography,
  Stack
} from '@mui/material';

import { ClientContext } from '../../../client-context';
import { ServicesContext } from '@context/services';
import { UserContext } from '@context/user';
import PlanCard from './plan-card';
import { EmptyState } from '@components/molecules';
import { ACTION_TYPES } from './../../actions/index';

export default function Plans() {
  const { id, profile, setActiveAction, plans, isLoadingPlans: isLoading, loadPlans } = useContext(ClientContext);
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);

  return(
    <Card sx={{ width: '100%', maxWidth: '100%', p: 1 }}>
      <Typography color='text.secondary'>
        Exercise Plans
      </Typography>
      <Box sx={{ width: '100%', maxWidth: '100%' }}>
        <Stack spacing={1} direction='row' sx={{ overflowX: 'auto' }}>
          {!isLoading && plans.map((p => (
            <PlanCard plan={p}
              key={p.id}
              isLoading={false}
              refresh={loadPlans} />
          )))}
          {(!isLoading && plans.length == 0 ) &&
            <EmptyState 
              size={100}
              iconType='to-do-list'
              message={`Exercise plans that ${profile?.name} creates or than you assign to them will be shown here.`}
              buttonText={'ASSIGN PLAN'}
              buttonAction={() => {setActiveAction(ACTION_TYPES.ASSIGN_PLAN);}}
            />
          }
          {isLoading && [1, 2, 3, 4, 5, 6].map((index => (
            <PlanCard key={index} isLoading={true} />
          )))}
        </Stack>
      </Box>
      
    </Card>
  );
}
