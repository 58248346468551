import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { Auth0Provider } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';

import { 
  IconButton } 
  from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { UserProvider } from '@context/user';
import { EventsProvider } from '@context/events';
import { ServicesProvider } from '@context/services';
import { FeaturesProvider } from '@context/features';
import { AccountOnboardingProvider } from '@context/account-onboarding';

import config from '@config';

import ToggleColorMode from '@context/theme';
import { NewPostProvider } from '@components/organisms';

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <ToggleColorMode>
    <SnackbarProvider 
      ref={notistackRef} 
      autoHideDuration={3000}      
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon sx={{ color: '#fff' }}/>
        </IconButton>
      )}>
      <ServicesProvider config={config}>
        <Auth0Provider
          cacheLocation='localstorage'
          domain={config.auth0Domain}
          clientId={config.auth0ClientId}
          authorizationParams={{
            audience: config.auth0Audience,
            redirect_uri: window.location.origin
          }}>
          <UserProvider>
            <FeaturesProvider>
              <EventsProvider config={config}>
                <AccountOnboardingProvider>
                  <NewPostProvider>
                    <App />
                  </NewPostProvider>
                </AccountOnboardingProvider>
              </EventsProvider>
            </FeaturesProvider>
          </UserProvider>
        </Auth0Provider>
      </ServicesProvider>
    </SnackbarProvider>
  </ToggleColorMode>,
  document.getElementById('app')
);