import React, { useContext } from 'react';

import { 
  Skeleton,
  Avatar,
  Typography
} from '@mui/material';

import { LikeAndCommentButtons } from '@components/molecules';

import { getFromNow } from '@helpers/dates';

import { LoadableText } from '@components/atoms';

import config from '@config';
import { useTheme } from '@emotion/react';
import { NewPostContext } from '@components/organisms';
import { Card, CategoryChip, Box, Dot, Divider } from '@components/v2/atoms';
import { EventsContext, ServicesContext, UserContext } from '@context';
import { toShortDateString } from '@helpers/dates';

export default function Topic(props) {
  const IMAGE_SIZE = 54;
  const { topic, isLoading, handleComment, setTopicBeingViewed } = props;
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  const { trackEvent } = useContext(EventsContext);

  const likeTopic = async () => {
    if(!topic.likedByCurrentUser){
      trackEvent('Community:Topic:Like', { topicId: topic.id });
      await api.put({ url: `community/topics/${topic.id}/like`, user });
    }
  };

  const openCommentDialog = async () => {
    trackEvent('Community:Topic:Comment:Create:Open');
    openNewPostDialog({ 
      title: 'New Topic', 
      isImageEnabled: false,
      placeholder: 'Your comment...', 
      buttonAction: async ({ message }) => {
        await api.post({ url: `community/topics/${topic.id}/comments`, user, data: { message } });
        trackEvent('Community:Topic:Comment:Create:Save');
        handleComment(topic.id);
      }, 
      buttonText: 'COMMENT',
      successToastMessage : 'Comment Created!'
    });
  };

  return (
    <Box sx={{ px: 2, py: '6px' }}>
      <Card sx={{ p: 0 }}>
        <Box onClick={() => setTopicBeingViewed(topic)} sx={{ p : 1.5, paddingBottom: 0.5, color: 'text.primary', fontWeight: 'medium', width: '100%' }}>
          <Typography component='div' color='text.primary' fontWeight='medium'>
            <LoadableText text={topic?.name} isLoading={isLoading} />
          </Typography>
          <Box flex
            fullWidth
            center='vertical'
            sx={{ lineHeight: 1 }}>
            <Typography component='div' variant='caption' color='text.disabled'>
              <LoadableText minWidth={75} text={`by ${topic?.user?.name}`} isLoading={isLoading} />
            </Typography>
            <Dot sx={{ mx: 1 }}/>
            <Typography component='div' variant='caption'  color='text.disabled'>
              <LoadableText minWidth={50} text={toShortDateString(topic.createdAt)} isLoading={isLoading} />
            </Typography>
          </Box>
        </Box>
        <Box onClick={() => setTopicBeingViewed(topic)}
          flex
          fullWidth
          sx={{ px : 1.5, paddingBottom: 1, color: 'text.primary' }}>
          {isLoading ? (
            <Box sx={{ paddingRight: 1 }}>
              <Skeleton variant="rectangular">
                <Avatar sx={{ width: IMAGE_SIZE, height: IMAGE_SIZE, borderRadius: 1 }}/>
              </Skeleton>
            </Box>
          ) : (
            <Box>
              {topic.imageUrl &&
                <Box sx={{ paddingRight: 1, paddingTop: 0.5 }}>
                  <Avatar 
                    src={`${config.contentBaseUrl}images/${topic.imageUrl}`}
                    sx={{ width: IMAGE_SIZE, height: IMAGE_SIZE, borderRadius: 1 }}
                    imgProps={{ loading: 'lazy', decoding:'async' }} >
                  </Avatar>
                </Box>
              }
            </Box>
          )}
          <Box fullWidth
            sx={{ 
              typography: 'body2', 
              color: 'text.primary', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              display: '-webkit-box', 
              WebkitLineClamp: '3', 
              WebkitBoxOrient: 'vertical' 
            }}>
            <LoadableText text={topic.description}
              isLoading={isLoading}
              minWidth={100}/>
          </Box>
        </Box>
        <Box onClick={() => setTopicBeingViewed(topic)} sx={{ px : 1.5, paddingBottom: 1 }}>
          {topic?.categories?.map(c => (
            <CategoryChip 
              sx={{ m: 0.25 }}
              size='small'
              key={c.id}
              category={c}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ px: 2, py: 0.5 }}>
          <LikeAndCommentButtons 
            isUpVote
            isLoading={isLoading}
            data={{ likeCount:topic.likeCount, commentCount:topic.commentCount, likedByCurrentUser: topic.likedByCurrentUser }}
            likeAction = {likeTopic}
            commentAction = {() => openCommentDialog()}
          />
        </Box>
        {/* <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => setTopicBeingViewed(topic)} >
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex' }}>
                {isLoading ? (
                  <Box sx={{ paddingRight: 1 }}>
                    <Skeleton variant="rectangular">
                      <Avatar sx={{ width: IMAGE_SIZE, height: IMAGE_SIZE, borderRadius: 1 }}/>
                    </Skeleton>
                  </Box>
                ) : (
                  <Box>
                    {topic.imageUrl &&
                    <Box sx={{ paddingRight: 1, paddingTop: 0.5 }}>
                      <Avatar 
                        src={`${config.contentBaseUrl}images/${topic.imageUrl}`}
                        sx={{ width: IMAGE_SIZE, height: IMAGE_SIZE, borderRadius: 1 }}
                        imgProps={{ loading: 'lazy', decoding:'async' }} >
                      </Avatar>
                    </Box>
                    }
                  </Box>
                )}
                <Box>
                  <Box sx={{ 
                    typography: 'body2', 
                    color: 'text.primary', 
                    width: '100%', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    display: '-webkit-box', 
                    WebkitLineClamp: '3', 
                    WebkitBoxOrient: 'vertical' 
                  }}>
                    <LoadableText text={topic.description}
                      isLoading={isLoading}
                      minWidth={100}/>
                  </Box>
                  <Box>
                    {topic?.categories?.map(c => (
                      <CategoryChip 
                        sx={{ m: 0.25 }}
                        size='small'
                        key={c.id}
                        category={c}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 0.25 }}>
            <Box sx={{ typography: 'body2', color: 'text.secondary', fontWeight: 'light' }}>  
              {topic.lastCommentAt &&
              <LoadableText text={`Last Reply ${getFromNow(topic.lastCommentAt)}`} isLoading={isLoading} minWidth={20} />
              }
            </Box>
            <Box sx={{}}>
              <LikeAndCommentButtons 
                isLoading={isLoading}
                data={{ likeCount:topic.likeCount, commentCount:topic.commentCount, likedByCurrentUser: topic.likedByCurrentUser }}
                likeAction = {likeTopic}
                commentAction = {() => openCommentDialog()}
              />
            </Box>
          </Box>
        </Box> */}
      </Card>
    </Box>
  );
}