
import React from 'react';

export default function Blog(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path d="M8.99996 16.9998L4 11.9997L9 6.99976"
        stroke={props.stroke}
        fill={props.fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path d="M4 12H20"
        stroke={props.stroke}
        fill={props.fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}

