import { Box, Stack } from '@mui/material';
import React from 'react';

export default function LoadableList(props) {
  var { rows, isLoading, getRow, getKey, numberOfRows = 10, divider } = props;

  var dummyData= [];

  for(var i = 0; i < numberOfRows; i++) {
    dummyData.push({ id: i });
  }

  var data = isLoading ? dummyData: rows;
  
  return (
    <Stack 
      direction="column"
    >
      {data.map((d, index) => {
        return <Box key={getKey(d)}>
          {getRow(d)}
          {index != rows.length - 1 && divider}
        </Box>;
      })}
    </Stack>
  );
}