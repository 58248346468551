import React, { createContext, useEffect, useState } from 'react';

export const NewPostInputContext = createContext();

export function NewPostInputProvider(props){
  const { settings, initialImage } = props;
  const [message, setMessage] = useState('');
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');

  var isButtonEnabled = true;

  if(settings.requiresTitle && title == ''){
    isButtonEnabled = false;
  }

  if(message == '' && image == null){
    isButtonEnabled = false;
  }

  useEffect(() => {
    setMessage('');
    setTitle('');
    setImage(null);
  }, []);

  useEffect(() => {
    setImage(initialImage);
  }, [initialImage]);

  return (
    <NewPostInputContext.Provider value={{ 
      settings,
      message,
      setMessage,
      title,
      setTitle,
      image,
      setImage,
      isButtonEnabled
    }}>
      {props.children}
    </NewPostInputContext.Provider>
  );
}