import React from 'react';

import {
  Box,
  Typography 
} from '@mui/material';

import { toShortDateString } from '@helpers/dates';
import LoadableText from './loadable/text';
import { useTheme } from '@emotion/react';

export default function UserGoalText(props) {
  const { goal, isLoading, isCompleted } = props;
  const theme = useTheme();

  return (
    <Typography component='div'
      sx={{ 
        color: isCompleted ? theme.palette.secondary.dark : theme.palette.primary.main,
        textDecoration: isCompleted ? 'line-through': 'none'
      }}>
      <LoadableText isLoading={isLoading}
        text={
          <Box sx={{ display: 'flex', fontSize:'body2.fontSize' }}>
            <Typography fontWeight='medium' fontSize='body2.fontSize' sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>{goal.description}</Typography> 
            {goal.targetDate &&
              <>
              &nbsp;by&nbsp;
                <Typography sx={{ whiteSpace: 'nowrap' }} fontWeight='medium' fontSize='body2.fontSize'> {toShortDateString(goal.targetDate)}</Typography>
              </>
            }
          </Box>}
      />
     
    </Typography>
  );
}