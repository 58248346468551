import React from 'react';

import { 
  Tooltip
} from '@mui/material';

export default function OptionalTooltip(props) {
  const { isEnabled, children } = props;

  if(!isEnabled){
    return (<>{children}</>);
  }

  return (
    <Tooltip title={props.title}>
      {children}
    </Tooltip>
  );
}