import * as React from 'react';

import { AccountRoles } from '@helpers/roles';
import { LoadableChip } from '@components/atoms';

export default function AccountRoleChip(props) {
  const { role, isLoading } = props ;

  const getColor = () => {
    if(role){
      switch(role) {
      case AccountRoles.ADMIN.toUpperCase():
        return 'secondary';
      case AccountRoles.PRACTITIONER.toUpperCase():
        return 'primary';
      default:
        return 'default';
      }
    }
  };

  return (
    <LoadableChip 
      color={getColor()}
      size='small'
      isLoading={isLoading}
      label={AccountRoles[role]}
      variant="outlined" />
  );
}