import React from 'react';

import { 
  Box,
  CircularProgress as MuiCircularProgress
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { Icon } from '@components/v2/atoms';
import { LoadableText } from '@components/atoms';
export default function CircularProgress(props){
  const { size = 100, value, count, thickness = 4, text, isActive = false, isLoading, fontColor, light = false, fontSize='0.875rem', completeIcon='check' } = props;
  const theme = useTheme();

  return (
    <Box sx={{ 
      position: 'relative',
      display: 'inline-flex',
      width: size,
      height: size
    }}>
      <MuiCircularProgress
        variant="determinate"
        size={size ?? 100}
        thickness={thickness}
        color='secondary'
        sx={{ 
          zIndex: 3,
          borderRadius: '50%',
          backgroundColor: isActive ? 
            `${theme.palette.secondary.main}1F` : 
            'none',
          position: 'absolute',
          left: 0,
          strokeLinecap: 'round'
        }}
        value={value}
      />
      <MuiCircularProgress
        variant="determinate"
        size={size}
        thickness={1}
        value={100}
        sx={{ 
          zIndex: 2,
          color: light ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
          strokeLinecap: 'round',
          position: 'absolute',
          left: 0
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {value == 100 &&
          
          <Box sx={{ 
            position: 'relative', 
            color: isActive ? theme.palette.secondary.main : grey[200], 
            lineHeight: 1,
            height:size / 2,
            fontWeight: 'medium'  }}>
            <Icon 
              isLoading={isLoading}
              type={completeIcon}
              size={size * 0.5}
              fill={theme.palette.secondary.main}
              stroke={'none'}
            />
          </Box>
        }

        {(value != 100 && text) &&
          <Box sx={{ 
            position: 'relative', 
            color: fontColor == null ? (isActive ? theme.palette.secondary.main : grey[200]) : fontColor, 
            lineHeight: 1,
            fontSize: fontSize,
            fontWeight: count > 0 ? 'bold' : 'regular'  }}>
            <LoadableText isLoading={isLoading} text={text} minWidth={size / 2}/>
            
          </Box>
        }
      </Box>
    </Box>
  );
}