import React, { useCallback, useContext, useEffect, useState } from 'react';

import { 
  Box
} from '@mui/material';
import BottomNavBar from './bottom-nav-bar';
import { useTheme } from '@emotion/react';
import TopNavBar from './top-bar';
import { ColorModeContext } from '@context/theme';
import useDetectKeyboardOpen from '@helpers/device';

export default function Page(props){
  const { fullPage = true, title, includeProfile=false, OnboardingBanner } = props;
  const theme = useTheme();
  const { toggleBackgroundColor } = useContext(ColorModeContext);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [bottomBarHeight, setBottomBarHeight] = useState(72);
  const [topBarHeight, setTopBarHeight] = useState(72);

  useEffect(() => {
    toggleBackgroundColor(true);
  }, []);

  const bottomNavRefContainer = useCallback((n) => {
    if (!n) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      var height = entries[0].contentRect.height;
      if(height){
        setBottomBarHeight( height);
      }
    });
    resizeObserver.observe(n);
  }, []);

  const topNavRefContainer = useCallback((n) => {
    if (!n) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      var height = entries[0].contentRect.height;
      if(height){
        setTopBarHeight( height);
      }
    });
    resizeObserver.observe(n);
  }, []);

  return (
    <Box sx={{ 
      height: fullPage ? 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))' : 'inherit',
      backgroundColor: '#FAFAFA'
    }}>
      <Box ref={topNavRefContainer} sx={{ backgroundColor: theme.palette.braceGreenDark.main }}>
        <TopNavBar title={title} includeProfile={includeProfile}/>
      </Box>
      <Box sx={{ 
        height: `calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - ${isKeyboardOpen ? 0 : bottomBarHeight}px - ${topBarHeight}px)`
      }}>
        <Box sx={{ height: 'calc(100% + 2px)', marginTop: '-2px' }}>
          {props.children}
        </Box>
        {(!isKeyboardOpen && OnboardingBanner) &&
          <Box sx={{
            position: 'absolute',
            bottom: 'calc(env(safe-area-inset-bottom) + 64px)',
            width: '100%'
          }}>
            {OnboardingBanner}
          </Box>
        }
      </Box>
      {!isKeyboardOpen &&
        <>
          <Box ref={bottomNavRefContainer}>
            <BottomNavBar/>
          </Box>
          <Box sx={{ 
            zIndex: 1199,
            position: 'fixed',
            bottom: '0',
            marginBottom: '-2px',
            width: '100%',
            height: 'calc(env(safe-area-inset-bottom) + 4px)',
            backgroundColor: theme.palette.braceGreenDark.main
          }}>
          </Box>
        </>
      }
    </Box>
  );
}