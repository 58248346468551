import React from 'react';

import { 
  Box,
  TextField as MuiTextField,
  Typography
} from '@mui/material';

export default function TextField(props){
  const { label, dataTestId, required, size='small', sx, onChange = () => {}, ...muiProps } = props;

  return (
    <Box sx={{ width: '100%' }}>
      {label &&
      <Typography variant='body2' paddingBottom={0.5} fontWeight='medium'>
        {`${label}${required ? '*' : ''}`}
      </Typography>
      }
      <MuiTextField onChange={onChange}
        required={required}
        inputProps={{ 'data-testid': dataTestId }} 
        size={size}
        fullWidth
        {...muiProps}
        sx={{ ...sx, 
          '.MuiInputBase-multiline':{
            padding: 0
          },
          'input.MuiInputBase-inputSizeSmall': {
            padding: '12px 14px'
          },
          'textarea.MuiInputBase-inputSizeSmall': {
            padding: '12px 14px'
          }
        }} />
    </Box>
  );
}