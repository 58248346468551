import React from 'react';

import { 
  Box,
  InputBase
} from '@mui/material';
import { Search } from '@material-ui/icons';
import { useTheme } from '@emotion/react';
import Button from './buttons/button';

export default function SearchInput(props){
  const { value, onChange, isDisabled, placeholder, inputRef, inputProps } = props;
  const theme = useTheme();

  return (
    <Box 
      ref={inputRef}
      sx={{ 
        flexGrow: 1,
        borderRadius: 1, 
        backgroundColor: theme.palette.divider,
        display:'flex', 
        alignItems: 'center',
        height: '36px',
        px: 1
      }}>
      <Search />
      <InputBase
        inputProps={inputProps}
        disabled={isDisabled}
        sx={{ color: 'text.primary', flexGrow: 1, px: 1 }}
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          if(onChange){
            onChange(event.target.value);
          }
        }} 
      />
      {(inputProps?.ref?.current && inputProps?.ref?.current.contains(document.activeElement)) &&
        <Button 
          color='braceGreenDark'
          onClick={(e) => {
            if(inputProps?.ref){
              e.stopPropagation();
              inputProps.ref.current.blur();
            }
          }}>
          Cancel
        </Button>
      }
    </Box>
  );
}