import React, { useContext } from 'react';

import {
  Box,
  IconButton,
  Divider,
  Typography,
  Grid
}from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { NewPlanContext } from '../context';

export default function Header(props) {
  const { title, endButton } = props;
  const { close } = useContext(NewPlanContext);

  return (
    <Box>
      <Grid container alignItems='center'>
        <Grid item xs={2}>
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <IconButton onClick={close} data-testid='recovery-new-plan-close-button'><CloseIcon style={{ fontSize: 28 }}/></IconButton>
          </Box>
        </Grid>
        <Grid item xs={8}> 
          <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2}> 
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            {endButton}
          </Box>
         
        </Grid>
        
      </Grid>
      <Divider />
    </Box>
  );
}

