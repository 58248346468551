
import React from 'react';

export default function CirclePlus(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={'#000000'}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 3C7.75 2.0335 8.5335 1.25 9.5 1.25H14.5C15.4665 1.25 16.25 2.0335 16.25 3C16.25 3.9665 15.4665 4.75 14.5 4.75H9.5C8.5335 4.75 7.75 3.9665 7.75 3Z"
        stroke={'none'}
        fill={props.fill}/>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45 3C6.45 2.91583 6.45341 2.83245 6.4601 2.75H5.5C3.98122 2.75 2.75 3.98122 2.75 5.5L2.75 20C2.75 21.5188 3.98122 22.75 5.5 22.75L18.5 22.75C20.0188 22.75 21.25 21.5188 21.25 20L21.25 5.5C21.25 3.98122 20.0188 2.75 18.5 2.75L17.5399 2.75C17.5466 2.83245 17.55 2.91583 17.55 3C17.55 4.68447 16.1845 6.05 14.5 6.05L9.5 6.05C7.81553 6.05 6.45 4.68447 6.45 3ZM8 10.25C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75L16 11.75C16.4142 11.75 16.75 11.4142 16.75 11C16.75 10.5858 16.4142 10.25 16 10.25L8 10.25ZM8 15.25C7.58579 15.25 7.25 15.5858 7.25 16C7.25 16.4142 7.58579 16.75 8 16.75H11.4286C11.8428 16.75 12.1786 16.4142 12.1786 16C12.1786 15.5858 11.8428 15.25 11.4286 15.25H8Z"
        stroke={'none'}
        fill={props.fill} />
    </svg>
  );
}
