import React, { useContext } from 'react';

import {
  Box, 
  IconButton,
  Typography,
  Input,
  Button,
  Menu,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
}from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { NewPlanContext } from '../../context';

function SetInput(props){
  var { value, onChange, onBlur } = props;

  return (
    <Input 
      type='number'     
      pattern="\d*"
      value={value}
      onChange={(value) => onChange(value.target.value)} 
      onBlur={onBlur}
      disableUnderline
      sx={{ '& input[type=number]': {
        'MozAppearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        'WebkitAppearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        'WebkitAppearance': 'none',
        margin: 0
      } }}
      inputProps={{ 'aria-label': 'description', style: { textAlign: 'center', fontSize: '1rem', padding: '0px' } }} />
  );
}

const measurementTypes = ['Weight', 'Seconds', 'Minutes', 'Metres', 'Kilometres'];

export default function ExerciseSets(props) {
  const { exercise, set } = props;
  const { copySet, removeSet, updateSetRepsValue, updateSetMeasurement, updateExerciseMeasurementType } = useContext(NewPlanContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const translateMeasurementType = (measurementType) => {
    switch(measurementType){
    case 'SECONDS':
      return 'Sec';
    case 'MINUTES':
      return 'Min';
    case 'METRES':
      return 'M';
    case 'KILOMETRES':
      return 'KM';
    case 'WEIGHT':
    default:
      return 'KG';
    }
  };

  const handleSetsBlur = () => {
    if(!set.quantity || set.quantity < 1){
      updateSetRepsValue(exercise.id, set, 1);
    }
  };

  const handleMeasurementTypeButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMeasurementTypeClose = (measurementType) => {
    if(typeof measurementType === 'string' || measurementType instanceof String){
      updateExerciseMeasurementType(exercise.id, measurementType.toUpperCase());
    }

    setAnchorEl(null);
  };

  return (
    <TableContainer>
      <Table  size="small"
        aria-label="a dense table"   
        sx={{
          ['& .MuiTableCell-root']: {
            borderBottom: 'none',
            paddingTop: 0.5,
            paddingBottom: 0.5
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Set</TableCell>
            <TableCell align="center">Reps</TableCell>
            <TableCell align="center">
              <Button aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMeasurementTypeButtonClick}
                sx={{ padding: 0 }}
                endIcon={anchorEl ? <ArrowDropUpIcon size='small' /> : <ArrowDropDownIcon size='small' /> }>
                <Typography component="div">
                  <Box fontSize='1rem' color='text.primary' fontWeight='medium'>
                    {translateMeasurementType(exercise.measurementType)}
                  </Box>
                </Typography>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMeasurementTypeClose}
              >
                {measurementTypes.map((measurementType) => 
                  <MenuItem sx={{
                    minHeight: 0,
                    padding: 1
                  }}
                  key={measurementType}
                  onClick={() => handleMeasurementTypeClose(measurementType)}>{measurementType}</MenuItem>
                )}
              </Menu>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exercise.sets.map((set) => 
            <TableRow key={set.number}>
              <TableCell align="center" sx={{ fontSize: '1rem' }}>{set.number}</TableCell>
              <TableCell align="center"><SetInput value={set.quantity != 0 ? set.quantity : ''} onChange={(value) => updateSetRepsValue(exercise.id, set, value)} onBlur={handleSetsBlur}/></TableCell>
              <TableCell align="center"><SetInput value={set.measurement >= 0 ? set.measurement : ''} onChange={(value) => updateSetMeasurement(exercise.id, set, value)} onBlur={() => {}}/></TableCell>
              <TableCell align="center">
                <Box sx={{ display: 'flex' }}>
                  <IconButton size='small'
                    sx={{ py: 0 }}
                    onClick={() => removeSet(exercise.id, set)}
                    disabled={exercise.sets.length === 1}>
                    <RemoveCircleIcon />
                  </IconButton>
                  { set.number == exercise.sets.length && 
                  <IconButton size='small' onClick={() => copySet(exercise.id, set)} sx={{ py: 0 }}>
                    <ContentCopyIcon />
                  </IconButton>
                  }
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

