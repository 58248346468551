import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import {
  Box,
  Grid
} from '@mui/material';

import LoadingCard from './loading-card';
import InProgressProgram from './in-progress-program';
import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';

function filterPrograms(programs){
  var filteredPrograms = [];

  //Add In Progress Programs First
  programs.forEach(function(program){
    if(moment(program.endDate).isAfter(moment())){
      filteredPrograms.push(program);
    }
  });

  //Add Complete Programs Next
  programs.forEach(function(program){
    if(moment(program.endDate).isBefore(moment()) && program.sessions.totalCount == program.sessions.completeCount && filteredPrograms.length < 4){
      filteredPrograms.push(program);
    }
  });

  //Add Complete Programs Next
  programs.forEach(function(program){
    if(moment(program.endDate).isBefore(moment()) && program.sessions.totalCount != program.sessions.completeCount && filteredPrograms.length < 4){
      filteredPrograms.push(program);
    }
  });

  return filteredPrograms;
}

export default function ProgramsList(props){
  const { profile } = props;
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if(profile?.id){
      api.get({ url: `recovery/programs?userId=${profile.id}`, user })
        .then(result => {
          setPrograms(result.results);
          setIsLoading(false);
        });
    }
  }, [profile]);

  return (
    <Box>
      <Grid container spacing={1}>
        {isLoading &&
          <>
            <Grid item xs={6}>
              <LoadingCard />
            </Grid>
            <Grid item xs={6}>
              <LoadingCard />
            </Grid>
          </>
        }
        {!isLoading && filterPrograms(programs).map(program => 
          <Grid item
            xs={6}
            key={program.id}
            sx={{ textDecoration: 'none' }} >
            <InProgressProgram program={program} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
