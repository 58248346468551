import React from 'react';

import { 
  Box,
  Grid
} from '@mui/material';

import Goals from './goals';
import DiaryGraph from './diary-graph';
import Programs from './programs';
import Plans from './plans';
import Notes from './notes';

export default function Cards() {
  return(
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Programs />
        </Grid>
        <Grid item xs={12} md={12}>
          <Plans />
        </Grid>
        <Grid item xs={12} >
          <Notes/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Goals/>
        </Grid>
        <Grid item xs={12} md={6}>
          <DiaryGraph/>
        </Grid>
      </Grid>
    </Box>
  );
}
