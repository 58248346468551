import React, { useContext } from 'react';

import {
  Box,
  Button
}from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { NewPlanContext } from '../context';

export default function Buttons(props) {
  const { close } = useContext(NewPlanContext);
  const { next, nextText, isNextDisabled, isNextLoading, back } = props;

  return (
    <Box sx={{ m: 1, display: 'flex', flexDirection: 'row-reverse' }}>
      {next && 
        <LoadingButton 
          data-testid='recovery-new-plan-next-button'
          disabled={isNextDisabled}
          loading={isNextLoading}
          sx={{ marginLeft: 1 }}
          onClick={next}
          color='secondary'
          variant='contained'>
          {nextText ?? 'Next'}
        </LoadingButton>
      }
      {back && 
        <Button 
          sx={{ marginLeft: 1 }}
          onClick={back}
          color='secondary'
          variant='outlined'>
            Back
        </Button>
      }
      <Button onClick={close} color='secondary'>
        Cancel
      </Button>
    </Box>
  );
}

