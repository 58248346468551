import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { UserContext, ServicesContext, EventsContext, FeaturesContext } from '@context';
import moment from 'moment';
import ConfirmationModal from './../../molecules/confirmation-modal';
export const NewPlanContext = createContext();

function copySetFunction(setsArray, setToCopy){
  var newSet = { ...setToCopy,
    id: Math.max(...setsArray.map(o => o.id), 0) + 1,
    number: setsArray.length + 1
  };

  return [...setsArray, newSet];
}

function removeFromSetsArray(setsArray, setToRemove){
  var index = setsArray.indexOf(setToRemove);

  var updatedSetsArray = [
    ...setsArray.slice(0, index),
    ...setsArray.slice(index + 1)
  ];

  updatedSetsArray.sort((a, b) => a.id > b.id);

  updatedSetsArray.forEach((set, index) => {
    set.number = index + 1;
  });

  return updatedSetsArray;
}

function setSetReps(setsArray, setToUpdate, reps){
  var updateSets = setsArray.map((set) => {return set === setToUpdate ? 
    { ...set, quantity: reps ? parseInt(reps) : 0 } : 
    set;
  });

  return updateSets;
}

function setSetMeasurement(setsArray, setToUpdate, measurement){
  var updateSets = setsArray.map((set) => {return set === setToUpdate ? 
    { ...set, measurement: parseInt(measurement) } : 
    set;
  });

  return updateSets;
}

export function NewPlanProvider(props){
  const { isOpen, close, planBeingEdited } = props;
  
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { trackEvent } = useContext(EventsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [exercises, setExercises] = useState([]);
  const [isLoadingExercises, setIsLoadingExercises] = useState(false);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [isSavingPlan, setIsSavingPlan] = useState(false);
  const [exerciseBeingEdited, setExerciseBeingEdited] = useState(null);
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const [shouldAlsoUpdateSessions, setShouldAlsoUpdateSessions] = useState(true);
  const [shouldShowCloseConfirmationModal, setShouldShowCloseConfirmationModal] = useState(false);
  var isDraft = planBeingEdited?.isDraft == true;
  var isEdit = planBeingEdited?.id != null;
  var isSaveDraftEnabled = !isEdit || isDraft;

  const loadExercises = async () => {
    setIsLoadingExercises(true);
    var result = await api.get({ url: 'recovery/exercises/library', user });
    setExercises(result);
    setIsLoadingExercises(false);
  };

  const addNewExercise = (exercise) => {
    setHasChanged(true);
    setExercises([ exercise, ...exercises ]);
  };

  const updateExercise = (exercise) => {
    setHasChanged(true);
    setExercises(exercises.map(e => {
      if(e.id == exercise.id){
        return exercise;
      }
      return e;
    }));
  };

  const addSelectedExercise = (exercise) => {
    setHasChanged(true);
    setSelectedExercises([...selectedExercises, { 
      ...exercise, 
      sets: [{
        id: 0, 
        number: 1, 
        quantity: 1, 
        measurement: 0
      }], 
      measurementType: 'WEIGHT' 
    }]);
  };

  const clearSelectedExercises = () => {
    setHasChanged(true);
    setSelectedExercises([]);
  };

  const removeSelectedExercise = (exercise) => {
    setHasChanged(true);
    setSelectedExercises(selectedExercises.filter(se => se.id != exercise.id));
  };

  const addSet = (exerciseId) => {
    setHasChanged(true);
    setSelectedExercises(selectedExercises.map(
      (exercise) => exercise.id === exerciseId ? 
        { ...exercise, 
          sets: [...exercise.sets, {
            id: exercise.sets.length, 
            number: exercise.sets.length + 1, 
            quantity: 1, 
            measurement: 0
          }]
        } : 
        exercise

    ));
  };

  const toggleIsEachSide = (exerciseId) => {
    setHasChanged(true);
    setSelectedExercises(selectedExercises.map(
      (exercise) => exercise.id === exerciseId ? 
        { ...exercise, 
          isEachSide: !exercise.isEachSide 
        } : 
        exercise
    ));
  };

  const setExerciseNotes = (exerciseId, notes) => {
    setHasChanged(true);
    setSelectedExercises(selectedExercises.map(
      (exercise) => exercise.id === exerciseId ? 
        { ...exercise, 
          notes
        } : 
        exercise
    ));
  };

  const copySet = (exerciseId, set) => {
    setHasChanged(true);
    setSelectedExercises(selectedExercises.map(
      (exercise) => exercise.id === exerciseId ? 
        { ...exercise, 
          sets: copySetFunction(exercise.sets, set)
        } : 
        exercise

    ));
  };

  const removeSet = (exerciseId, set) => {
    setHasChanged(true);
    setSelectedExercises(selectedExercises.map(
      (exercise) => exercise.id === exerciseId && 
                              exercise.sets.length > 1 ? 
        { ...exercise, 
          sets: removeFromSetsArray(exercise.sets, set)
        } : 
        exercise
    )
    );
  };

  const updateSetRepsValue = (exerciseId, set, reps) => {
    setHasChanged(true);
    setSelectedExercises(selectedExercises.map(
      (exercise) => exercise.id === exerciseId ? 
        { ...exercise, 
          sets: setSetReps(exercise.sets, set, reps)
        } : 
        exercise
    ));
  };

  const updateSetMeasurement = (exerciseId, set, measurement) => {
    setHasChanged(true);
    setSelectedExercises(selectedExercises.map(
      (exercise) => exercise.id === exerciseId ? 
        { ...exercise, 
          sets: setSetMeasurement(exercise.sets, set, measurement)
        } : 
        exercise
    ));
  };

  const updateExerciseMeasurementType= (exerciseId, measurementType) => {
    setHasChanged(true);
    setSelectedExercises(selectedExercises.map(
      (exercise) => exercise.id === exerciseId ? 
        { ...exercise, 
          measurementType: measurementType
        } : 
        exercise
    ));
  };

  const savePlan = ({ isDraft=false } = {}) => {
    setIsSavingPlan(true);

    var newName = name;

    if(newName == ''){
      newName = `Drafted - ${moment().format('Do MMM')}`;
    }

    if(planBeingEdited){
      trackEvent(`Recovery:PlanCreator:${isDraft ? 'Draft:' : ''}Update`, 
        {
          planId: planBeingEdited.id
        });
      api.put({ url: `recovery/plans/${planBeingEdited.id}`, user, data: {
        name: newName,
        notes,
        isDraft,
        exercises: selectedExercises,
        shouldAlsoUpdateSessions
      } })
        .then(() => {
          enqueueSnackbar(`${isDraft ? 'Draft' : 'Plan'} successfully updated`, { 
            variant: 'success'
          });
          setIsSavingPlan(false);
          close(true);
        });
    }else{
      trackEvent(`Recovery:PlanCreator:${isDraft ? 'Draft:' : ''}Save`);
      api.post({ url: 'recovery/plans', user, data: {
        name: newName,
        notes,
        isDraft,
        exercises: selectedExercises
      } })
        .then(() => {
          enqueueSnackbar(`Successfully created ${isDraft ? 'draft' : 'plan'} "${newName}"`, { 
            variant: 'success'
          });
          setIsSavingPlan(false);
          close(true);
        });
    }

  };

  const closeDialog = ({ forceClose = false }) => {
    if(hasChanged && (!isEdit || isDraft) && !forceClose){
      setShouldShowCloseConfirmationModal(true);
    }else{
      trackEvent('Recovery:PlanCreator:Closed');
      setSelectedExercises([]);
      setName('');
      setHasChanged(false);
      setNotes('');
      setShouldAlsoUpdateSessions(true);
      setShouldShowCloseConfirmationModal(false);
      setIsSavingPlan(false);
      close();
    }

  };

  useEffect(() => {
    if(user){
      loadExercises();
    }
  }, [user]);

  useEffect(() => {
    if(!isOpen){
      setSelectedExercises([]);
      setName('');
      setIsSavingPlan(false);
    }else{
      if(planBeingEdited){
        trackEvent('Recovery:PlanCreator:Edit:Open', {
          planId: planBeingEdited.id
        });
        api.get({ url: `recovery/plans/${planBeingEdited.id}`, user })
          .then((response) => {
            setName(response.name);
            setNotes(response.notes);

            var selectedExercises = [];
            
            response.exercises.forEach(exercise => {
              var selectedExercise = {
                ...exercise.definition,
                measurementType: exercise.measurementType,
                notes: exercise.notes,
                isEachSide: exercise.isEachSide,
                sets: []
              };

              var setCounter = 0;
              exercise.sets.forEach(set => {
                set.id = setCounter++;
                selectedExercise.sets.push(set);
              });
            
              selectedExercises.push(selectedExercise);
            });

            setSelectedExercises(selectedExercises);
          });
      }else{
        trackEvent('Recovery:PlanCreator:New:Open');
      }
    }
  }, [isOpen]);

  return (
    <NewPlanContext.Provider value={{ 
      isEdit,
      isDraft,
      isSaveDraftEnabled,
      isOpen: props.isOpen,
      close: closeDialog,
      exercises,
      isLoadingExercises,
      selectedExercises,
      addSelectedExercise,
      clearSelectedExercises,
      removeSelectedExercise,
      addNewExercise,
      updateExercise,
      addSet,
      copySet,
      removeSet,
      updateSetRepsValue,
      toggleIsEachSide,
      updateSetMeasurement,
      updateExerciseMeasurementType,
      name, 
      setName,
      notes, 
      setNotes,
      shouldAlsoUpdateSessions, 
      setShouldAlsoUpdateSessions,
      savePlan,
      isSavingPlan,
      exerciseBeingEdited, 
      setExerciseBeingEdited,
      setExerciseNotes
    }}>
      <>
        {props.children}
        <ConfirmationModal 
          isOpen={shouldShowCloseConfirmationModal} 
          title='Save as Draft?' 
          message={'Would you like to save this plan as a draft so you can come back to it later?'}
          cancelText={'No Delete'}
          cancelColor='error'
          handleClickOutsideModal={() => {
            setShouldShowCloseConfirmationModal(false);
          }}
          handleClose={() => {
            setHasChanged(false);
            closeDialog({ forceClose: true });
            setShouldShowCloseConfirmationModal(false);
          }}
          confirmText={`${isDraft ? 'Update' : 'Save'} Draft`}
          handleConfirmation={() => savePlan({ isDraft : true })} />
      </>
    </NewPlanContext.Provider>
  );
}