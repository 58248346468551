import React from 'react';

import { 
  Radio as MuiRadio
} from '@mui/material';
import { useTheme } from '@emotion/react';
import Icon from '../icons';
import { grey } from '@mui/material/colors';

export default function Radio(props){
  const { size = 16, ...muiProps } = props;
  const theme = useTheme();
  return (
    <MuiRadio 
      {...muiProps}
      checkedIcon={
        <Icon type='check-circle'
          size={size} 
          fill={theme.palette.braceGreenLight.main}
          stroke={theme.palette.braceGreenDark.main} />
      }
      icon={<Icon type='circle'
        size={size}
        fill='#ffffff'
        stroke={grey[300]} />}
    /> 
  );
}