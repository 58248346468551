import React from 'react';

import { 
  Box
} from '@mui/material';

import LoadableText from './loadable/text';

export default function TruncatedText(props) {
  var { text, dataTestId, isLoading, maxLines } = props;
  return (
    <>
      {isLoading && <LoadableText isLoading={isLoading} text={text} />}
      {!isLoading && 
        <Box data-testid={dataTestId}
          sx={{
            'WebkitLineClamp': `${maxLines}`,
            'WebkitBoxOrient': 'vertical',
            display: '-webkit-box',
            overflow: 'hidden'
          }}>
          {text}
        </Box>
      }
    </>
  );
}