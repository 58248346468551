
export const AccountRoles = {
  'CLIENT': 'Client',
  'PRACTITIONER': 'Practitioner',
  'ADMIN': 'Admin'
};

export const hasRole = (role, requiredRole) => {
  switch(requiredRole){
  case AccountRoles.ADMIN:
    return role == AccountRoles.ADMIN;
  case AccountRoles.PRACTITIONER:
    return role == AccountRoles.ADMIN || role == AccountRoles.PRACTITIONER;
  default:
    return true;

  }
};