import React, { useContext, memo } from 'react';

import {
  Box
} from '@mui/material';

import config from '@config';

import { UserContext } from '@context/user';
import { Avatar } from '@components/atoms';
import { ProfileImage } from '@components/molecules';

export default memo(function User() {
  const { user } = useContext(UserContext);

  return (
    <Box sx={{
      p: 1,
      display: 'flex', 
      alignItems: 'center'
    }} >
      <ProfileImage
        isLoading={false}
        user={user}
        name={user?.name} 
      />
      <Box sx={{ paddingLeft: 1 }}>
        <Box sx={{ typography: 'h5', fontWeight: 'medium', lineHeight: 1.1 }}>
          {user.name}
        </Box>
      </Box>
    </Box>
  );
});