import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Dialog,
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  Skeleton,
  IconButton
} from '@mui/material';

import { UserContext } from '@context/user';
import CloseIcon from '@mui/icons-material/Close';

import { DialogTransition, Avatar, InjuryDetails } from '@components/atoms';
import { useTheme } from '@emotion/react';

import { getFromNow } from '@helpers/dates';

import config from '@config';
import { ServicesContext } from '@context/services';
import { EmptyState, ProfileImage } from '@components/molecules';

export default function Likes(props) {
  const { isOpen, close, parentId } = props;
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [likes, setLikes] = useState([]);
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);

  useEffect(() => {
    if(isOpen){
      setIsLoading(true);
      api.get({ url: 'community/likes', params: { parentId },  user })
        .then(response => {
          setIsLoading(false);
          setLikes(response.results);
        });
    }
  }, [isOpen]);

  const Like = (props) => {
    const { l } = props;

    return (
      <Stack direction="row"
        spacing={1}
        p={1}
        sx={{ alignItems: 'center' }}>
        <Box>
          <ProfileImage 
            linkTo={`?profileId=${l?.user.id}`}
            isLoading={isLoading}
            user={l?.user}
            name={l?.user.name} />
        </Box>
        <Stack sx={{ flexGrow: 1 }}>
          <Stack direction="row">
            <Stack direction="row" sx={{ flexGrow: 1 }} spacing={0.5}>
              <Typography variant="body1"
                sx={{ fontWeight: 'medium' }}
                component={Link}
                to={`/profile/${l?.user.id}`}
                style={{ textDecoration: 'none' }}
                color='text.primary'>
                { isLoading ? <Skeleton sx={{ width: '75px' }}/> : l?.user.name}
              </Typography>
              <Typography component="div" variant="body1" sx={{ color: 'text.secondary' }} >
                { isLoading ? <Skeleton sx={{ width: '75px' }}/> : getFromNow(l?.createdAt)}
              </Typography>
            </Stack>
          </Stack>
          <Box>
            <InjuryDetails 
              isLoading={isLoading} 
              activityType={l?.user.activityType}
              injuryType={l?.user.injuryType} />
          </Box>
        </Stack>
      </Stack>
    );
  };

  const EmptyLoadingState = () => {
    var rows = [];
    for (var i = 0; i < 10; i++) {
      rows.push(<Like key={i} isLoading={true} />);
    }
    return rows;
  };

  return (
    <Dialog open={isOpen}
      onClose={close}
      sx={{
        '.MuiPaper-root' : {
          [theme.breakpoints.down('md')]: {
            width: '90%'
          },
          [theme.breakpoints.up('md')]: {
            width: '50%'
          }
        }
      }}
      TransitionComponent={DialogTransition} >
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={2}>
            <IconButton onClick={close} >
              <CloseIcon style={{ fontSize: 28 }} color="action" />
            </IconButton>
          </Grid>
          <Grid item xs={8} sx={{ textAlign: 'center', typography: 'h5' }}>
            Liked By
          </Grid>
        </Grid>
      </Box>
      <Divider />
      {isLoading && likes.length === 0 &&
        <EmptyLoadingState />
      }
      {!isLoading && likes.length === 0 &&
        <Box p={2}>
          <EmptyState 
            iconType='thumbs-up'
            title='No Likes Yet'
            message='Why not be the first one to show some encouragement?'
          />
        </Box>
      }
      {likes.length > 0 &&
        likes.map((like) =>
          <Box key={like.id} sx={{ marginBottom: '1px' }} >
            <Divider />
            <Like l={like} /> 
            <Divider />
          </Box>
        )}
    </Dialog>
  );
}