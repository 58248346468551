import React, { useContext } from 'react';
import moment from 'moment';
import { 
  Box, 
  Card, 
  Typography,
  Checkbox
} from '@mui/material';

import { ClientContext } from '../../client-context';
import { EmptyState } from '@components/molecules';
import { ACTION_TYPES } from './../actions/index';
import { UserGoalText } from '@components/atoms';

export default function Goals() {
  const { goals, isLoadingGoals, setActiveAction } = useContext(ClientContext);

  var incompleteGoals = goals.filter(g => !g.isCompleted);

  if(incompleteGoals.length < 3){
    var completeGoals = goals
      .filter(g => g.isCompleted)
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));

    var counter = 0;
    while(incompleteGoals.length < 3 && counter < completeGoals.length){
      incompleteGoals.push(completeGoals[counter++]);
    }
  }

  var sortedGoals = incompleteGoals.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));

  return(
    <Card sx={{ width: '100%', height: '100%', p: 1 }}>
      <Typography color='text.secondary'>
        Goals
      </Typography>
      {isLoadingGoals &&
        <EmptyState 
          size={100}
          fullWidth={true}
          iconType='to-do-list'
          title={'Loading Goals..'}
        />
      }
      {!isLoadingGoals &&
        <>
          {sortedGoals?.length > 0 && goals.map(goal => {
            return (
              <Box key={goal.id} sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox checked={goal.completed} disabled/>
                <UserGoalText goal={goal}/>
              
              </Box>
            );
          })}
          {goals?.length == 0 && 
           <EmptyState 
             size={100}
             fullWidth={true}
             iconType='target'
             buttonText='Create Goal'
             buttonAction={() => {setActiveAction(ACTION_TYPES.CREATE_GOAL);}}
           />
          }
        </>
      }
    </Card>
  );
}
