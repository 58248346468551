import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button
}from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { NewPlanContext } from '../../context';
import Buttons from '../buttons';

import Exercise from './exercise';
import NewExerciseDialog from './new-exercise-dialog';

export default function PickExercises(props) {
  const PAGE_SIZE = 20;
  const { handleNext } = props;
  const { isLoadingExercises, exercises, selectedExercises, addSelectedExercise, removeSelectedExercise, exerciseBeingEdited, setExerciseBeingEdited } = useContext(NewPlanContext);
  const [ filterText, setFilterText ] = useState('');
  const [ isNewExerciseDialogOpen, setIsNewExerciseDialogOpen] = useState(false);
  const [numberOfExercisesToDisplay, setNumberOfExercisesToDisplay] = useState(PAGE_SIZE);

  useEffect(() => {
    setNumberOfExercisesToDisplay(PAGE_SIZE);
  }, []);

  const scrollable = {
    overflow: 'auto'
  };

  var remainingExercises = exercises
    .filter(e => selectedExercises.filter(se => se.id == e.id).length === 0);

  if(filterText){
    remainingExercises = remainingExercises.filter(
      re => re.name.toLowerCase().includes(filterText.toLowerCase()) 
      || re.tags.filter(t => t.toLowerCase().includes(filterText.toLowerCase())).length > 0
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', p: 1 }}>
        <FormControl
          size='small'  
          sx={{ flexGrow: 1  }}>
          <InputLabel >Search By Name OrTags</InputLabel>
          <OutlinedInput
            label='Search By Name Or Tags'
            onChange={(e) => setFilterText(e.target.value)}
            inputProps={{ 'data-testid':'recovery-new-plan-exercises-search-input' }}
            value={filterText}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={filterText === ''}
                  onClick={() => setFilterText('')}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button sx={{ marginLeft: 1 }}
          variant="contained"
          onClick={() => setIsNewExerciseDialogOpen(true)}
          size='small'>
          New Exercise
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
          <Box sx={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              Library
            </Typography>
            <Box sx={{ flexGrow: 1,  ...scrollable }}>
              <Divider />
              {remainingExercises.slice(0, numberOfExercisesToDisplay).map(e => (
                <Exercise 
                  isAdd
                  key={e.id}
                  exercise={e}
                  onClick={() => addSelectedExercise(e)}/>
              ))}
              {numberOfExercisesToDisplay < remainingExercises.length && 
              <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                <Button onClick={() => {
                  setNumberOfExercisesToDisplay(numberOfExercisesToDisplay + PAGE_SIZE);
                }}>
                More
                </Button>
              </Box>}
            </Box>
          </Box>
          <Box sx={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            Selected
            </Typography>
            <Box sx={{ flexGrow: 1,  ...scrollable }}>
              <Divider />
              {selectedExercises.map(e => (
                <Exercise 
                  key={e.id}
                  exercise={e}
                  onClick={() => removeSelectedExercise(e)}/>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Buttons next={handleNext} isNextDisabled={isLoadingExercises || selectedExercises.length === 0} />
      <NewExerciseDialog isOpen={isNewExerciseDialogOpen || exerciseBeingEdited != null}
        close={() => {
          setIsNewExerciseDialogOpen(false);
          setExerciseBeingEdited(null);
        }}/>
    </Box>
  );
}

