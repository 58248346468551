import React from 'react';

import { 
  Chip
} from '@mui/material';

import LoadableText from './text';

export default function LoadableChip(props) {
  var { label, size, isLoading, variant, color, sx } = props;

  if(isLoading){
    return <LoadableText isLoading={true}/>;
  }

  return (
    <Chip 
      color={color}
      size={size} 
      label={label}
      variant={variant}
      sx={sx} />
  );
}