import React, { useContext } from 'react';

import { 
} from '@mui/material';

import { UserContext } from '@context/user';

import { Account } from '@components/templates';
import { useHistory } from 'react-router';

export default function Index() {
  var { user, isLoading, activeOrganisation } = useContext(UserContext);
  var history = useHistory();

  if(user && user.status != 'PENDING' && !isLoading){
    if(activeOrganisation?.id){
      history.push('/dashboard');
    }
  }

  return(
    <Account 
      defaultUserType='PRACTITIONER'
      doAfterPractitionerSignUp = {() => {
        history.push('/onboarding');
      }}
    />
  );
}
