import React, { useContext } from 'react';

import { 
  Box
} from '@mui/material';

import AdjustIcon from '@mui/icons-material/Adjust';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import { MenuOptions } from '../../../../components/molecules';
import { CreateGoalDialog } from '@components/organisms';
import AssignPlan from './assign-plan';
import CreateNote from './create-note';
import { ClientContext } from '../../client-context';

export const ACTION_TYPES = {
  ADD_NOTE:'ADD_NOTE',
  CREATE_GOAL:'CREATE_GOAL',
  ASSIGN_PLAN:'ASSIGN_PLAN'
};

export default function Actions() {
  let { isLoadingProfile, activeAction, setActiveAction, profile, loadGoals } = useContext(ClientContext);

  return(
    <Box>
      <CreateGoalDialog userId={profile?.id}
        isOpen={activeAction == ACTION_TYPES.CREATE_GOAL}
        close={(shouldRefresh) => {
          setActiveAction(null);
          if(shouldRefresh){
            loadGoals();
          }
        }} />
      <AssignPlan
        isOpen={activeAction == ACTION_TYPES.ASSIGN_PLAN}
        close={() => {
          setActiveAction(null);
        }}/>
      <CreateNote
        isOpen={activeAction == ACTION_TYPES.ADD_NOTE}
        close={() => {
          setActiveAction(null);
        }}/>
      <MenuOptions 
        disabled={isLoadingProfile}
        actions={[
          { icon: <AdjustIcon />, name: 'Assign Goal', onClick: () => {setActiveAction(ACTION_TYPES.CREATE_GOAL);} },
          { icon: <FitnessCenterIcon />, name: 'Assign Plan', onClick: () => {setActiveAction(ACTION_TYPES.ASSIGN_PLAN);} },
          { icon: <NoteAddIcon />, name: 'Add Note', onClick: () => {setActiveAction(ACTION_TYPES.ADD_NOTE);} }
        ]}/>
    </Box>
  );
}
