import React from 'react';

export default function DiaryIcon (props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 1.25H8L8 22.75H4.25C3.83579 22.75 3.5 22.4142 3.5 22V19H1.5V17H3.5V13H1.5V11H3.5V7H1.5V5H3.5V2C3.5 1.58579 3.83579 1.25 4.25 1.25ZM21.75 22.75H9.5L9.5 1.25H21.75C22.1642 1.25 22.5 1.58579 22.5 2V22C22.5 22.4142 22.1642 22.75 21.75 22.75ZM18.5 6.25H13.5V7.75H18.5V6.25ZM16.5 10.25H13.5V11.75H16.5V10.25Z"
        fill={props.fill}
        stroke={props.stroke}/>
    </svg>
  );
}