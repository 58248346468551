
export const COOKIE_NAMES = {
  INVITE_TOKEN: 'invite_token'
};

export function setCookie({ name, value, minutes= 5 }) {
  var d = new Date();
  d.setTime(d.getTime() + (minutes*60*1000));
  var expires = 'expires='+d.toUTCString();
  document.cookie = name + '=' + value + '; ' + expires;
}

export function getCookie(name) {
  name = name + '=';
  var ca = document.cookie.split(';');
  for(var i=0; i<ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return null;
}