import React, { useContext } from 'react';

import { 
  Box, 
  Card, 
  Typography,
  Stack
} from '@mui/material';
import { useTheme } from '@emotion/react';

import { ClientContext } from '../../client-context';
import { EmptyState } from '@components/molecules';
import { ACTION_TYPES } from './../actions/index';

import { PostItNote } from '@components/atoms';
import { toShortDateString } from '@helpers/dates';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Notes() {
  const { notes, isLoadingNotes, setActiveAction, setNoteBeingEdited } = useContext(ClientContext);
  const theme = useTheme();

  return(
    <Card sx={{ width: '100%', height: '100%', p: 1 }}>
      <Typography color='text.secondary'>
        Notes
      </Typography>
      {isLoadingNotes &&
        <EmptyState 
          size={100}
          iconType='notes'
          title={'Loading Notes..'}
        />
      }
      {!isLoadingNotes &&
      <>
        {notes?.length > 0 && 
          <Stack spacing={1} direction='row' sx={{ overflowX: 'auto' }}>
            {notes.map(note => {
              return (
                <Box 
                  key={note.id}
                  sx={{ 
                    [theme.breakpoints.down('md')]: {
                      minWidth:   '50%'
                    },
                    [theme.breakpoints.up('md')]: {
                      minWidth:   '33%'
                    }, 
                    maxHeight: '33vh' }}
                  onClick={() => {
                    setActiveAction(ACTION_TYPES.ADD_NOTE);
                    setNoteBeingEdited(note);
                  }} >
                  <PostItNote>
                    <Box sx={{ display:'flex', p: 0.5 }}>
                      <Typography variant='body2' sx={{ flexGrow: 1 }}>
                        {note.createdByUser.name}
                      </Typography>
                      <Typography variant='caption'>
                        {toShortDateString(note.createdAt)}
                      </Typography>
                    </Box>
                    <ReactQuill 
                      theme='snow'
                      value={note.message}
                      modules={{ toolbar: null }}
                      readOnly={true} />
                  </PostItNote>
                </Box>
              );})}
          </Stack>
        }
        {notes?.length == 0 &&
          <EmptyState 
            size={100}
            iconType='notes'
            buttonText='Add Note'
            buttonAction={() => {setActiveAction(ACTION_TYPES.ADD_NOTE);}}
          />
        }
      </>
      }
    </Card>
  );
}
