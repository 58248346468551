
import React, { useContext } from 'react';

import {
  Box,
  InputBase,
  Divider
} from '@mui/material';

import { useTheme } from '@emotion/react';
import { NewPostInputContext } from './new-post-input-context';

export default function Content() {
  const { settings, message, setMessage, title, setTitle, image } = useContext(NewPostInputContext);
  const theme = useTheme();
  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  return (
    <Box>
      {settings.requiresTitle &&
        <Box px={1} py={1} width='100%'>
          <InputBase
            fullWidth
            multiline
            autoFocus={settings.requiresTitle}
            sx={{ color: 'text.primary', flexGrow: 1 }}
            placeholder={settings.titlePlaceholder}
            value={title}
            onChange={handleTitleChange} />
          <Divider light />
        </Box>
      }
      <Box px={1} width='100%'>
        <InputBase
          data-testid='new-post-dialog-content-message-input'
          fullWidth
          minRows={4}
          multiline
          autoFocus={!settings.requiresTitle}
          sx={{ color: 'text.primary', flexGrow: 1 }}
          placeholder={settings.placeholder}
          value={message}
          onChange={handleChange} />
      </Box>
      {image &&
      
        <Box px={1}
          width='100%'  
          sx={{ 
            justifyContent: 'center',
            width: '100%',
            display: 'flex'
          
          }}>
          <Box
            sx={{ 
              [theme.breakpoints.down('md')]: {
                maxWidth: '80%'
              },
              [theme.breakpoints.only('md')]: {
                maxWidth: '50%'
              },
              [theme.breakpoints.up('lg')]: {
                maxWidth: '33%'
              }   
                    
            }}>

            <img style= {{ width: '100%', borderRadius:'4px' }} src={URL.createObjectURL(image)} />
          </Box>
        </Box>
      }
    </Box>
  );
}