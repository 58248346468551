import React from 'react';

import { 
} from '@mui/material';

import { Page } from '../../components/organisms';

import { ProfileUpdate as ProfileUpdateForm } from '@components/templates';

export default function ProfileUpdate(){
  return (
    <>
      <Page>
        <ProfileUpdateForm />
      </Page>
    </>
  );
}

