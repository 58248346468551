import React, { useState } from 'react';

import {
  Box,
  IconButton,
  Dialog,
  Typography,
  Divider
}from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Image from 'mui-image';
import { titleCase } from '@helpers/text';

export default function ExerciseInfoDialog(props) {
  const { exercise, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);
  const theme  = useTheme();

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Box>
      <IconButton size="small" onClick={() => {setIsOpen(true);}} disabled={disabled}>
        <InfoIcon color='secondary' />
      </IconButton>
      <Dialog       sx={{
        '.MuiPaper-root' : {
          [theme.breakpoints.down('md')]: {
            width: '90%'
          },
          [theme.breakpoints.up('md')]: {
            width: '50%'
          }
        }
      }}
      open={isOpen}
      onClose={close}>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
            <Typography sx={{ flexGrow: 1 }}variant='h5'>{titleCase(exercise?.name)}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ p: 1  }}>
            <Typography variant='h5'>Tags</Typography>
            <Box sx={{ display: 'flex', maxWidth: '100%', flexWrap: 'wrap' }}>
              {exercise?.tags?.map(t => (
                <Box key={t} sx={{ px: 0.25, m: 0.25, borderWidth: '1px', borderColor: theme.palette.divider, borderStyle: 'solid' }}>
                  <Typography fontWeight='light' fontSize='0.75rem'>
                    {t}
                  </Typography>
                </Box>
              ))}
            </Box>
            {exercise?.equipment?.length > 0 &&
            <>
              <Typography variant='h5'>Equipment</Typography>
              <Box sx={{ display: 'flex', maxWidth: '100%', flexWrap: 'wrap' }}>
                {exercise.equipment.map(e => (
                  <Box key={e} sx={{ px: 0.25, m: 0.25, borderWidth: '1px', borderColor: theme.palette.divider, borderStyle: 'solid' }}>
                    <Typography fontWeight='light' fontSize='0.75rem'>
                      {e}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </>
            }
            {exercise?.instructions?.length > 0 &&
            <>
              <Typography  variant='h5'>Instructions</Typography>
              {exercise?.instructions.map((m, index) => (
                <Typography key={index} component='li' variant='caption'>{m}</Typography>
              ))}
            </>
            }
            {exercise?.videoUrl &&
            <>
              <Typography  variant='h5'>Video</Typography>
              <Image src={exercise?.videoUrl}/>
            </>
            }
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

