import React from 'react';

export default function RunningPerson (props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={'none'}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 4.5C13.25 3.25736 14.2574 2.25 15.5 2.25C16.7426 2.25 17.75 3.25736 17.75 4.5C17.75 5.74264 16.7426 6.75 15.5 6.75C14.2574 6.75 13.25 5.74264 13.25 4.5ZM10.0357 9.19501C9.43692 9.32087 8.96064 9.50264 8.57178 9.72414C7.84399 10.1387 7.32953 10.7452 6.89189 11.6072C6.64188 12.0997 6.03999 12.2962 5.54753 12.0462C5.05507 11.7962 4.85853 11.1943 5.10854 10.7019C5.6709 9.59416 6.42528 8.64509 7.5819 7.98628C8.72193 7.33692 10.1572 7.02421 11.9884 7.00255L11.9998 7.00242L13.3538 7.00195C14.1546 7.00164 14.7862 7.46905 15.1469 7.98647C15.2783 8.17493 15.4347 8.35441 15.6001 8.4785C16.0321 8.80243 16.5262 8.98716 17.0627 8.92967C17.5974 8.87236 18.3465 8.55594 19.2527 7.53604C19.6195 7.12319 20.2516 7.08588 20.6644 7.45271C21.0773 7.81954 21.1146 8.45161 20.7478 8.86447C19.6166 10.1375 18.443 10.7932 17.2758 10.9183C16.5465 10.9964 15.8871 10.8602 15.3185 10.6146L12.7868 14.4645L13.7226 15.386C14.4926 16.1442 15.0403 17.0959 15.3054 18.1399L15.9695 20.7559C16.1054 21.2912 15.7816 21.8353 15.2463 21.9712C14.711 22.1071 14.1669 21.7833 14.031 21.248L13.3669 18.632C13.1923 17.9445 12.8309 17.3149 12.3193 16.811L10.838 15.3523C10.3678 14.982 9.96022 14.6606 9.6502 14.3635C9.31834 14.0455 9.02587 13.6896 8.85485 13.2248C8.72442 12.8703 8.66211 12.4944 8.67119 12.1167C8.68311 11.6216 8.84512 11.1904 9.05664 10.7823C9.25531 10.3991 9.53944 9.96068 9.86718 9.45503L9.89194 9.41682L10.0357 9.19501ZM9.88548 16.4653C10.1424 15.9764 9.95425 15.3718 9.46534 15.115C8.97644 14.8581 8.37186 15.0462 8.11498 15.5351C7.54176 16.6261 6.21725 17.23 4.9099 16.919L4.23168 16.7576C3.69439 16.6298 3.15521 16.9617 3.02738 17.499C2.89955 18.0363 3.23149 18.5755 3.76878 18.7033L4.447 18.8647C6.59646 19.376 8.86518 18.4072 9.88548 16.4653Z"
        fill="currentColor"
        stroke='none' />
    </svg>
  );
}