import React, { useContext, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { 
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
  Tooltip
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InfoIcon from '@mui/icons-material/Info';

import Image from 'mui-image';
import { useTheme } from '@emotion/react';

import { forceLightTheme } from '@helpers/theme';
import { AccountRoles } from '@helpers/roles';

import { ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UserContext, ServicesContext } from '@context';
import { TextField } from '@components/atoms';
import { LoadingButton } from '@mui/lab';

function Index() {
  const { enqueueSnackbar } = useSnackbar();
  var { user, activeOrganisation } = useContext(UserContext);
  var { api } = useContext(ServicesContext);
  var theme = useTheme();
  var history = useHistory();
  const [invites, setInvites] = useState([{ number: 1, role: 'PRACTITIONER', name:'', email:'' }]);
  var [isSaving, setIsSaving] = useState(false);
  var [isValid, setIsValid] = useState(false);
  var [validationError, setValidationError] = useState('');

  const areInvitesValid = () => {
    if(validationError != ''){
      setValidationError('');
    }

    const nonEmptyEmails = invites.map(v => v.email).filter(e => e != '');
    const uniqueEmails = new Set(nonEmptyEmails);

    if (uniqueEmails.size < nonEmptyEmails.length) {
      setValidationError('You cannot have duplicate emails');
      return false;
    }

    return invites.filter(i => !i.name || i.name == '' || !i.email || i.email == '').length == 0;
  };

  useEffect(() => {
    setIsValid(areInvitesValid());
  }, [invites]);

  const goToNextStep = () => {
    history.push('/');
  };

  const addNewRow = () => {
    setInvites([...invites, { number: invites.length + 1, role: 'PRACTITIONER', name:'', email:'' }]);
  };

  const addUser = async (invite) => {
    await api.post({ url: `organisations/${activeOrganisation.id}/accounts`, data: { email: invite.email, role: invite.role, name: invite.name }, user });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true);
    for (const invite of invites) {
      await addUser(invite);
    }
    enqueueSnackbar(`${invites.length} Invite${invites.length > 1 ? 's' : ''} Successfully Sent`, { 
      variant: 'success'
    });
    setIsSaving(false);
    goToNextStep();
  };

  var handleNameChange = (invite, e) => {
    setInvites(invites.map((i) => {
      return i.number === invite.number ? 
        { ...i, name: e.target.value } : 
        i;
    }));
  };

  var handleEmailChange = (invite, e) => {
    setInvites(invites.map((i) => {
      return i.number === invite.number ? 
        { ...i, email: e.target.value } : 
        i;
    }));
  };

  var handleRoleChange = (invite, role) => {
    setInvites(invites.map((i) => {
      return i.number === invite.number ? 
        { ...i, role } : 
        i;
    }));
  };

  var removeRow = (invite) => {
    var result = invites.filter(i => i.number != invite.number);
    var count = 0;
    setInvites(result.map(i => {
      count = count + 1;
      return { ...i, number: count };
    }));
  };

  return(
    <Box sx={{ 
      background: '#fff', 
      height: '100vh', 
      width: '100vw', 
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      color: 'text.primary',
      alignItems: 'center' }}>
      <Box sx={{   
        [theme.breakpoints.down('md')]: {
          width: '100%'
        },
        [theme.breakpoints.up('md')]: {
          width: '800px'
        }  
      }}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box sx={{ p: 1, m: 1, display: 'flex' , flexDirection:'column', alignItems:'center' }}>
        
            <Image 
              sx={{ marginBottom: 2 }}
              duration={500} 
              width='150px' 
              src='https://bracesocial-prod-cdn.azureedge.net/static/BraceLogoBlackOnWhite.png'/>
            <Typography fontSize={'h4.fontSize'} fontWeight='light' py={0}>
          Welcome to Brace!
            </Typography>
            <Typography>{'Last step, let\'s invite any additional admins you want on the platform.'}</Typography>
            <Typography>{'Don\'t worry this can be done later if preferred.'}</Typography>
            {
              invites.map((invite) => 
                <Grid container
                  sx={{ my: 0.5 }}
                  spacing={1}
                  key={invite.number}>
                  <Grid item xs={3}>
                    <TextField 
                      inputProps={{ 'data-testid': `onboarding-users-name-input-${invite.number}` }}
                      label='Name'
                      required 
                      fullWidth
                      size='small' 
                      value={invite.name}
                      onChange={(event) => {handleNameChange(invite, event);}}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField 
                      fullWidth
                      inputProps={{ 'data-testid': `onboarding-users-email-input-${invite.number}` }}
                      label='Email'
                      type='email'
                      required 
                      size='small' 
                      value={invite.email}
                      onChange={(event) => {handleEmailChange(invite, event);}}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth size='small'>
                      <InputLabel id="demo-simple-select-label">Role</InputLabel>
                      <Select
                        size='small'
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={invite.role}
                        label="Role"
                        onChange={(event) => {handleRoleChange(invite, event.target.value);}}
                      >
                        {
                          Object.keys(AccountRoles)
                            .filter(ar => ar == 'ADMIN' || ar == 'PRACTITIONER')
                            .map((role) => 
                              <MenuItem key={role} value={role}>{AccountRoles[role]}</MenuItem>
                            )
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} sx={{ display: 'flex' }}>
                    <Tooltip title={
                      <>
                        <Typography variant='body2' color="inherit">What are the roles?</Typography>
                        <Typography variant='body2' sx={{ my: 1 }}color="inherit"><strong>Practitioner</strong> -  Ability to invite clients, create client groups, assign recovery plans and view clients metrics.</Typography>
                        <Typography variant='body2' sx={{ my: 1 }}color="inherit"><strong>Admin</strong> - All practitioner functionality, access to organisation information and ability to invite new users.</Typography>
                      </>
                    }
                    arrow>
                      <IconButton color='info'>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton disabled={invites.length == 1} onClick={() => removeRow(invite)}><RemoveCircleIcon/></IconButton>
                 
                  </Grid>
                </Grid>
              )
            }
            {validationError != '' &&
            <FormHelperText sx={{ fontSize: 'body1.fontSize' }}error={true}>{validationError}</FormHelperText>
            }
            <Box sx={{ display: 'flex', alignItems: 'start', width: '100%' }}>
              <Button onClick={addNewRow} >Add Another +</Button>
            </Box>
         
            <LoadingButton
              data-testid= 'onboarding-users-invite-button'
              sx={{ marginTop: 2, width: '66%', minWidth: '250px' }}
              type='submit'
              variant='contained'
              disabled={!isValid}
              loading={isSaving}>
            Invite
            </LoadingButton>
            <Button onClick={goToNextStep} data-testid= 'onboarding-users-skip-button'>Skip for now</Button>
        
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default function Onboarding(){
  var theme = useTheme();
  return (
    <ThemeProvider theme={forceLightTheme(theme)}>
      <Index />
    </ThemeProvider>
  );
}
