import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { 
  Box,
  Typography,
  Card,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Tooltip,
  Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';

import { AccountRoles, hasRole } from '@helpers/roles';

import config from '@config';

import { LoadableText, LoadableList, Avatar, LoadableChip } from '@components/atoms';
import { ConfirmationModal } from '@components/molecules';
import { AccountRoleChip } from '../../../components/atoms';

import { UserContext, ServicesContext } from '@context';

import { toShortDateString } from '@helpers/dates';

import AddUser from '../../../components/organisms/add-user-dialog';

export default function Users() {
  const { user, activeOrganisation, activeOrganisationAccount } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [invites, setInvites] = useState([]);
  const [isLoadingInvites, setIsLoadingInvites] = useState(false);
  const [userBeingRemoved, setUserBeingRemoved] = useState(null);
  const [isRemovingUser, setIsRemovingUser] = useState(false);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const loadUsers = async () => {
    setIsLoadingUsers(true);
    var result = await api.post({ url: `organisations/${activeOrganisation?.id}/accounts/search`, data:{}, user });
    setUsers(result.results);
    setIsLoadingUsers(false); 
  };

  const loadInvites = async () => {
    setIsLoadingInvites(true);
    var result = await api.post({ url: 'invites/search', data:{
      organisationId: activeOrganisation.id
    }, user });

    setInvites(result.results);
    setIsLoadingInvites(false); 
  };

  const load = () => {
    loadUsers();
    loadInvites();
  };

  function resendInvite(inviteId){
    setIsResending(true);
    api.put({ url: `invites/${inviteId}/resend`, user })
      .then(() => {
        enqueueSnackbar('Successfully resent invite', { 
          variant: 'success'
        });
        setIsResending(false);
      });
  }

  const removeUser = async () => {
    setIsRemovingUser(true);
    await api.delete({ url: `organisations/${activeOrganisation.id}/accounts/${userBeingRemoved.account.id}`, user } );
    setIsRemovingUser(false);
  };

  useEffect(() => {
    load();
  }, []);

  const UserTableRow = (props) => {
    var { user } = props;
    var color = user.account?.status != 'ACTIVE' ? 'text.secondary' : 'text.primary';
    return (
      <TableRow
        data-testid={`user-row-${user?.account?.id}`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {user.inviteId &&   
            <LoadableChip 
              size='small'
              isLoading={isLoadingUsers || isLoadingInvites}
              label={'Invited'}
              color='info'
              variant="outlined" />
            }
            {(!user.inviteId && user.account?.status == 'PENDING') &&   
            <LoadableChip 
              size='small'
              isLoading={isLoadingUsers || isLoadingInvites}
              label={'Pending'}
              color='warning'
              variant="outlined" />
            }
            {user.account?.status == 'ACTIVE' && 
              <Avatar 
                key={user.account?.id}
                iconSize={20}
                size={25}
                isLoading={isLoadingUsers || isLoadingInvites}
                src={user?.account?.imageLocation ? 
                  `${config.contentBaseUrl}images/${user?.account?.imageLocation}` : null}
                name={user.account?.name} />
            }
          </Box>
           
        </TableCell>
        <TableCell >
          <Typography color={color} component='div'>
            <LoadableText shouldNotLinkify={true} isLoading={isLoadingUsers || isLoadingInvites} text={user?.isInvite ? user.name : user.account?.name} />
          </Typography>
        </TableCell>
        <TableCell>
          <AccountRoleChip role={user.role} isLoading={isLoadingUsers || isLoadingInvites}/>
        </TableCell>
        <TableCell>
          <Stack direction='row' spacing={0.5}>
            {user?.teams?.map(t => 
              <LoadableChip 
                key={t.id}
                size='small'
                isLoading={isLoadingUsers || isLoadingInvites}
                label={t.name}
                color='default'
                variant="outlined" />
            )}
          </Stack>
        </TableCell>
        <TableCell >
          <Typography color={color} component='div'>
            <LoadableText isLoading={isLoadingUsers || isLoadingInvites}
              text={user?.account?.lastOnlineAt ?
                toShortDateString(user?.account?.lastOnlineAt) : '-'
              }/>
          </Typography>
        </TableCell>
        <TableCell >
          <Typography color={color} component='div'>
            <LoadableText isLoading={isLoadingUsers || isLoadingInvites} text={toShortDateString(user?.account?.createdAt)}/>
          </Typography>
         
        </TableCell>
        <TableCell align="right" >
          {(hasRole(AccountRoles[activeOrganisationAccount?.role], AccountRoles.ADMIN) && !user.isInvite && !user.isThisUser) &&
            <IconButton data-testid={`user-row-${user?.account?.id}-delete`}
              onClick={() => {
                setUserBeingRemoved(user);
              }}
              disabled={(isLoadingUsers || isLoadingInvites || isRemovingUser)}
            >
              <DeleteIcon fontSize="small"/>
            </IconButton>
          }
          {user.inviteId &&
          <Tooltip title="Resend Invite">
            <IconButton 
              data-testid={`user-row-${user?.email}-resend-invite`}
              onClick={() => {
                resendInvite(user.inviteId);
              }}
              disabled={(isLoadingUsers || isLoadingInvites || isResending)}
            >
              <EmailIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
          }
         
        </TableCell>
      </TableRow>
    );
  };

  var rows = [];
  users.filter(u => u.account.role !== 'CLIENT').forEach(u => {
    var invite = invites.filter(i => i.inviteeEmail == u.account.email)[0];

    rows.push({
      ...u,
      isThisUser: u.account.id == user.id,
      inviteId: invite?.id
    });
  });

  invites.filter(i => i.role !== 'CLIENT').forEach(invite =>{
    if(users.filter(u => u.account.email == invite.inviteeEmail).length == 0){
      rows.push({
        isInvite: true,
        id: invite.id,
        name: invite.inviteeEmail,
        email: invite.inviteeEmail,
        role: invite.role,
        createdAt: invite.createdAt
      });
    }
  });

  return(
    <Card  sx={{ p: 1 }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography data-testid='settings-users-header' sx={{ fontSize: 'h5.fontSize' }}>
          Users
        </Typography>
        <Box>
          <Button 
            data-testid= 'settings-add-user-button'
            onClick={() => setIsAddUserDialogOpen(true)}
            variant='contained'
            size='small'
            color='primary'>
            Invite New User
          </Button>
        </Box>
      </Box>
      <Box>
       
      </Box>
      <TableContainer>
        <Table aria-label="simple table" size="small" >
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'text.secondary', py: 0.5 }} ></TableCell>
              <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Name</TableCell>
              <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Role</TableCell>
              <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Groups Owned</TableCell>
              <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Last Active</TableCell>
              <TableCell sx={{ color: 'text.secondary', py: 0.5 }} >Joined At</TableCell>
              <TableCell sx={{ color: 'text.secondary', py: 0.5 }} ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <LoadableList 
              isLoading={isLoadingUsers || isLoadingInvites}
              rows={rows}
              getRow={(user) => {return (<UserTableRow user={user} key={user.id}/>);} }
            />
          </TableBody>
        </Table>
      </TableContainer>
      <AddUser 
        title='Invite New User'
        isOpen={isAddUserDialogOpen}
        accountRoleOptions={[AccountRoles.ADMIN, AccountRoles.PRACTITIONER]}
        defaultAccountRole='PRACTITIONER'
        close={(shouldRefresh) => {
          setIsAddUserDialogOpen(false);
          if(shouldRefresh){
            load();
          }
        }}/>
      <ConfirmationModal 

        isDeleteAction={true}
        title='Remove User?'
        message='If you remove this user they will no longer have access to your organisation. Are you sure you want to do this?'
        confirmationSuccessMessage='User successfully removed from organisation'
        handleClose={(shouldRefresh) => {
          if(shouldRefresh) {
            load();
          }
          setUserBeingRemoved(null);
        }}
        handleConfirmation={removeUser}
        isOpen={userBeingRemoved != null}/>
    </Card>
  );
}