import React, { useContext, useEffect, useState } from 'react';

import { 
  Box, 
  ThemeProvider,
  Button,
  Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { Icon } from '../atoms';
import { forceLightTheme } from '@helpers/theme';
import { UserContext } from '@context';
import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

function Page(props) {
  const { handleLogout, auth0User, user } = useContext(UserContext);
  const { loginWithRedirect } = useAuth0();
  const { onClick } = props;
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const [timeoutId, setTimeoutId] = useState('');
  
  const login = () => {
    Capacitor.isNativePlatform() ?
      loginWithRedirect({
        async openUrl(url){
          await Browser.open({
            url,
            windowName: '_self'
          });
        }

      }) : 
      loginWithRedirect();
  };
  
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if(auth0User?.sub && user?.id && timeoutId != ''){
      clearTimeout(timeoutId);
      setTimeout('');
    }else if(timeoutId == ''){
      const timeoutId = setTimeout(() => {
        setShouldShowButton(true);
      }, 10000);
      
      setTimeoutId(timeoutId);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [auth0User]);

  return (
    <Box 
      onClick={onClick}
      sx={{ 
        background: '#fff', 
        height: '100vh', 
        width: '100vw', 
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center' }}>
      <Box>
        <Icon type='treadmill'/>
        {shouldShowButton && 
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 2 }}>
          
          {auth0User?.sub &&
          <>
            <Typography sx={{ p: 2 }}>
              Having issues logging in?
            </Typography>
            <Button onClick={() => {
              handleLogout(() => {window.location = '/';});
            }}
            variant='contained'
            size='small'>
              Logout 
            </Button>
          </>
          }
          {!auth0User?.sub &&
            <>
              <Typography sx={{ p: 2 }}>
                It seems like you are not logged in!
              </Typography>
              <Button onClick={login} variant='contained' size='small'>
                Login 
              </Button>
            </>
          }
        </Box>
        }
      </Box>
    </Box>
  );
}

export default function LoadingPage(props){
  const theme = useTheme();
  return (
    <ThemeProvider theme={forceLightTheme(theme)}>
      <Page {...props}/>
    </ThemeProvider>
  );
}