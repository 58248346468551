import React from 'react';

import { 
  TextField
} from '@mui/material';

export default function RichTextField(props) {
  const { value, onChange } = props;
  return (
    <TextField 
      {...props}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}