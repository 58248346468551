import React, { useState } from 'react';

import { 
  Box,
  Chip,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '@components/v2/atoms';
import CategoriesSearch from './categories-search';

export default function PickCategories(props) {
  const { categories, selectedCategories, setSelectedCategories } = props;
  const [expandedGroup, setExpandedGroup] = useState(null);
  const CATEGORY_DISPLAY_LIMIT = 8;
  const CATEGORY_TYPE_ORDER = ['Injury Type', 'Injury Stage', 'Physical Symptoms', 'Mental Supports'];
  const INJURY_STAGE_ORDER = ['Newly Injured', 'Pre Surgery', 'Post Surgery', 'Rehabilitation', 'Returning To Play', 'Recovered', 'ReInjured'];

  const CategoryChip = (props) => {
    return (
      <Box sx={{ p: 0.5 }}>
        <Chip {...props} />
      </Box>
    );
  };

  const Category = (props) => {
    const { category, isSelected, isExample } = props;

    const handleClick = () => {
      if(!isSelected){
        setSelectedCategories([...selectedCategories, category]);
      }else{
        setSelectedCategories(selectedCategories.filter(sc => sc.id != category.id));
      }
    };

    return (
      <CategoryChip 
        data-testid={`new-topic-dialog-category-chip-${isExample ? 'example-' : ''}${category.name}`}
        color={isSelected && !isExample ? 
          'primary' : 
          'default'
        }
        variant={isSelected ? 'contained' : 'outlined'}
        onClick={handleClick}
        label={category.name}
        onDelete={isSelected ? handleClick : undefined}
        sx={{ opacity : isExample ? 0.33 : 1 }}
      />
    );
  };

  var remainingCategories = categories.filter(c => selectedCategories.filter(sc => sc.id == c.id).length == 0);

  var groupedRemainingCategories = {};

  remainingCategories.forEach(rc => {
    if(!groupedRemainingCategories[rc.type]){
      groupedRemainingCategories[rc.type] = [];
    }

    groupedRemainingCategories[rc.type].push(rc);
    
  });

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box sx={{ textAlign: 'center', paddingBottom: 1 }}>
        <Typography variant='body1' color='text.secondary'>Make it easy to find, categorise your topic!</Typography>
      </Box>
      <CategoriesSearch
        categories={categories} 
        selectedCategories={selectedCategories} 
        setSelectedCategories={setSelectedCategories} />
      <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 3, paddingBottom: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography>Selected Categories*</Typography>
        </Box>
        <Button 
          onClick={() => setSelectedCategories([])}
          size='small'
          disabled={selectedCategories.length == 0}
          color='braceGreenDark'
          sx={{ lineHeight: 1 }}>
          Clear All
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedCategories.length > 0 && selectedCategories.map((c) => 
          <Category 
            key={c.name}
            category={c}
            isSelected={true}
          />
        )}
        {selectedCategories.length == 0 && 
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          <Typography sx={{ opacity: 0.33 }}>Eg.</Typography>
          {[{ name: 'ACL' }, { name: 'Motivation' }, { name: 'Swelling' }].map((c) => 
            <Category 
              isExample={true}
              key={c.name}
              category={c}
              isSelected={true}
            />
          )}
        </Box>
        }
      </Box>
      {Object.keys(groupedRemainingCategories)
        .sort((key1, key2) => CATEGORY_TYPE_ORDER.indexOf(key1) - CATEGORY_TYPE_ORDER.indexOf(key2))
        .map((key) => {
          var categoryGroup = groupedRemainingCategories[key];
          var sortedCategoryGroup = categoryGroup
            .sort((c1, c2) => {
              if(key == 'Injury Stage'){
                return INJURY_STAGE_ORDER.indexOf(c1.name) - INJURY_STAGE_ORDER.indexOf(c2.name); 
              }

              var textA = c1.name.toUpperCase();
              var textB = c2.name.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

          var isExpanded = expandedGroup == key;
          var shouldShowShowMoreChip = !isExpanded && categoryGroup.length > CATEGORY_DISPLAY_LIMIT;

          var categoriesToShow = isExpanded ?
            sortedCategoryGroup :
            sortedCategoryGroup.slice(0, CATEGORY_DISPLAY_LIMIT);

          return (
            <Box key={key}>
              <Typography variant='body1' color='text.disabled' py={1} >
                {key}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {categoriesToShow.map((c) => 
                  <Category 
                    key={c.name}
                    category={c}
                  />
                )}
                {shouldShowShowMoreChip && 
                <CategoryChip 
                  data-testid={`new-topic-dialog-expand-type-${key}`}
                  icon={<KeyboardArrowDownIcon />}
                  onClick={() => setExpandedGroup(key)}
                  label={`Show ${categoryGroup.length - CATEGORY_DISPLAY_LIMIT} More`}
                />
                }
                {isExpanded && 
                <CategoryChip 
                  icon={<KeyboardArrowUpIcon />}
                  onClick={() => setExpandedGroup(null)}
                  label={'Show Less'}
                />
                }
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}