import React from 'react';

import { 
  Box,
  Fab
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDetectKeyboardOpen from '@helpers/device';

export default function FabButton(props){
  const { onClick, dataTestId } = props;
  const isKeyboardOpen = useDetectKeyboardOpen();
  return (
    <Box sx={{ 
      position: 'fixed',
      right: 4,
      bottom: `calc(${isKeyboardOpen ? 18 : 100}px + env(safe-area-inset-bottom))`
    }}>
      <Fab
        data-testid={dataTestId}
        color="primary" 
        onClick={onClick}>
        <AddIcon />
      </Fab>
    </Box>
  );
}