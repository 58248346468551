import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

import { 
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '&.MuiDialog-root': {
    zIndex: theme.zIndex.modal + 1
  }
}));

export default function ConfirmationModal(props) {
  var { 
    title, 
    message, 
    handleClose, 
    handleConfirmation, 
    confirmText='Confirm', 
    cancelText='Cancel', 
    confirmDataTestId='confirmation-modal-button-confirm', 
    cancelColor='primary', 
    isOpen, 
    isDeleteAction, 
    confirmationSuccessMessage, 
    handleClickOutsideModal } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const doConfirmation = async () => {
    setIsLoading(true);
    await handleConfirmation();
    if(confirmationSuccessMessage){
      enqueueSnackbar(confirmationSuccessMessage, { 
        variant: 'success'
      });
    }
    
    setIsLoading(false);
    handleClose(true);
  };

  const doCancel = async () => {
    setIsLoading(true);
    await handleClose();
    setIsLoading(false);
  };

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClickOutsideModal}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={cancelColor}
          variant='outlined'
          disabled={isLoading}
          onClick={doCancel}>{cancelText}</Button>
        <LoadingButton 
          data-testid={confirmDataTestId}
          loading={isLoading}
          variant='contained'
          color={isDeleteAction ? 'error' : 'primary'}
          onClick={doConfirmation}>{confirmText}</LoadingButton>
      </DialogActions>
    </StyledDialog>
  );
}