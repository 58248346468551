import * as React from 'react';

import Box from '@mui/material/Box';

import TextField from './text-field';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function AutoCompleteWithOptions(props){
  var { value, onChange, onBlur, placeholder, options, label, size='small', ...muiProps } = props;

  console.log(muiProps);

  return (
    <Autocomplete
      multiple
      fullWidth
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      onBlur={onBlur}
      size={size}
      options={options}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '') {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
      sx={{
        '.MuiOutlinedInput-root.MuiInputBase-sizeSmall':{
          padding: '9.5px 8px'
        }
      }}
      renderInput={(params) => (
        <TextField
          placeholder={value.length > 0 ? '' : placeholder}
          size={size}
          {...params}
          label={label}
          {...muiProps}
        />
      )}
    />
  );
}