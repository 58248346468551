import React, { useState } from 'react';

import { 
  Card,
  Box,
  Typography,
  Divider,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { useTheme } from '@emotion/react';

import { LoadableText } from '@components/atoms';
import { NewPlanDialog } from '@components/templates';

export default function PlanCard(props) {
  const { plan, isLoading, refresh } = props;
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const theme = useTheme();

  return(
    <>
      <Card variant='outlined'
        sx={{
          [theme.breakpoints.down('md')]: {
            width: '50%',
            minWidth: '50%'
          },
          [theme.breakpoints.up('md')]: {
            width: '33%',
            minWidth: '33%'
          } }}>
        <Box sx={{ p: 1 }}>
          <Typography component="div">
            <Box sx={{ fontSize: 'body1.fontSize', fontWeight:'medium', color: 'text.primary' }}>
              <LoadableText isLoading={isLoading} text={plan?.name} dataTestId={`client-plan-name-${plan?.id}`}/>
            </Box>
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1, p: 1, display: 'flex' }}>
          <Box sx={{ flexGrow: '1' }}>
            <Typography component="div"
              variant='body1'
              lineHeight={1}
              fontWeight='medium'>
              <LoadableText isLoading={isLoading} text={plan?.exerciseCount}/>
            </Typography>
            <Typography variant='caption' lineHeight={1} color='text.secondary'>
            Exercises
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <IconButton
              data-testid={`client-plan-edit-button-${plan?.id}`}
              size='small'
              variant='contained'
              onClick={() => setIsBeingEdited(true)}>
              <EditIcon fontSize='small' />
            </IconButton>
          </Box>
        </Box>
     
      </Card>
      <NewPlanDialog 
        isOpen={isBeingEdited}
        close={(shouldRefresh) => {
          setIsBeingEdited(false);
          if(shouldRefresh){
            refresh();
          }
        }}
        planBeingEdited={plan}/>
    </>
  );
}
