import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { useEffect, useState } from 'react';

const useDetectKeyboardOpen = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [isKeyboardOpening, setIsKeyboardOpening] = useState(false);

  useEffect(() => {
    if(Capacitor.getPlatform() != 'web'){
      Keyboard.addListener('keyboardDidShow', info => {
        setIsKeyboardOpen(true);
        setIsKeyboardOpening(false);
      });

      Keyboard.addListener('keyboardWillShow', info => {
        setIsKeyboardOpening(true);
      });
    
      Keyboard.addListener('keyboardWillHide', () => {
        setIsKeyboardOpening(false);
      });

      Keyboard.addListener('keyboardDidHide', () => {
        setIsKeyboardOpen(false);
        setIsKeyboardOpening(false);
      });
    }
  }, []);

  return isKeyboardOpen;
};

export default useDetectKeyboardOpen;

export function isNative(){
  return Capacitor.isNativePlatform();
}