import axios from 'axios';

export default class Api {
  constructor(config) {
    this.config = config;
  }
  async getToken(user, token, getUserToken, isAuthenticated){
    var result;
    if(isAuthenticated){
      result = getUserToken ?
        await getUserToken() :
        token ? 
          token : 
          user.token;
    }
    return result;
  }
  async post({ url, user, data, getUserToken, isAuthenticated = true, cancellationToken }){
    var authToken = await this.getToken(user, null, getUserToken, isAuthenticated);
    return axios({
      method: 'post',
      url: this.config.apiBaseUrl +  url,
      headers: isAuthenticated ? { 'Authorization': authToken } : {},
      data,
      cancelToken: cancellationToken?.token
    })
      .then(response => {
        return response.data;
      })
      .catch(error => { 
        if(error?.response?.status === 400)   {
          var expectedError = new Error(error.message);
          expectedError.status = error.response?.status;
          expectedError.data = error.response?.data;
          throw expectedError;
        }        
        throw new Error(error.message);
      });
  }
  async put({ url, user, getUserToken, data, isAuthenticated = true }){
    var authToken = await this.getToken(user, null, getUserToken, isAuthenticated);
    return axios({
      method: 'put',
      url: this.config.apiBaseUrl +  url,
      headers: isAuthenticated ? { 'Authorization': authToken } : {},
      data
    })
      .then(response => {
        return response.data;
      })
      .catch(error => { 
        if(error?.response?.status === 400)   {
          var expectedError = new Error(error.message);
          expectedError.status = error.response?.status;
          expectedError.data = error.response?.data;
          throw expectedError;
        }        
        throw new Error(error.message);
      });
  }
  async get({ url, user, token, getUserToken, params, isAuthenticated = true }){
    var authToken = await this.getToken(user, token, getUserToken, isAuthenticated);

    return axios({
      method: 'get',
      params,
      url: this.config.apiBaseUrl +  url,
      headers: isAuthenticated ? { 'Authorization': authToken } : {}
    })
      .then(response => {
        return response.data;
      })
      .catch(error => { 
        if(error?.response?.status === 400)   {
          var expectedError = new Error(error.message);
          expectedError.status = error.response?.status;
          expectedError.data = error.response?.data;
          throw expectedError;
        }        
        throw new Error(error.message);
      });
  }
  async delete({ url, user, token, params, isAuthenticated = true, getUserToken }){
    var authToken = await this.getToken(user, token, getUserToken, isAuthenticated);

    return axios({
      method: 'delete',
      params,
      url: this.config.apiBaseUrl +  url,
      headers: isAuthenticated ? { 'Authorization': authToken } : {}
    })
      .then(response => {
        return response.data;
      })
      .catch(error => { 
        if(error?.response?.status === 400)   {
          var expectedError = new Error(error.message);
          expectedError.status = error.response?.status;
          expectedError.data = error.response?.data;
          throw expectedError;
        }        
        throw new Error(error.message);
      });
  }
  getCancellationToken(){
    const CancelToken = axios.CancelToken;
    return CancelToken.source();
  }
}